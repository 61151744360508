import { Button, Col, Form, Input, Modal, Radio, Row, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import type Model from './model';

const { TextArea } = Input;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

// 新增或编辑管理员信息
export const AdminEditorModal = observer(({ store }: { store: Model }) => {
  if (!store) {
    return null;
  }
  const { visible, onClose, type, saveLoading, onSave, allRoleType, allSex, setRef } = store;
  return (
    <Modal
      centered={true}
      footer={
        <Space>
          <Button onClick={onClose}>取消</Button>
          <Button
            loading={saveLoading}
            onClick={onSave}
            type="primary"
          >
            保存
          </Button>
        </Space>
      }
      maskClosable={false}
      onCancel={onClose}
      open={visible}
      title={type === 'add' ? '添加用户' : '修改用户'}
      width={686}
    >
      <Form ref={setRef}>
        <Row>
          <Col span={12}>
            <Form.Item
              label="用户昵称"
              name="nickName"
              rules={[{ required: true }]}
              validateTrigger="onBlur"
            >
              <Input
                autoComplete="off"
                placeholder="请输入用户昵称"
                maxLength={8}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              label="角色"
              name="platformRoleVOList"
              rules={[{ required: true }]}
              validateTrigger="onBlur"
            >
              <Select
                allowClear={true}
                mode="multiple"
                options={allRoleType}
                placeholder="请选择"
                labelInValue={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="手机号码"
              name="mobile"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  pattern: /^1[3456789]\d{9}$/,
                  message: '请输入正确的手机号',
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder="请输入手机号"
                maxLength={11}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              label="邮箱"
              name="userEmail"
              validateTrigger="onBlur"
              rules={[
                {
                  type: 'email',
                  message: '请输入正确的邮箱',
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder="请输入邮箱"
                maxLength={30}
              />
            </Form.Item>
          </Col>
        </Row>
        {type !== 'edit' && (
          <Row>
            <Col span={12}>
              <Form.Item
                label="用户名称"
                name="userName"
                rules={[
                  {
                    required: true,
                    pattern: /^[^\u4e00-\u9fa5]+$/,
                    message: '用户名称不能输入中文',
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input
                  disabled={type === 'edit'}
                  autoComplete="off"
                  placeholder="请输入用户名称"
                  maxLength={16}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...formItemLayout}
                label="用户密码"
                name="password"
                validateTrigger="onBlur"
                rules={[{ required: true }]}
              >
                <Input.Password
                  autoComplete="off"
                  placeholder="请输入密码"
                  maxLength={8}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={12}>
            <Form.Item
              label="用户性别"
              name="userSex"
              validateTrigger="onBlur"
            >
              <Select
                allowClear={true}
                optionFilterProp="label"
                options={allSex}
                placeholder="请选择"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              label="状态"
              name="accountStatus"
              validateTrigger="onBlur"
            >
              <Radio.Group>
                <Radio value="1">是</Radio>
                <Radio value="0">否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="备注"
              name="userComment"
              validateTrigger="onBlur"
              rules={[
                {
                  max: 100,
                  message: '备注不能超过100个字符！', // 当输入超过100个字符时显示的错误信息
                },
              ]}
            >
              <TextArea
                rows={4}
                autoSize={{
                  minRows: 3,
                  maxRows: 6,
                }}
                showCount={true}
                maxLength={100}
                placeholder="请输入内容"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
