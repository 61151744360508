import type { AnchorDetailProps } from '@/pages/anchorDetail/anchorDetailStore';
import { JOB_STATUS, SEX } from '@/pages/anchorDetail/constants';
import { ResumeVita } from '@/pages/anchorDetail/resumeVita';
import { Button, DatePicker, Form, Input, InputNumber, Radio, Select, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

export const ResumeInformationEdit = observer(({ anchorDetailStore }: AnchorDetailProps) => {
  const {
    toggleIsEdit,
    anchorDetail,
    resumeFormInstance,
    handleResumeSave,
    isResumeSave,
    liveData,
    handleAddPlatformAccount,
  } = anchorDetailStore;

  React.useEffect(() => {
    const userJobHuntVO = anchorDetail?.userJobHuntVO || {};
    resumeFormInstance.current?.setFieldsValue({
      // 基本信息
      name: anchorDetail.name,
      sex: anchorDetail.sex,
      birthday: anchorDetail.birthday ? dayjs(anchorDetail.birthday) : undefined,
      height: anchorDetail.height,
      weight: anchorDetail.weight,
      bustSize: anchorDetail.bustSize,
      waistSize: anchorDetail.waistSize,
      hipSize: anchorDetail.hipSize,
      contactInfo: anchorDetail.contactInfo,

      // 直播经验
      workYear: anchorDetail.workYear,
      gmv: userJobHuntVO.gmv,
      onLineCount: userJobHuntVO.onLineCount,
      natureStartAccount: userJobHuntVO.natureStartAccount,
      playMethod: userJobHuntVO.playMethod,
      acceptAudition: userJobHuntVO.acceptAudition,
      liveStyle: userJobHuntVO.liveStyle,
      oldLiveTypeList: (userJobHuntVO.oldLiveTypeList || []).reduce((pre, curr) => {
        const childrenList = curr.childrenList || [];
        if (childrenList.length === 0) {
          pre.push(`${curr.id}`);
        } else {
          curr.childrenList?.forEach((item) => {
            pre.push(`${item.id}`);
          });
        }
        return pre;
      }, []),

      // 个人优势
      advantage: userJobHuntVO.advantage,

      // 求职状态
      jobStatus: userJobHuntVO.jobStatus,
    });
  }, [anchorDetail, resumeFormInstance]);
  const commonInputNumberConfig = {
    step: 1,
    precision: 0,
    rootClassName: 'w-full',
    min: 0,
    max: 1000,
    placeholder: '请输入',
  };

  return (
    <Form
      ref={resumeFormInstance}
      autoComplete="off"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      colon={false}
    >
      <div className={`w-[350px] flex flex-col gap-3 ${styles.resumeContainer}`}>
        <div className={'flex justify-end gap-2'}>
          <Button onClick={toggleIsEdit}>取消</Button>
          <Button
            loading={isResumeSave}
            onClick={handleResumeSave}
            type={'primary'}
          >
            保存
          </Button>
        </div>
        <ItemContainer title={'基本信息'}>
          <Form.Item
            label={'姓名'}
            name="name"
          >
            <Input
              placeholder={'请输入'}
              allowClear={true}
            />
          </Form.Item>
          <Form.Item
            label={'性别'}
            name="sex"
          >
            <Radio.Group options={Object.values(SEX)} />
          </Form.Item>
          <Form.Item
            label={'联系方式'}
            name="contactInfo"
            validateTrigger="onBlur"
          >
            <Input
              autoComplete="off"
              placeholder="请输入联系方式"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            label={'出生年月'}
            name="birthday"
          >
            <DatePicker rootClassName={'w-full'} />
          </Form.Item>
          <Form.Item
            label={'身高(cm)'}
            name="height"
          >
            <InputNumber {...commonInputNumberConfig} />
          </Form.Item>
          <Form.Item
            label={'体重(kg)'}
            name="weight"
          >
            <InputNumber {...commonInputNumberConfig} />
          </Form.Item>
          <Form.Item label={'三围(cm)'}>
            <div className={'flex items-center gap-2'}>
              <Form.Item name="bustSize">
                <InputNumber
                  {...commonInputNumberConfig}
                  placeholder={'胸围'}
                />
              </Form.Item>
              <Form.Item name="waistSize">
                <InputNumber
                  {...commonInputNumberConfig}
                  placeholder={'腰围'}
                />
              </Form.Item>
              <Form.Item name="hipSize">
                <InputNumber
                  {...commonInputNumberConfig}
                  placeholder={'臀围'}
                />
              </Form.Item>
            </div>
          </Form.Item>
        </ItemContainer>
        <ItemContainer title={'直播经验'}>
          <Form.Item
            label={'直播年限(年)'}
            name="workYear"
          >
            <InputNumber {...commonInputNumberConfig} />
          </Form.Item>
          <Form.Item
            label={'单场最高GMV(元)'}
            name="gmv"
          >
            <InputNumber
              {...commonInputNumberConfig}
              max={999999999}
            />
          </Form.Item>
          <Form.Item
            label={'最高在线人数(人)'}
            name="onLineCount"
          >
            <InputNumber
              {...commonInputNumberConfig}
              max={999999999}
            />
          </Form.Item>
          <Form.Item
            label={'播过的类目'}
            name="oldLiveTypeList"
          >
            <TreeSelect
              allowClear={true}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              placeholder={'请选择'}
              fieldNames={{ value: 'id', label: 'name', children: 'childrenList' }}
              showSearch={true}
              treeData={liveData}
              filterTreeNode={(inputValue: string, treeNode: any) => {
                return treeNode.name?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1;
              }}
            />
          </Form.Item>
          <Form.Item label={'播过的帐号'}>
            <Button
              onClick={handleAddPlatformAccount}
              type={'link'}
            >
              添加
            </Button>
          </Form.Item>

          <Form.Item
            label={'起号经验'}
            name="natureStartAccount"
          >
            <Radio.Group
              options={[
                { value: 1, label: '有' },
                { value: 0, label: '无' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={'套路玩法'}
            name="playMethod"
          >
            <Radio.Group
              options={[
                { value: 1, label: '会' },
                { value: 0, label: '不会' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={'面试试镜'}
            name="acceptAudition"
          >
            <Radio.Group
              options={[
                { value: 1, label: '接受' },
                { value: 0, label: '不接受' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={'直播风格'}
            name="liveStyle"
          >
            <Input.TextArea
              showCount={true}
              maxLength={100}
              autoSize={{ minRows: 1, maxRows: 4 }}
              placeholder={'请输入'}
              allowClear={true}
            />
          </Form.Item>
        </ItemContainer>
        <ItemContainer title={'个人优势'}>
          <Form.Item
            wrapperCol={{ span: 24 }}
            name="advantage"
          >
            <Input.TextArea
              showCount={true}
              maxLength={500}
              autoSize={{ minRows: 1, maxRows: 10 }}
              placeholder={'请输入'}
              allowClear={true}
            />
          </Form.Item>
        </ItemContainer>
        <ItemContainer title={'求职状态'}>
          <Form.Item
            wrapperCol={{ span: 24 }}
            name="jobStatus"
          >
            <Select
              options={Object.values(JOB_STATUS)}
              placeholder={'请输入'}
            />
          </Form.Item>
        </ItemContainer>
        <ResumeVita anchorDetailStore={anchorDetailStore} />
      </div>
    </Form>
  );
});

function ItemContainer({ title, children }: { title?: string; children?: React.ReactNode }) {
  return (
    <div className={'p-4 border border-solid border-divide rounded-1'}>
      <div className={'text-heading text-14 font-500 mb-4'}>{title}</div>
      {children}
    </div>
  );
}
