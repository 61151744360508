import { type BaseData, request } from '@/utils';
import { Button, Form, Modal, Select, type SelectProps, Space } from 'antd';
import { observer } from 'mobx-react';
import { useState } from 'react';
import React from 'react';
import { API } from '../api';
import type Model from './editUserModel';
const EditUser = ({ store }: { store: Model }) => {
  const { visible, setRef, onClose, usage, userId, userName, mobile, onSave, type } = store;
  return (
    <Modal
      title={type === 'edit' ? '编辑白名单' : '添加白名单'}
      open={visible}
      onCancel={onClose}
      footer={
        <Space>
          <Button onClick={onClose}>取消</Button>
          <Button
            onClick={onSave}
            type="primary"
          >
            保存
          </Button>
        </Space>
      }
      maskClosable={false}
      width={383}
    >
      <Form ref={setRef}>
        <Form.Item
          label={type === 'edit' ? '用户信息' : '选择用户'}
          name="userId"
          rules={[
            {
              required: true,
              message: '请选择用户',
            },
          ]}
        >
          {type === 'edit' && (
            <div
              style={{
                height: '80px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: '10px',
              }}
            >
              <div style={{ fontSize: '12px', lineHeight: '20px' }}>用户昵称：{userName}</div>
              <div style={{ fontSize: '12px', lineHeight: '20px' }}>ID：{userId}</div>
              <div style={{ fontSize: '12px', lineHeight: '20px' }}>手机号：{mobile}</div>
            </div>
          )}
          {type === 'add' && (
            <SearchInput
              placeholder={'输入用户昵称/手机号/ID搜索选择'}
              style={undefined}
              className={'!w-60'}
            />
          )}
        </Form.Item>
        <Form.Item
          label="选择用途"
          name="whitelistUseIdList"
          rules={[{ required: true, message: '请输入用途' }]}
        >
          <Select
            mode="multiple"
            labelInValue={true}
            options={usage}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(EditUser);

const SearchInput: React.FC<{
  placeholder: string;
  style: React.CSSProperties;
  className: string;
  callback?: (value: string) => void;
  [key: string]: any;
}> = React.forwardRef((props, ref) => {
  const [data, setData] = useState<SelectProps['options']>([]);

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };
  return (
    <Select
      // @ts-ignore
      ref={ref}
      labelInValue={true}
      className={props.className}
      showSearch={true}
      value={props.value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={(value, option: any) => {
        props.onChange(option?.sysUserId);
        props?.callback(option?.sysUserId);
      }}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        label: d.label,
        value: d.value,
        sysUserId: d.sysUserId,
      }))}
    />
  );
});

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
const fetch = (value: string, callback: (data: { label: any; value: any }[] | undefined) => void) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = async () => {
    const req = await request<BaseData<any>>({
      method: 'POST',
      url: API.userList,
      data: {
        name: value,
        pageNum: 1,
        pageSize: 200,
      },
    });
    if (currentValue === value) {
      callback(
        (req?.data?.list || [])?.map((item) => {
          console.log(item, 'item');

          return {
            label: item.nickName,
            value: item.id,
            sysUserId: item.id,
          };
        }),
      );
    }
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};
