import { VideoModalStore } from '@/compnents/videoModal';
import { MainSubStructureModel, type PaginationData } from '@/utils';
import { Image } from 'antd';
import dayjs from 'dayjs';
import { request } from '../../utils';
export default class Model {
  public userId = new URLSearchParams(window.location.search)?.get('id');

  constructor() {
    this.mainSubStructureModel.onQuery();
  }
  public videoModalStore = new VideoModalStore();

  public accessor mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      rowHeight: 90,
      columns: [
        {
          key: 'content',
          name: '审核内容',
          formatter: ({ row }) => {
            const { fileType, fileUrl, coverUrl, auditReason } = row;
            if (fileType === 0) {
              return '简历审核';
            } else if (fileType === 1) {
              return (
                <Image
                  src={fileUrl}
                  alt=""
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover',
                  }}
                />
              );
            } else if (fileType === 2) {
              return (
                <img
                  src={coverUrl}
                  alt=""
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover',
                  }}
                  onClick={() => {
                    this.videoModalStore.handleOpen({ url: fileUrl, coverUrl: coverUrl });
                  }}
                />
              );
            }

            return '';
          },
        },
        {
          key: 'opusType',
          name: '类型',
          formatter: ({ row }) => {
            const { opusType } = row;
            if (opusType === 0) {
              return '文本';
            } else if (opusType === 1) {
              return '形象照';
            } else if (opusType === 2) {
              return '切片';
            } else if (opusType === 3) {
              return '模卡';
            }

            return '切片';
          },
        },
        {
          key: 'auditStatus',
          name: '审核结果',
          formatter: ({ row }) => {
            const { auditStatus } = row;
            if (auditStatus == 1) {
              return <div className={'text-primary'}>审核通过</div>;
            }
            return '审核失败';
          },
        },
        {
          key: 'auditAdminName',
          name: '审核人',
        },
        {
          key: 'createTime',
          name: '审核时间',
          formatter: ({ row }) => {
            const { createTime } = row;
            return createTime ? dayjs(createTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: async (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        return request<PaginationData<any>>({
          url: '/pbb/platform/anchor/auditLog/list',
          method: 'post',
          data: { id: this.userId, ...postParams },
        });
      },
    },
  });
}
