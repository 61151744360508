import { ASSETS } from '@/assets';
import type { SliceInfoVO } from '@/pages/section/sectionAdmin/type.ts';
import { Image, RenderByPermission, getPageId, requireSource } from '@/utils';
import { CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import type React from 'react';
import { useState } from 'react';

interface IProps {
  cell: SliceInfoVO;
  boxStyle?: React.CSSProperties;
  boxClassName?: string;
  isChecked?: boolean;
  onChecked?: (checkId: number) => void;
  onTagEdit?: (id: number) => void;
  onReasonEdit?: (id: number) => void;
  onRecommend?: (id: number) => void;
  onCopy?: (str: string) => void;
  onImgClick?: () => void;
  onModelCardDownload?: () => Promise<void>;
  onAnchorInfoModel?: (id: number) => void;
}

export const SectionCard = observer((props: IProps) => {
  const [loading, setLoading] = useState(false);

  const {
    cell,
    boxStyle = {},
    boxClassName,
    isChecked,
    onChecked,
    onImgClick,
    onTagEdit,
    onReasonEdit,
    onRecommend,
    onModelCardDownload,
    onCopy,
    onAnchorInfoModel,
  } = props;

  return (
    <div
      className="p-4 rounded-2 cursor-pointer"
      onClick={() => {
        onChecked?.(cell.opusId);
      }}
      style={{
        background: isChecked ? 'rgba(230,247,255,0.7)' : 'transparent',
        border: isChecked ? '1px solid #3A6EF8' : '1px solid transparent',
      }}
    >
      <div
        className={`w-75 relative rounded-2 overflow-hidden ${boxClassName}`}
        style={boxStyle}
      >
        <Image
          style={{ height: '400px' }}
          className="w-75 !h-100 !object-cover bg-[#ccc]"
          src={cell?.coverUrl || cell?.fixleUrl}
        />
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onImgClick?.();
          }}
          className="absolute z-10 size-[160px] flex items-center justify-center top-30 left-[50%] translate-x-[-50%] cursor-pointer"
        >
          <img
            className={' size-[60px]'}
            src={ASSETS.COMMON.PLAY}
            alt={''}
          />
        </div>

        <div className="flex items-center justify-center absolute top-[358px] right-4 bg-[rgba(51,51,51,0.7)] h-7.5 rounded-4 text-14 pl-2 pr-2 text-white">
          热度: {cell?.viewCount}
        </div>
        <div
          className="absolute top-3 left-3 w-8 h-8 border-2 border-solid border-[#D9D9D9] bg-white rounded-1 flex justify-center items-center"
          style={{
            background: isChecked ? '#3A6EF8' : '#fff',
          }}
        >
          <CheckOutlined style={{ color: isChecked ? '#fff' : 'transparent', transition: '0.5s' }} />
        </div>
        <div className="p-4 bg-[#FAFAFA]">
          <div className="flex items-center justify-between relative">
            <div
              className="flex items-center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onAnchorInfoModel?.(cell.userId);
              }}
            >
              <Image
                className="!w-10 !h-10 !object-cover rounded-[50%] mr-2"
                src={cell?.avatar}
              />
              <div>
                {cell?.isFee && Number(cell?.isFee) === 1 ? (
                  <div className="flex">
                    <div
                      className="text-[#FFF1DD] w-14 text-10 p-2 pt-0.5 pb-0.5 rounded-0.5 scale-80 text-center flex-shrink-0"
                      style={{ background: 'linear-gradient( 270deg, #2F201F 0%, #735E4F 100%)' }}
                    >
                      已付费
                    </div>
                    <span className="text-[red] text-12">（{cell.payCount}）</span>
                  </div>
                ) : (
                  ''
                )}
                <div className={'flex flex-row items-center h-8'}>
                  {cell.name && <div className={'line-clamp-1 text-14'}>{cell?.name}</div>}
                  {!cell.name && (
                    <div className={'flex justify-between'}>
                      <div className={'line-clamp-1 text-14'}>{cell.nickName}</div>
                      <div className={'line-clamp-1 text-14'}>{cell.userId}</div>
                    </div>
                  )}
                  <img
                    src={requireSource('/common/idcard.png')}
                    alt={''}
                    className={'w-4 h-4 ml-1'}
                  />
                </div>
                {/* <span className="text-14">{cell?.name}</span>
                <img
                  src={requireSource('/common/idcard.png')}
                  alt={''}
                  className={'w-4 h-4 ml-1'}
                /> */}
              </div>
            </div>

            <div className="p-2 pt-1 pb-1 bg-[rgba(253,141,244,0.2)] text-[#FD8DF4] text-14">{cell?.userId}</div>
          </div>

          <div className="flex justify-between mt-3">
            {/* <span className="text-14 font-500">标签</span> */}
            <RenderByPermission permissionId={`${getPageId()}_68`}>
              <div className="flex justify-between">
                <span className="text-14 font-500">切片标签:</span>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onTagEdit?.(cell.opusId);
                  }}
                  className="flex items-center text-12 text-primary cursor-pointer"
                >
                  <img
                    className="w-3 h-3 mr-0.5"
                    alt=""
                    src={requireSource('/common/edit.png')}
                  />
                  编辑
                </div>
              </div>
            </RenderByPermission>
          </div>

          <div className="flex flex-wrap mt-2">
            {cell?.sysTagVOList?.map((tag) => {
              return (
                <div
                  key={tag.libId}
                  className="mr-1 mb-2 p-2 pt-1 pb-1 bg-[rgba(29,83,217,0.1)] border border-[rgba(58,110,248,0.4)] text-12 text-primary"
                >
                  {tag.libName}
                </div>
              );
            })}
          </div>
          <div className="flex justify-between mt-1">
            <span className="text-14 font-500">
              切片类目:
              {cell?.liveTypeName}
            </span>
          </div>
          <div className="flex justify-between mt-1">
            <span className="text-14 font-500">
              期望工作城市:
              {
                <div className="flex flex-wrap mt-2">
                  {cell?.provinceCityCountyList?.map((item) => {
                    return (
                      <div
                        key={item.provinceId}
                        className="mr-1 mb-2 p-2 pt-1 pb-1 bg-[rgba(29,83,217,0.1)] border border-[rgba(58,110,248,0.4)] text-12 text-primary"
                      >
                        {item.provinceName}-{item.cityName}-{item.countyName}
                      </div>
                    );
                  })}
                </div>
              }
            </span>
          </div>

          <div className="flex justify-between mt-1">
            <span className="text-14 font-500">推荐理由</span>
            <RenderByPermission permissionId={`${getPageId()}_69`}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onReasonEdit?.(cell.opusId);
                }}
                className="flex items-center text-12 text-primary cursor-pointer"
              >
                <img
                  className="w-3 h-3 mr-0.5"
                  alt=""
                  src={requireSource('/common/edit.png')}
                />
                编辑
              </div>
            </RenderByPermission>
          </div>

          <div className="flex justify-between mt-1">
            <span className="text-14 font-500">审核时间:{dayjs(cell?.auditTime).format('YYYY-MM-DD HH:mm:ss')}</span>
          </div>

          <div className="mt-3 text-12 text-normal">{cell?.butlerEvaluation}</div>

          <div className="mt-4 flex justify-between">
            <RenderByPermission permissionId={`${getPageId()}_67`}>
              <Button
                type="default"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onCopy?.(cell.sliceUrl);
                }}
              >
                复制链接
              </Button>
            </RenderByPermission>
            <RenderByPermission permissionId={`${getPageId()}_84`}>
              <>
                <Button
                  loading={loading}
                  type="dashed"
                  className="w-20"
                  onClick={async (e) => {
                    setLoading(true);
                    e.preventDefault();
                    e.stopPropagation();
                    try {
                      await onModelCardDownload?.();
                    } catch (error) {
                    } finally {
                      setLoading(false);
                    }
                  }}
                >
                  下载模卡
                </Button>
              </>
            </RenderByPermission>
            <RenderByPermission permissionId={`${getPageId()}_66`}>
              <>
                <Button
                  type="primary"
                  className="w-20"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRecommend?.(cell.opusId);
                  }}
                >
                  推荐
                </Button>
              </>
            </RenderByPermission>
          </div>
        </div>
      </div>
    </div>
  );
});
