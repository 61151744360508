import { ASSETS } from '@/assets';
import { type BaseData, MainSubStructureModel, request, requireSource } from '@/utils';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';

export class ServiceRecordModel {
  @observable public parent: any;
  @observable public visible = false;
  @observable public id: string | number;
  constructor(parent: any) {
    this.parent = parent;
  }

  @action onShow = (id: string | number) => {
    this.visible = true;
    this.id = id;
    this.mainSubStructureModel.gridModel.onQuery();
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    pageId: 'serviceRecord',
    grid: {
      columns: [
        {
          key: 'packageNameList',
          name: '服务名称',
        },
        {
          key: 'totalAmount',
          name: '支付金额',
        },
        {
          key: 'channelName',
          name: '付费来源',
        },
        {
          key: 'inviteName',
          name: '付费推荐人',
          formatter: ({ row }) => {
            const { inviteName, inviteAvatar, feeTimes, inviteUserId, inviteMobile } = row;
            return (
              <div className="flex items-center cursor-pointer h-full gap-2">
                {inviteAvatar && (
                  <div className="relative">
                    <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                      <img
                        src={inviteAvatar}
                        className="w-full h-full"
                        alt=""
                      />
                    </div>
                  </div>
                )}
                {inviteName || inviteUserId || inviteMobile ? (
                  <div className="text-12 leading-[18px]">
                    <div className="flex items-center">
                      {feeTimes ? (
                        <>
                          <img
                            src={ASSETS.COMMON.PAY_STATUS}
                            className="w-13"
                            alt=""
                          />
                          <div className="text-[red]"> ({feeTimes})</div>
                        </>
                      ) : null}
                    </div>
                    <div className="flex items-center">
                      用户昵称：{inviteName}
                      <img
                        src={requireSource('/common/idcard.png')}
                        alt={''}
                        className={'w-4 h-4 ml-1'}
                      />
                    </div>
                    <div>ID：{inviteUserId}</div>
                    <div>手机号：{inviteMobile}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          },
        },
        {
          key: 'payTime',
          name: '创建时间',
          formatter: ({ row }) => {
            const { payTime } = row;
            return payTime ? dayjs(payTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rowHeight: 80,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },

    hiddenSubTable: true,
    api: {
      onQuery: async (params) => {
        const { ...rest } = params;
        const postParams = {
          ...rest,
        };
        return request<BaseData<any>>({
          url: '/pbb/platform/sys/service/order/list',
          method: 'POST',
          data: {
            userId: this.id,
            ...postParams,
          },
        });
      },
    },
  });

  @action onClose = () => {
    this.visible = false;
  };
}
