import { request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';
import { API } from '../../api';

export class CanOwnInviteModel {
  @observable public visible = false;
  @observable public parent = null;
  @observable public ref: FormInstance;

  @observable public ids = [];
  constructor(parent) {
    this.parent = parent;
  }

  @action
  onShow = (ids) => {
    this.ids = ids;
    this.visible = true;
  };

  @action
  setRef = (ref: FormInstance) => {
    this.ref = ref;
  };
  @action
  onClose = () => {
    this.visible = false;
  };

  @action onSubmit = async () => {
    const isValidate = await this.ref.validateFields();
    if (!isValidate) {
      return;
    }
    const values = this.ref.getFieldsValue();
    await request({
      url: API.canOwnInvite,
      method: 'post',
      data: { userIdList: this.ids, canOwnInvite: values.value },
    });
    const { ids } = this;
    this.onClose();
    message.success('修改成功');
    this.parent?.mainStore.grid.onRefresh();
  };
}
