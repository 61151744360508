import { Form, Modal, Radio } from 'antd';
import { observer } from 'mobx-react';
import type { CanOwnInviteModel } from './model';

const CanOwnInvite = ({ store }: { store: CanOwnInviteModel }) => {
  const { onClose, onSubmit, visible, setRef } = store;
  return (
    <Modal
      title="批量可自主邀约"
      open={visible}
      onOk={onSubmit}
      onCancel={onClose}
      maskClosable={false}
    >
      <Form ref={setRef}>
        <Form.Item
          name="value"
          required={true}
        >
          <Radio.Group>
            <Radio value="1">是</Radio>
            <Radio value="0">否</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(CanOwnInvite);
