import { type BaseData, request } from '@/utils';
import { Form, type FormInstance, Modal, TreeSelect } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

interface Options {
  callback?: (params: { libId: unknown[] }, ...args: any[]) => Promise<any>;
  title?: string;
  categoryType?: number;
  libId?: unknown[];
}

interface TagItem {
  id?: string;
  name?: string;
  parentId?: string;
  children?: TagItem[];
}

export class TagModalStore {
  public formRef = React.createRef<FormInstance>();
  @observable public tagData: TagItem[] = [];
  private getTagData = async () => {
    const info = await request<BaseData<TagItem[]>>({
      url: '/pbb/platform/sys/tag/lib/list/all',
      params: { categoryType: this.options.categoryType },
    });

    this.tagData = (info.data || []).filter((item) => item?.children?.length > 0);
  };

  @observable public open = false;
  @observable.ref options: Options = {};
  @action public handleOpen = (options: Options) => {
    this.open = true;
    this.options = options;
    this.getTagData();
  };
  @action public handleClose = () => {
    this.open = false;
    this.options = {};
  };

  @observable public isSubmit = false;
  public handleSubmit = async () => {
    if (this.isSubmit) {
      return;
    }

    const values = await this.formRef.current.validateFields();

    this.isSubmit = true;
    try {
      await this.options.callback(values);
      this.handleClose();
    } finally {
      this.isSubmit = false;
    }
  };
}

export const TagModal = observer(
  ({
    tagModalStore: { open, handleClose, options, handleSubmit, isSubmit, formRef, tagData },
  }: { tagModalStore: TagModalStore }) => {
    return (
      <Modal
        destroyOnClose={true}
        centered={true}
        open={open}
        width={400}
        onCancel={handleClose}
        maskClosable={false}
        title={options.title}
        closable={true}
        confirmLoading={isSubmit}
        onOk={handleSubmit}
      >
        <Form
          ref={formRef}
          layout={'vertical'}
          autoComplete="off"
          initialValues={{ libId: options.libId }}
        >
          <Form.Item
            label={'标签'}
            name="libId"
          >
            <TreeSelect
              allowClear={true}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              placeholder={'请选择标签'}
              fieldNames={{ value: 'id', label: 'name' }}
              showSearch={true}
              treeData={tagData}
              filterTreeNode={(inputValue: string, treeNode: any) => {
                return treeNode.name?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1;
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
