import { type BaseData, request } from '@/utils';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import React, { useState } from 'react';

export const SearchSelect: React.FC<{
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  callback?: (value: string) => void;
  [key: string]: any;
  api: string;
  selectKeyword: string;
  name: string;
  nameValue: string;
  keyWord?: string;
  type?: string;
  mobile?: string;
  optionRender?: (item: any) => React.ReactNode;
  labelRender?: (item: any) => React.ReactNode;
}> = React.forwardRef((props, ref) => {
  const [data, setData] = useState<SelectProps['options']>([]);

  const handleSearch = (newValue: string) => {
    fetch(
      props.api,
      newValue,
      setData,
      props.selectKeyword,
      props.name,
      props.nameValue,
      props?.keyWord,
      props?.mobile,
    );
  };

  const renderOption = props.optionRender || null;
  const renderLabel = props.labelRender || null;

  return (
    <Select
      // @ts-ignore
      ref={ref}
      labelInValue={true}
      className={props.className}
      showSearch={true}
      value={props.value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      options={(data || []).map((d) => ({
        label: d.label,
        value: d.value,
        keyWord: d.keyWord,
        mobile: d.mobile,
      }))}
      onChange={(value, option: any) => {
        props.onChange(option);
      }}
      optionRender={renderOption}
      labelRender={renderLabel}
      allowClear={true}
      notFoundContent={null}
    />
  );
});

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (
  api: string,
  value: string,
  callback: (data: { label: any; value: any }[] | undefined) => void,
  selectKeyword?: string,
  name?: string,
  nameValue?: string,
  keyWord?: string,
  mobile?: string,
) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = async () => {
    const req = await request<BaseData<any>>({
      method: 'POST',
      url: api,
      data: {
        [selectKeyword]: currentValue,
        pageNum: 1,
        pageSize: 200,
      },
    });

    if (currentValue === value) {
      callback(
        (req?.data?.list || [])?.map((item) => {
          return {
            label: item[name],
            value: item[nameValue],
            keyWord: item[keyWord],
            mobile: item[mobile],
          };
        }),
      );
    }
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};
