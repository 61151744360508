import { VideoModal, VideoModalStore } from '@/compnents/videoModal';
import { EpUpload, RenderByPermission } from '@/utils';
import compressImgOrVideo from '@/utils/compress';
import getImgUrl from '@/utils/firstFrame';
import {
  DeleteOutlined,
  EnvironmentOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlayCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import type { InputRef } from 'antd';
import { AutoComplete, Button, Form, Image, Input, Modal, Radio, Spin, Tag, Upload, message } from 'antd';
import type { RcFile } from 'antd/es/upload';
import { observer } from 'mobx-react';
import { type ChangeEvent, useRef, useState } from 'react';
import type EditorModel from './model';
import type { AddressModel } from './model';

// 新增或编辑需求配置信息
export default observer(({ store }: { store: EditorModel }) => {
  const { isEdit } = store;
  const pageId = new URLSearchParams(window.location.search)?.get('pageId');
  return (
    <>
      <Modal
        centered={true}
        open={store.visible}
        onOk={() => {
          store.ref?.submit();
        }}
        maskClosable={false}
        onCancel={store.onCancel}
        confirmLoading={store.saveLoading}
        title="企业详情"
        width="800px"
        footer={[
          isEdit ? (
            <Button
              key="back"
              onClick={store.onCancel}
            >
              取消
            </Button>
          ) : null,
          <RenderByPermission
            key="submit"
            permissionId={`${pageId}_3`}
          >
            <Button
              type="primary"
              loading={store.saveLoading}
              onClick={() => {
                if (isEdit) {
                  store.ref?.submit();
                } else {
                  store.isEdit = true;
                }
              }}
            >
              {isEdit ? '保存' : '编辑'}
            </Button>
          </RenderByPermission>,
        ]}
      >
        <Spin spinning={store.detailLoading}>
          <Form
            className="max-h-[60vh] overflow-y-auto"
            ref={store.setRef}
            onFinish={store.beforeFinish}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
          >
            <Form.Item
              name="name"
              label="公司全称"
              rules={[
                {
                  required: true,
                  message: '请输入公司全称',
                },
              ]}
            >
              <Input
                placeholder="请输入公司全称"
                disabled={!isEdit}
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              name="employerName"
              label="关联雇主"
            >
              <Input
                style={{ border: 'unset', background: 'unset', color: '#000' }}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="intro"
              label="公司介绍"
            >
              <Input.TextArea
                disabled={!isEdit}
                placeholder="请输入公司介绍"
                maxLength={1000}
                showCount={true}
                autoSize={{ minRows: 6, maxRows: 10 }}
              />
            </Form.Item>
            <AddressList store={store} />

            <Form.Item
              name="scaleType"
              label="人员规模"
            >
              <Radio.Group disabled={!isEdit}>
                {Object.entries(store.scaleType).map(([key, value]) => (
                  <Radio
                    key={value.toString()}
                    value={String(key)}
                  >
                    {value}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <ImageUpload
              name="logoUrl"
              label="公司logo"
              message="请上传公司logo"
              store={store}
            />
            <VideoUpload
              name="videos"
              label="公司视频"
              message="请上传公司视频"
              store={store}
            />

            <ImageUploadMultiple
              name="images"
              label="公司图片"
              message="请上传公司图片"
              store={store}
            />
            <KeyWordTags
              name="businessVOS"
              label="主营业务"
              store={store}
            />
          </Form>
        </Spin>
      </Modal>
      <AddressModal store={store} />
      <AmapModal store={store.addressModal} />
    </>
  );
});

const ImageUpload = observer(
  (props: {
    readonly name: string;
    readonly label: string;
    readonly message: string;
    store: EditorModel;
  }) => {
    const [loading, setLoading] = useState(false);
    return (
      <Form.Item
        noStyle={true}
        shouldUpdate={(prevValues, nextValues) => {
          return prevValues[props.name] !== nextValues[props.name];
        }}
      >
        {({ getFieldValue, setFieldValue }) => {
          const imageUrl = getFieldValue(props.name);
          const customRequest = async (options: any) => {
            setLoading(true);
            const unit = options.file.name.split(',');
            const file: any = await compressImgOrVideo(options.file);
            if (file === false) {
              return;
            }
            const url = await EpUpload.singleUpload({
              attachmentType: 1,
              module: 'companyLogo',
              file,
            });
            await props.store.onMediaFinish({ logoUrl: url });
            setFieldValue(props.name, url);
            setLoading(false);
          };

          const beforeUpload = (file: RcFile) => {
            if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp') {
              message.error('图片格式只能为jpg、jpeg、png、webp');
              return false;
            }
            // 限制文件大小，例如限制为 2MB
            const isLt6M = file.size / 1024 / 1024 < 6;
            if (!isLt6M) {
              console.error('图片需要小于6MB!');
              return false;
            }
            return true;
          };

          return (
            <Form.Item
              label={props.label}
              name={props.name}
              getValueFromEvent={(e) => imageUrl}
              validateTrigger={false}
            >
              <Upload
                disabled={!props.store.isEdit}
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                accept=".jpg,.jpeg,.png,.webp"
              >
                {imageUrl ? (
                  <Image
                    src={imageUrl}
                    preview={!props.store.isEdit}
                    style={{
                      width: '80px',
                      height: '80px',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <button
                    style={{
                      border: 0,
                      background: 'none',
                    }}
                    type="button"
                  >
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div className="mt-2">上传</div>
                  </button>
                )}
              </Upload>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  },
);
const ImageUploadMultiple = observer(
  (props: {
    readonly name: string;
    readonly label: string;
    readonly message: string;
    store: EditorModel;
  }) => {
    const [loading, setLoading] = useState(false);
    const fileListRef = useRef<(RcFile | File)[]>([]);
    return (
      <Form.Item
        noStyle={true}
        shouldUpdate={(
          prevValues: (typeof props.store)['companyData'],
          nextValues: (typeof props.store)['companyData'],
        ) => {
          return prevValues[props.name] !== nextValues[props.name];
        }}
      >
        {({ getFieldValue, setFieldValue }) => {
          const imageUrl = getFieldValue(props.name) || [];

          const customRequest = async () => {
            setLoading(true);
            const fileList = await Promise.all(
              fileListRef.current.map(async (item: File | RcFile) => {
                return (await compressImgOrVideo(item)) as File;
              }),
            );

            const url = await EpUpload.multiUpload({
              attachmentType: 1,
              module: 'companyAlbum',
              fileList,
            });
            const newUrl = url.map((item: string) => {
              return { fileUrl: item, fileType: 1 };
            });
            props.store.onMediaFinish({ photoDTOS: newUrl });
            fileListRef.current = [];
            setLoading(false);
          };

          const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
            console.log('beforeUpload', file);
            if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp') {
              message.error('图片格式只能为jpg、jpeg、png、webp');
              return false;
            }

            // 限制文件大小，例如限制为 2MB
            const isLt6M = file.size / 1024 / 1024 < 6;
            if (!isLt6M) {
              console.error('图片需要小于6MB!');
              return false;
            }
            if (fileListRef.current.length === 0) {
              fileListRef.current = fileList;
              customRequest();
            }

            return false;
          };

          return (
            <Form.Item
              label={props.label}
              name={props.name}
              getValueFromEvent={() => imageUrl}
              validateTrigger={false}
            >
              <div className="flex items-center gap-2 flex-wrap">
                {imageUrl?.map((item: Record<string, any>, index: number) => {
                  return (
                    <div
                      key={`${index.toString()}`}
                      className="flex items-center justify-center w-[100px] h-[100px] rounded-2 box-content"
                      style={{
                        border: '1px solid #d9d9d9',
                      }}
                    >
                      <Image
                        style={{
                          width: '80px',
                          height: '80px',
                          objectFit: 'cover',
                        }}
                        src={item.fileUrl}
                        preview={
                          props.store.isEdit
                            ? {
                                mask: (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      props.store.removePhoto(item.id);
                                    }}
                                  >
                                    <DeleteOutlined style={{ fontSize: 24 }} />
                                  </div>
                                ),
                              }
                            : true
                        }
                      />
                    </div>
                  );
                })}
                <Upload
                  disabled={!props.store.isEdit}
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  // customRequest={customRequest}
                  beforeUpload={beforeUpload}
                  multiple={true}
                  accept=".jpg,.jpeg,.png,.webp"
                >
                  <button
                    style={{
                      border: 0,
                      background: 'none',
                    }}
                    type="button"
                  >
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div className="mt-2">上传</div>
                  </button>
                </Upload>
              </div>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  },
);
const VideoUpload = observer(
  (props: {
    readonly name: string;
    readonly label: string;
    readonly message: string;
    store: EditorModel;
  }) => {
    const [loading, setLoading] = useState(false);
    const fileListRef = useRef<(RcFile | File)[]>([]);
    return (
      <Form.Item
        noStyle={true}
        shouldUpdate={(
          prevValues: (typeof props.store)['companyData'],
          nextValues: (typeof props.store)['companyData'],
        ) => {
          return prevValues[props.name] !== nextValues[props.name];
        }}
      >
        {({ getFieldValue, setFieldValue }) => {
          const videoList = getFieldValue(props.name);

          const customRequest = async () => {
            try {
              setLoading(true);
              const fileList = fileListRef.current;

              const url = await EpUpload.multiUpload({
                attachmentType: 2,
                module: 'companyAlbum',
                fileList,
              });

              const coverFile = await Promise.all(
                url.map(async (item: string) => {
                  return (await getImgUrl(item)) as File;
                }),
              );

              const coverUrl = await EpUpload.multiUpload({
                attachmentType: 1,
                module: 'companyAlbum',
                fileList: coverFile,
              });

              const arr = url.map((item, index) => {
                return {
                  fileUrl: item,
                  coverUrl: coverUrl[index],
                  fileType: 2,
                };
              });
              await props.store.onMediaFinish({ photoDTOS: arr });
            } catch (error) {
            } finally {
              fileListRef.current = [];
              setLoading(false);
            }
          };

          const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
            const videoType = [
              'video/mp4',
              'video/flv',
              'video/avi',
              'video/wmy',
              'video/mov',
              'video/webm',
              'video/mpeg4',
              'video/ts',
              'video/mpg',
              'video/rm',
              'video/rmvb',
              'video/mkv',
              'video/m4v',
            ];
            if (!videoType.includes(file.type)) {
              message.error('视频格式只能为mp4,flv,avi,wmy,mov,webm,mpeg4,ts,mpg,rm,rmvb,mkv,m4v');
              return false;
            }
            // 限制文件大小，例如限制为 2MB
            const isLt300M = file.size / 1024 / 1024 < 300;
            if (!isLt300M) {
              console.error('视频需要小于300MB!');
              return false;
            }
            if (fileListRef.current.length === 0) {
              fileListRef.current = fileList;
              customRequest();
            }
            return false;
          };

          const videoModalStore = new VideoModalStore();

          return (
            <Form.Item
              label={props.label}
              name={props.name}
              getValueFromEvent={(e) => videoList}
              validateTrigger={false}
            >
              <div className="flex items-center gap-2">
                {videoList?.map((item: Record<string, any>, index: number) => {
                  return (
                    <div
                      key={`${index.toString()}`}
                      className="flex items-center justify-center w-[100px] h-[100px] rounded-2 box-content"
                      style={{
                        border: '1px solid #d9d9d9',
                      }}
                    >
                      <Image
                        style={{
                          width: '80px',
                          height: '80px',
                          objectFit: 'cover',
                        }}
                        src={item.coverUrl}
                        preview={
                          props.store.isEdit
                            ? {
                                mask: (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      props.store.removePhoto(item.id);
                                    }}
                                  >
                                    <DeleteOutlined style={{ fontSize: 24 }} />
                                  </div>
                                ),
                              }
                            : {
                                mask: (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      videoModalStore.handleOpen({
                                        url: item.fileUrl,
                                        coverUrl: item.coverUrl,
                                      });
                                    }}
                                  >
                                    <PlayCircleOutlined style={{ fontSize: 24 }} />
                                  </div>
                                ),
                              }
                        }
                      />
                      <VideoModal videoModalStore={videoModalStore} />
                    </div>
                  );
                })}

                <Upload
                  disabled={!props.store.isEdit}
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  multiple={true}
                  // mp4,flv,avi,wmy,mov,webm,mpeg4,ts,mpg,rm,rmvb,mkv,m4v
                  accept=".mp4,.flv,.avi,.wmy,.mov,.webm,.mpeg4,.ts,.mpg,.rm,.rmvb,.mkv,.m4v"
                >
                  <button
                    style={{
                      border: 0,
                      background: 'none',
                    }}
                    type="button"
                  >
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div className="mt-2">上传</div>
                  </button>
                </Upload>
              </div>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  },
);

const KeyWordTags = observer(
  (props: {
    readonly name: string;
    readonly label: string;
    readonly message?: string;
    store: EditorModel;
  }) => {
    const inputRef = useRef<InputRef>(null);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');

    return (
      <Form.Item
        noStyle={true}
        shouldUpdate={(prevValues, nextValues) => {
          return prevValues[props.name] !== nextValues[props.name];
        }}
      >
        {({ getFieldValue, setFieldValue }) => {
          const tagsArr = getFieldValue(props.name) || [];
          const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value);
          };

          const handleInputConfirm = () => {
            if (inputValue && !tagsArr.some((ix: { name: string }) => ix.name === inputValue)) {
              console.log([...tagsArr, { name: inputValue }]);
              setFieldValue(props.name, [...tagsArr, { name: inputValue }]);
            }
            setInputVisible(false);
            setInputValue('');
          };

          const showInput = () => {
            if (props.store.isEdit) {
              setInputVisible(true);
            }
          };
          const handleClose = (e: React.MouseEvent<HTMLElement>, removedTag: { name: string }) => {
            e.preventDefault();
            if (props.store.isEdit) {
              setFieldValue(
                props.name,
                tagsArr.filter((ix: { name: string }) => ix.name !== removedTag.name),
              );
            }
          };

          return (
            <Form.Item
              label={props.label}
              name={props.name}
              getValueFromEvent={() => tagsArr}
            >
              <div className="flex items-center ">
                {tagsArr?.map((item: { name: string }, index: number) => (
                  <Tag
                    key={`${index.toString()}`}
                    closable={props.store.isEdit}
                    style={{ userSelect: 'none' }}
                    onClose={(e) => handleClose(e, item)}
                  >
                    <span>{item.name}</span>
                  </Tag>
                ))}

                {inputVisible ? (
                  <Input
                    className="w-24"
                    ref={inputRef}
                    type="text"
                    size="small"
                    value={inputValue}
                    maxLength={6}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                ) : (
                  props.store.isEdit && (
                    <Tag
                      icon={<PlusOutlined />}
                      onClick={showInput}
                    >
                      自定义标签
                    </Tag>
                  )
                )}
              </div>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  },
);

const AddressList = ({ store }: { store: EditorModel }) => {
  return (
    <Form.Item label="工作地址">
      <Form.List name="addressVOList">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => {
              return (
                <div
                  key={field.key}
                  className="flex items-baseline gap-2"
                >
                  <Form.Item
                    {...field}
                    name={[field.name, 'info']}
                    className="flex-1"
                  >
                    <Input
                      disabled={true}
                      placeholder="公司地址"
                    />
                  </Form.Item>

                  {store.isEdit && (
                    <MinusCircleOutlined
                      className="text-[red]"
                      onClick={() => store.removeAddress(field.key)}
                    />
                  )}
                </div>
              );
            })}
            <Button
              disabled={!store.isEdit}
              onClick={() => {
                const employerId = String(store.companyData?.employerId);
                store.addressModal.onOpen(employerId);
              }}
              icon={<PlusOutlined />}
            >
              新增地址
            </Button>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};

export const AddressModal = observer(({ store }: { store: EditorModel }) => {
  const { addressModal } = store;
  return (
    <Modal
      zIndex={2000}
      centered={true}
      open={addressModal.visible}
      onOk={() => {
        addressModal.ref?.submit();
      }}
      maskClosable={false}
      onCancel={addressModal.onCancel}
      confirmLoading={addressModal.saveLoading}
      title="新增地址"
    >
      <Form
        ref={addressModal.setRef}
        onFinish={addressModal.onFinish}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
      >
        {addressModal.options?.map((item) => item.value)}
        <Form.Item label="选择地址">
          <div className="flex items-center gap-2">
            <Form.Item
              name="info"
              noStyle={true}
            >
              <Input
                disabled={true}
                className="flex-1"
                placeholder="请选择完整地址(包含省市区)"
              />
            </Form.Item>
            <EnvironmentOutlined
              onClick={addressModal.amapModal.onOpen}
              style={{ cursor: 'pointer', color: '#1890ff', fontSize: '24px' }}
            />
          </div>
        </Form.Item>

        <Form.Item
          label="楼层/门牌号"
          name="officeBuilding"
        >
          <Input
            placeholder="请输入楼层/门牌号"
            maxLength={10}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export const AmapModal = observer(({ store }: { store: AddressModel }) => {
  const { amapModal } = store;

  return (
    <Modal
      zIndex={2001}
      centered={true}
      onCancel={amapModal.onCancel}
      open={amapModal.visible}
      onOk={() => {
        amapModal.ref?.submit();
      }}
      title="搜索地址"
    >
      <Form
        ref={amapModal.setRef}
        onFinish={() => {
          amapModal.onFinish();
        }}
      >
        <Form.Item
          name="addressText"
          label="搜索地址"
        >
          <AutoComplete
            options={amapModal.options}
            onSelect={amapModal.onSelect}
            onSearch={amapModal.onSearch}
            placeholder="输入关键词模糊查询地址"
          />
        </Form.Item>
      </Form>
      <div
        id="containerMap"
        className="w-full h-[400px]"
      />
    </Modal>
  );
});
