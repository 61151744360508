import { ImportFile } from '@/compnents/import/importFile.tsx';
import { ImportFailList } from '@/compnents/importFailList/index.tsx';
import { MainSubStructure, NormalProgrammeComponent } from '@/utils';
import { observer } from 'mobx-react';
import React from 'react';
import { ShowToPartBroker } from './components/add/showToPartBroker.tsx';
import ModalAssemble from './components/modal/index.tsx';
import PositionCard from './components/positionCard.tsx';
import InformationPopupModel from './informationPopupModel.tsx';
import PositionManagementModel from './model.tsx';

@observer
export default class PositionManagement extends React.Component {
  constructor(prop) {
    super(prop);
    this.store = new PositionManagementModel();
  }
  public readonly store: PositionManagementModel;

  render() {
    const { normalProgramme, mainSubStructureModel, importStore } = this.store;
    return (
      <div className="h-screen flex flex-col">
        <NormalProgrammeComponent store={normalProgramme} />
        <div className="flex-1">
          <MainSubStructure store={mainSubStructureModel} />
        </div>
        <ModalAssemble store={this.store} />
        <ShowToPartBroker store={this.store.showToPartBrokerModel} />
        <PositionCard store={this.store.recommendedPositionModel} />
        {/* 导入弹窗 */}
        <ImportFile store={importStore} />
      </div>
    );
  }
}
