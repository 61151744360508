import { MainSubStructure, NormalProgrammeComponent } from '@/utils';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import type EstimatedRevenueModel from './estimatedRevenueModel';

const EstimatedRevenue = ({ store }: { store: EstimatedRevenueModel }) => {
  const { visible, onClose, filterSet, mainSubStructureModel, anchorInviteCount, totalAmount } = store;

  const mainDataList = [
    {
      title: '注册主播',
      value: store.anchorInviteCount,
      unit: '人',
    },
    {
      title: '预估收益',
      value: store.totalAmount,
      unit: '元',
    },
  ];

  return (
    <Modal
      open={visible}
      centered={true}
      onCancel={onClose}
      width={'60%'}
      title="预估收益"
      footer={null}
      maskClosable={false}
    >
      <div className="bg-[#F0F3F8] h-[500px] flex flex-col">
        <div className="bg-white pl-8 pr-8">
          <div className="pb-2 pt-6">
            <NormalProgrammeComponent store={filterSet} />
          </div>
        </div>
        <div className="flex justify-around pt-4 pb-4 mt-2 bg-white">
          {mainDataList.map((item) => {
            return (
              <div
                key={item.title + item.value}
                className="flex"
              >
                <div className="mt-2">{item.title}：</div>
                <div className="text-error text-12 mt-2 ml-5">
                  {item.value}
                  {item?.unit || ''}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex-1 bg-white mt-2 pt-2">
          <MainSubStructure store={mainSubStructureModel} />
        </div>
      </div>
    </Modal>
  );
};

export default observer(EstimatedRevenue);
