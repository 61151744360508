import SectionReport from '@/pages/section/sectionReport';
import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useRoutes } from 'react-router-dom';
import { DefaultLayout } from './compnents/defaultLayout';
import { NoPage } from './compnents/noPage';

const AnchorManagement = React.lazy(() => import('./pages/anchor/anchorManagement'));
const CompanyInfo = React.lazy(() => import('./pages/employer/companyInfo'));
const FinanceDetail = React.lazy(() => import('./pages/finance/financeDetail'));
const AllOrder = React.lazy(() => import('./pages/order/allOrder'));
const InterviewAdmin = React.lazy(() => import('./pages/finance/interviewAdmin'));
const SectionAdmin = React.lazy(() => import('./pages/section/sectionAdmin'));
const TagsManagement = React.lazy(() => import('./pages/tagsManagement'));

const EmployerManagement = React.lazy(() => import('./pages/employer/employerManagement'));
const Login = React.lazy(() => import('./pages/login/index'));
const Administrators = React.lazy(() => import('./pages/administrators/index'));

const Home = React.lazy(() => import('./pages/home'));
const AuthorityManagement = React.lazy(() => import('./pages/authorityManagement'));
const BusinessCardManagement = React.lazy(() => import('./pages/user/businessCardManagement'));
const PositionManagement = React.lazy(() => import('./pages/positionManagement'));

const HouseKeeperManagement = React.lazy(() => import('./pages/user/housekeeperManagement'));

const Employment = React.lazy(() => import('./pages/employment/index'));
const JDLibrary = React.lazy(() => import('./pages/clue/jdLibrary')); // JD库=未合作职位
const VirtualEmployer = React.lazy(() => import('./pages/clue/virtualEmployer')); // 虚拟雇主管理

const Marketing = React.lazy(() => import('./pages/tools/marketing'));

const WeComAI = React.lazy(() => import('./pages/user/WeComAI'));

const RedEnvelopeModel = React.lazy(() => import('./pages/tools/redEnvelope'));
const AnchorDetail = React.lazy(() => import('./pages/anchorDetail/anchorDetail'));
const AuditLog = React.lazy(() => import('./pages/auditLog'));
const WhiteList = React.lazy(() => import('./pages/user/whiteList'));
const ContractManagement = React.lazy(() => import('./pages/user/contractManagement'));

const ServiceManagement = React.lazy(() => import('./pages/set/serviceManagement'));

const RevenueShare = React.lazy(() => import('./pages/set/revenueShare'));
const AnchorAuditLog = React.lazy(() => import('./pages/anchor/anchorAuditLog'));

export type MenuDataItem = {
  name?: string;
  children?: MenuDataItem[];
} & RouteObject;

export const routes: MenuDataItem[] = [
  {
    name: '',
    element: <DefaultLayout />,
    children: [
      {
        path: '/',
        element: (
          <Navigate
            replace={true}
            to="/pbb-pc-management/home/index"
          />
        ),
      },
      {
        path: '/pbb-pc-management/home/index',
        title: '首页',
        element: <Home />,
      },
      {
        path: '/pbb-pc-management/positionManagement/index',
        title: '职位管理',
        element: <PositionManagement />,
      },
      {
        path: '/pbb-pc-management/authorityManagement/index',
        title: '权限管理',
        element: <AuthorityManagement />,
      },
      {
        path: '/pbb-pc-management/administrators/index',
        name: '用户管理',
        element: <Administrators />,
      },
      {
        path: '/pbb-pc-management/login',
        title: '登录',
        element: <Login />,
      },

      {
        path: '/pbb-pc-management/employerManagement/index',
        title: '雇主管理',
        element: <EmployerManagement />,
      },
      {
        path: '/pbb-pc-management/clue/jdLibrary/index',
        title: 'JD库',
        element: <JDLibrary />,
      },
      {
        path: '/pbb-pc-management/clue/virtualEmployer/index',
        title: '虚拟雇主管理',
        element: <VirtualEmployer />,
      },
      {
        path: '/pbb-pc-management/section/sectionAdmin/index',
        title: '切片管理',
        element: <SectionAdmin />,
      },
      {
        path: '/pbb-pc-management/section/sectionTags/index',
        title: '切片标签',
        element: <SectionAdmin />,
      },
      {
        path: '/pbb-pc-management/anchorManagement/index',
        title: '主播管理',
        element: <AnchorManagement />,
      },
      {
        /**
         * 配置菜单时，增加type参数，如/pbb-pc-management/tagsManagement/index?type=0
         * 业务分类 0雇主 1经纪人 2切片 3职位 4管家 5主播
         */
        path: '/pbb-pc-management/tagsManagement/index',
        title: '标签',
        element: <TagsManagement />,
      },

      {
        path: '/pbb-pc-management/businessCardManagement/index',
        title: '名片管理',
        element: <BusinessCardManagement />,
      },
      {
        path: '/pbb-pc-management/hireHousekeeper',
        title: '管家管理',
        element: <HouseKeeperManagement />,
      },
      {
        path: '/pbb-pc-management/companyInfo/index',
        title: '公司信息',
        element: <CompanyInfo />,
      },
      { path: '/pbb-pc-management/finance/financeDetail/index', title: '资金明细', element: <FinanceDetail /> },
      {
        path: '/pbb-pc-management/finance/interviewAdmin/index',
        title: '到面次数管理',
        element: <InterviewAdmin />,
      },
      {
        path: '/pbb-pc-management/employment/index',
        title: '求职招聘',
        element: <Employment />,
      },
      {
        path: '/pbb-pc-management/marketing/index',
        title: '营销工具',
        element: <Marketing />,
      },
      {
        path: '/pbb-pc-management/redEnvelope/index',
        title: '发红包',
        element: <RedEnvelopeModel />,
      },
      {
        path: '/pbb-pc-management/anchorDetail/:id',
        title: '主播详情',
        element: <AnchorDetail />,
      },
      {
        path: '/pbb-pc-management/auditLog',
        title: '审核记录',
        element: <AuditLog />,
      },
      {
        path: '/pbb-pc-management/weComAI',
        title: '企微机器人',
        element: <WeComAI />,
      },
      {
        path: '/pbb-pc-management/sectionReport/index',
        title: '切片举报',
        element: <SectionReport />,
      },
      {
        path: '/pbb-pc-management/order/allOrder',
        title: '全部订单',
        element: <AllOrder />,
      },
      {
        path: '/pbb-pc-management/whiteList/index',
        title: '白名单',
        element: <WhiteList />,
      },
      {
        path: '/pbb-pc-management/serviceManagement/index',
        title: '服务管理',
        element: <ServiceManagement />,
      },

      {
        path: '/pbb-pc-management/revenueShare/index',
        title: '分账设置',
        element: <RevenueShare />,
      },
      {
        path: '/pbb-pc-management/contract/index',
        title: '协议管理',
        element: <ContractManagement />,
      },
      {
        path: '/pbb-pc-management/anchorAuditLog/index',
        title: '主播审核',
        element: <AnchorAuditLog />,
      },
      {
        path: '*',
        element: <NoPage />,
      },
    ],
  },
];

export function RenderRoutes() {
  return useRoutes(routes);
}
