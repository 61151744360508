import { type BaseData, request } from '@/utils';
import { Button, Form, Modal, Select, type SelectProps, Space } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import React from 'react';
import { SearchSelect } from '../utils';
import type ChangeBrokerModel from './changeBrokerModel';

interface miniData {
  data: {
    keyWord?: string;
  };
  label: string;
  value: any;
}

const ChangeBroker = ({ store }: { store: ChangeBrokerModel }) => {
  const [selectOption, setSelectOption] = useState<{ keyWord?: string }>({});

  useEffect(() => {
    selectOption;
  }, [selectOption]);

  const { visible, onClose, setRef, onSave, saveLoading } = store;

  return (
    <Modal
      centered={true}
      // title="选择经纪人用户"
      width={420}
      open={visible}
      onCancel={onClose}
      footer={
        <Space>
          <Button onClick={onClose}>取消</Button>
          <Button
            loading={saveLoading}
            onClick={onSave}
            type="primary"
          >
            保存
          </Button>
        </Space>
      }
    >
      <Form ref={setRef}>
        <div className="font-800 mb-2">
          <span className="text-error mr-1 font-400">*</span>选择经纪人用户
        </div>
        <Form.Item
          label="选择内部用户"
          name="adminId"
          labelCol={{ span: 8 }} // 使用花括号包裹 span 属性
        >
          <SearchSelect
            placeholder={'输入用户昵称/手机号/ID搜索选择'}
            api="/pbb/platform/sys/user/list/page"
            className={'!w-54'}
            selectKeyword="keyWord"
            name="nickName"
            nameValue="id"
            mobile="mobile"
          />
        </Form.Item>
        <Form.Item
          label="选择小程序用户"
          name="userId"
          labelCol={{ span: 8 }}
        >
          <SearchSelect
            placeholder={'输入用户昵称/手机号/ID搜索选择'}
            className={'!w-54'}
            api="/pbb/platform/anchor/list"
            selectKeyword="name"
            name="nickName"
            nameValue="id"
            keyWord="avatar"
            mobile="mobile"
            type="mini"
            optionRender={(item: miniData) => {
              return (
                <div className="flex">
                  <img
                    src={item.data.keyWord}
                    alt=" "
                    className="w-8 h-8 rounded-full mr-2"
                  />
                  <span>{item.label}</span>
                </div>
              );
            }}
            onChange={(option) => {
              setSelectOption(option);
            }}
            labelRender={(item: miniData) => {
              const keyWord = selectOption.keyWord;
              return (
                <div className="flex">
                  <img
                    src={keyWord}
                    alt=" "
                    className="w-5 h-5 rounded-full mr-2 mt-1"
                  />
                  <span>{item.label}</span>
                </div>
              );
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(ChangeBroker);
