import { type BaseData, MainSubStructureModel, request } from '@/utils';
import { action, observable } from 'mobx';
import { API } from '../api';
export default class GroupListModel {
  @observable public visible = false;
  @observable public parent = null;
  @observable public id: number;

  constructor({ parent }) {
    this.parent = parent;
  }

  @action public onShow = async (data) => {
    this.visible = true;
    const { sysUserInfo } = data;
    this.id = sysUserInfo.sysUserId;
    await this.groupListGrip.onQuery();
  };

  @action public onCancel = () => {
    this.visible = false;
  };

  public groupListGrip: MainSubStructureModel = new MainSubStructureModel({
    pageId: 'groupList',
    grid: {
      columns: [
        {
          key: 'name',
          name: '微信群列表',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      rowHeight: 80,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: async (params) => {
        const { ...rest } = params;
        const postParams = {
          ...rest,
          sysUserId: this.id,
        };
        return request<BaseData<any>>({
          url: API.queryGroupChatPage,
          method: 'POST',
          data: {
            ...postParams,
          },
        });
      },
    },
  });
}
