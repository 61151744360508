import { MainSubStructure } from '@/utils';
import { Button, Modal, Space } from 'antd';
import { observer } from 'mobx-react';
import type { ChangeBulterModel } from './model';

export const ChangeButler = observer(({ store }: { store: ChangeBulterModel }) => {
  const { visible, onClose, onSave, mainSubStructureModel } = store;
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={
        <Space>
          <Button onClick={onClose}>取消</Button>
          <Button
            onClick={onSave}
            type="primary"
          >
            保存
          </Button>
        </Space>
      }
      title="选择招聘管家"
      centered={true}
      closable={true}
      width="60%"
    >
      <div className="h-93">
        <MainSubStructure store={mainSubStructureModel} />
      </div>
    </Modal>
  );
});
