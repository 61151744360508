import AnchorInfoModal from '@/compnents/anchorInfo';
import { MainSubStructure } from '@/utils';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import EditProtocol from './modal/editProtocol';
import Model from './model';

const ContractManagement = () => {
  const store = useMemo(() => new Model(), []);

  const { protocolStore, editProtocolStore } = store;

  return (
    <div style={{ height: '100vh' }}>
      <MainSubStructure store={protocolStore} />
      <EditProtocol store={editProtocolStore} />
    </div>
  );
};

export default observer(ContractManagement);
