import { type BaseData, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';

export default class ServiceModel {
  @observable public ref: FormInstance = null;
  @observable public saveLoading = false; // 保存状态

  constructor() {
    this.onShow();
  }
  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  public onShow = () => {
    const keyList = ['butler_to_face_amount', 'invite_service_amount', 'invite_service_number', 'social_group_amount'];
    return request<BaseData<any>>({
      url: '/pbb/platform/anon/system/configDict/getValue/batch',
      method: 'post',
      data: {
        keyList,
      },
    }).then((resp) => {
      setTimeout(() => {
        this.ref.setFieldsValue(resp.data);
      }, 100);
    });
  };
}
