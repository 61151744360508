import { type BaseData, request } from '@/utils';
import { Button, Input, Modal, Radio, message } from 'antd';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import type { ModalProps } from './interface';

class Store {
  @observable public evalutionList: { dictName: string; dictValue: string; checked: boolean }[] = [];
  @observable public evaluate = '';
  @observable public loading = false;
  @observable public interviewed = '1';
  @observable public passed = '1';
  @observable public item: any;
  @observable public cb: () => void;
  @observable public onCancel: () => void;
  @observable public defaultTags: string[];
  @action public getList = async (dictType) => {
    const res = await request<BaseData<{ dictName: string; dictValue: string }[]>>({
      url: '/pbb/platform/anon/system/sysDict/getListByType',
      method: 'POST',
      data: { dictType },
    });
    const list = res.data.map((el) => {
      return { ...el, checked: false };
    });
    this.evalutionList = list;
    if (this.defaultTags.length) {
      this.changeEvaluation(this.defaultTags);
      this.defaultTags = [];
    }
  };

  @action public getInfo = async () => {
    const res = await request<BaseData<any>>({
      url: '/pbb/platform/sys/recruit/getInterviewFeedbackByEmployer',
      method: 'POST',
      data: {
        id: this.item.recruitId,
      },
    });
    this.defaultTags = res.data.employerEvaluateType;
    this.onValChange('interviewed', res.data.interviewed.toString());
    this.onValChange('passed', res.data.isThrough.toString());
    this.onValChange('evaluate', res.data.interviewEvaluate);
  };

  @action public changeEvaluation = (values: string[]) => {
    console.log('changeEvaluation', values, toJS(this.evalutionList));
    this.evalutionList = this.evalutionList.map((el) => {
      const index = values.findIndex((id) => id === el.dictValue);
      return { ...el, checked: index !== -1 ? !el.checked : el.checked };
    });
  };

  @action public onValChange = (type: string, val: string) => {
    console.log('onValChange', type, val);
    this[type] = val;
    if (type === 'passed') {
      this.getList(val === '1' ? 'employer_interview_type' : 'employer_interview_type_un_through');
    }
    console.log(this.interviewed);
  };

  @action public onSubmit = async () => {
    const employerEvaluateType = [];
    for (let i = 0; i < this.evalutionList.length; i++) {
      const element = this.evalutionList[i];
      if (element.checked) {
        employerEvaluateType.push(element.dictValue);
      }
    }
    try {
      this.loading = true;
      const res = await request<BaseData<string>>({
        url: '/pbb/platform/sys/recruit/employerInterviewFeedback',
        method: 'POST',
        data: {
          recruitId: this.item.recruitId,
          // isThrough: this.passed,
          interviewed: this.interviewed,
          interviewEvaluate: this.evaluate,
          employerEvaluateType,
        },
      });
      message.success(res.data);
      this.cb();
      this.onClose();
    } finally {
      this.loading = false;
    }
  };
  @action public onClose = () => {
    this.onCancel();
    this.changeEvaluation([]);
    this.onValChange('evaluate', '');
    this.interviewed = '1';
    this.passed = '1';
    this.evaluate = '';
  };
}
const EmployerEvaluation = ({ visible, onCancel, item, type, cb }: ModalProps) => {
  const store = useMemo(() => new Store(), []);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!visible) {
      return;
    }
    store.cb = cb;
    store.item = item;
    store.onCancel = onCancel;
    if (type === 'edit') {
      store.getInfo();
    } else {
      store.getList('employer_interview_type');
    }
  }, [visible]);
  const Item = ({
    el,
    size,
    value,
    type,
    onChange,
  }: {
    el: { dictName: string; dictValue: string; checked?: boolean };
    size: string;
    value?: string;
    type?: string;
    onChange: (value: string) => void;
  }) => {
    const checked = type === 'multiple' ? el.checked : value === el.dictValue;

    return (
      <div
        key={el.dictValue}
        className={'text-heading text-14 px-2 py-2 truncate mt-3 mr-3 relative font-500 text-center w-[140px]}'}
        style={{ background: checked ? '#E7F4FD' : '#F8FAFB' }}
        onClick={() => onChange(el.dictValue)}
      >
        {el.dictName}
      </div>
    );
  };
  return (
    <Modal
      open={visible}
      title={''}
      centered={true}
      onCancel={onCancel}
      maskClosable={false}
      confirmLoading={store.loading}
      onOk={store.onSubmit}
    >
      <br />
      <div>
        <div className="flex items-center">
          <div className="text-heading text-14 font-500 w-[200px]">
            <span className="text-[#FF5842]">*</span>
            该主播是否已参加面试:
          </div>
          <Radio.Group
            value={store.interviewed}
            onChange={(e) => store.onValChange('interviewed', e.target.value)}
          >
            <Radio value={'0'}>未到面</Radio>
            <Radio value={'1'}>已到面</Radio>
          </Radio.Group>
        </div>
        {/* {store.interviewed === '1' && (
          <div className="flex mt-3 items-center">
            <div className="text-heading text-14 font-500 w-[100px]">
              <span className="text-[#FF5842]">*</span>
              面试是否通过:
            </div>
            <Radio.Group
              value={store.passed}
              onChange={(e) => store.onValChange('passed', e.target.value)}
            >
              <Radio value={'1'}>通过</Radio>
              <Radio value={'0'}>未通过</Radio>
            </Radio.Group>
          </div>
        )} */}
      </div>
      {/* {store.interviewed === '1' && (
        <div className="mb-5">
          <div className="text-heading text-14 font-500 mt-3 mb-1">
            面试评价 <span className="text-[#C8CACB]">(可多选)</span>
          </div>
          <div className="flex flex-wrap">
            {store.evalutionList.map((el) => {
              return (
                <Item
                  key={el.dictValue}
                  el={el}
                  size="small"
                  type="multiple"
                  onChange={() => store.changeEvaluation([el.dictValue])}
                />
              );
            })}
          </div>
          <Input.TextArea
            showCount={true}
            className="rounded-1 w-full px-3 !h-30 !mt-2"
            placeholder="请输入其他原因"
            value={store.evaluate}
            maxLength={50}
            onChange={(e) => store.onValChange('evaluate', e.target.value)}
          />
        </div>
      )} */}
    </Modal>
  );
};

export default observer(EmployerEvaluation);
