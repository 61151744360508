import { API } from '@/compnents/anchorInfo/api.ts';
import type { UserVO } from '@/compnents/anchorInfo/type.ts';
import { type BaseData, copyToClipboard, getWxUrl, request } from '@/utils';
import { message } from 'antd';
import { action, observable } from 'mobx';

export default class AnchorInfoModel {
  @observable public visible = false;
  @observable public userVo: UserVO = null;
  @observable public type: string;
  //查询职位详情
  getAnchorDetail = async (id: string) => {
    const results = await request<BaseData<UserVO>>({ url: API.getAnchorDetail, method: 'post', data: { id } });
    this.userVo = results.data;
  };
  @action
  onShow = async (userId: string, type?: string) => {
    await this.getAnchorDetail(userId);
    this.visible = true;
    if (type) {
      this.type = type;
    }
  };

  @action
  onClose = () => {
    this.userVo = null;
    this.visible = false;
  };

  @action public shareAnchor = (id: number) => {
    getWxUrl({
      channel: 6,
      anchorId: id,
    }).then((url) => {
      copyToClipboard(url);
      message.success('复制分享主播链接成功');
    });
  };
}
