import { MainSubStructure } from '@/utils';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import GroupList from './groupList';
import InsertUser from './insertUser';
import Model from './model';

const WeComAI = () => {
  const store = useMemo(() => new Model(), []);
  const { weComAIgrid, groupListStore, insertUserStore } = store;

  return (
    <div className="h-screen">
      <MainSubStructure store={weComAIgrid} />
      <GroupList store={groupListStore} />
      <InsertUser store={insertUserStore} />
    </div>
  );
};

export default observer(WeComAI);
