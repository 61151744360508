import { cloneElement } from 'react';
import type { ReactElement } from 'react';

import { toJS } from 'mobx';
import DataGrid, { type DataGridProps, type Column } from 'pbb-data-grid';
import { get } from './helper';
interface SupplementOptions<R, SR> {
  fileName?: string; // 文件名
  exclude?: string[]; // 排除导出列
  supplementColumns?: Column<R, SR>[]; // 补充导出列
}

export async function exportTableToCsv<R, SR>(
  _rows: R[],
  _columns: Column<R, SR>[],
  supplementOptions?: SupplementOptions<R, SR>,
) {
  if (!_rows || !_columns) {
    return;
  }
  const rows = toJS(_rows);
  let columns = toJS(_columns);
  const { fileName, exclude, supplementColumns } = supplementOptions || {};
  if (exclude) {
    columns = columns.filter((column) => !exclude.includes(column.key) && !exclude.includes(column.name as string));
  }
  if (supplementColumns) {
    supplementColumns.forEach((item) => {
      const alreadyIndex = columns.findIndex((column) => column.key === item.key);
      if (alreadyIndex === -1) {
        columns.push(item);
      } else {
        columns[alreadyIndex] = item;
      }
    });
  }
  columns.forEach((column) => {
    if (!column.formatter) {
      column.formatter = (({ row }) => {
        const value = get(row, column.key);
        if (!value) {
          return '';
        }
        return value;
      }) as any;
    }
    return column;
  });
  const gridElement = (
    <DataGrid
      rows={rows}
      columns={columns}
    />
  );
  exportToCsv(gridElement, fileName || '导出');
}

export async function exportToCsv<R, SR>(gridElement: ReactElement<DataGridProps<R, SR>>, fileName: string) {
  const { head, body, foot } = await getGridContent(gridElement);
  const content = [...head, ...body, ...foot].map((cells) => cells.map(serialiseCellValue).join(',')).join('\n');

  downloadFile(fileName, new Blob([content], { type: 'text/csv;charset=utf-8;' }));
}

export async function exportToXlsx<R, SR>(gridElement: ReactElement<DataGridProps<R, SR>>, fileName: string) {
  const [{ utils, writeFile }, { head, body, foot }] = await Promise.all([import('xlsx'), getGridContent(gridElement)]);
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([...head, ...body, ...foot]);
  utils.book_append_sheet(wb, ws, 'Sheet 1');
  writeFile(wb, fileName);
}

async function getGridContent<R, SR>(gridElement: ReactElement<DataGridProps<R, SR>>) {
  const { renderToStaticMarkup } = await import('react-dom/server');
  const grid = document.createElement('div');
  grid.innerHTML = renderToStaticMarkup(
    cloneElement(gridElement, {
      enableVirtualization: false,
    }),
  );

  return {
    head: getRows('.rdg-header-row'),
    body: getRows('.rdg-row:not(.rdg-summary-row)'),
    foot: getRows('.rdg-summary-row'),
  };

  function getRows(selector: string) {
    return Array.from(grid.querySelectorAll<HTMLDivElement>(selector)).map((gridRow) => {
      return Array.from(gridRow.querySelectorAll<HTMLDivElement>('.rdg-cell')).map((gridCell) => gridCell.innerText);
    });
  }
}

function serialiseCellValue(value: unknown) {
  if (typeof value === 'string') {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(',') ? `"${formattedValue}"` : formattedValue;
  }
  return value;
}

function downloadFile(fileName: string, data: Blob) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}

// TODO:暂无需导出PDF，后续若需要安装jspdf和jspdf-autotable即可
// export async function exportToPdf<R, SR>(gridElement: ReactElement<DataGridProps<R, SR>>, fileName: string) {
//   const [{ jsPDF }, autoTable, { head, body, foot }] = await Promise.all([
//     import('jspdf'),
//     (await import('jspdf-autotable')).default,
//     await getGridContent(gridElement),
//   ]);
//   const doc = new jsPDF({
//     orientation: 'l',
//     unit: 'px',
//   });

//   autoTable(doc, {
//     head,
//     body,
//     foot,
//     horizontalPageBreak: true,
//     styles: { cellPadding: 1.5, fontSize: 8, cellWidth: 'wrap' },
//     tableWidth: 'wrap',
//   });
//   doc.save(fileName);
// }
