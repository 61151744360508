import { type BaseData, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import React from 'react';
import CompanyEditorModalModel from '../../../employer/companyInfo/companyEditor/model.tsx';
import AlternativeModel from '../../../employer/employerManagement/alternativeSlice/model.tsx';
import EmployerDetailModel from '../../../employer/employerManagement/employerDetail/model.tsx';
import type ParentStore from '../../model.tsx';
import type { CompanyVO, LiveTypeVO } from './interface.ts';

export default class AddModel {
  constructor(parentStore: ParentStore) {
    this.parentStore = parentStore;
    this.companyEditorModalModel = new CompanyEditorModalModel();
    this.employerDetailModel = new EmployerDetailModel(this);
    this.alternativeSliceStore = new AlternativeModel(this);
    this.__init__();
  }
  @observable public jobType = 1; //职位类型
  @observable public liveSegment = 0; //是否分段播
  @observable public hasTryPost = 0; //是否有试岗期
  @observable public hasTryUse = 0; //是否有试用期
  @observable public requireLanguage = 0; //是否有语言要求
  @observable public salaryRewardType: number; //提成
  @observable public tryUseSalaryRewardType: number; //试用期提成
  @observable public needCoordinateWork = 0; //是否需要配合做其它工作
  @observable public requireAssociatePerformance = 0; //过往业绩
  @observable public restType: 1 | 2; //休息模式
  @observable public restFixed: 0 | 1; //是否固定休
  @observable public isModified = false; //监听是否有字段修改
  @observable public salarySettleType: 1 | 2 | 3; //工资结算
  public companyEditorModalModel: CompanyEditorModalModel;
  // 雇主信息弹窗
  public employerDetailModel: EmployerDetailModel;
  // 备选切片弹窗
  public alternativeSliceStore: AlternativeModel;
  @observable public id: string; //职位id
  @observable public name: string; //职位名称
  @observable public status: number; //状态
  @observable public partTimeWorkPeriodType: 1 | 2 | 3; //长期兼职上班时段
  @observable public type: 1 | 2 | 3; //1:新增 2: 详情 3: 编辑
  @observable public dataOrigin: { [key: string]: any } = {};
  @observable public auditReason: string;
  public hrUserId: string;
  /**
   * 关闭弹窗
   */
  @action
  public onClose = () => {
    this.open = false;
    this.isModified = false;
    this.formRef.current.resetFields();
    this.jobType = 1;
    this.initValue();
    this.type = undefined;
    this.auditReason = undefined;
    this.status = undefined;
    this.id = undefined;
    this.name = undefined;
    this.hrUserId = undefined;
    this.dataOrigin = {};
    this.addressDict = [];
  };
  @action
  public initValue = () => {
    this.liveSegment = 0;
    this.hasTryPost = 0;
    this.hasTryUse = 0;
    this.requireLanguage = 0;
    this.childLiveTypeDict = [];
    this.salaryRewardType = undefined;
    this.restType = undefined;
    this.partTimeWorkPeriodType = undefined;
    this.restFixed = undefined;
    this.salarySettleType = undefined;
    this.tryUseSalaryRewardType = undefined;
    this.needCoordinateWork = 0;
    this.requireAssociatePerformance = 0;
  };
  @action
  public onOpen = (type: 1 | 2 | 3, id?: string) => {
    this.open = true;
    this.resetFields();
    this.id = id;
    this.type = type;
    if (this.id) {
      this.getPositionDetailById();
    }
  };

  // 刷新数据,提供给子组件使用
  @action
  public onRefresh = () => {
    this.getPositionDetailById();
  };

  @action
  public getPositionDetailById = async () => {
    const req = await request<BaseData<{ [key: string]: any }>>({
      method: 'POST',
      url: '/pbb/platform/sys/position/getPositionDetailById',
      data: {
        id: this.id,
      },
    });
    const {
      jobType,
      liveSegment,
      hasTryPost,
      hasTryUse,
      requireLanguage,
      salaryRewardType,
      tryUseSalaryRewardType,
      needCoordinateWork,
      requireAssociatePerformance,
      restType,
      restFixed,
      salarySettleType,
      platformList,
      status,
      companyId,
      partTimeWorkPeriodType,
      sysTagVOList,
      liveTypeParentId,
      requireAssociateExperience,
      hrUserId,
      requireLanguageType,
      auditReason,
      name,
    } = req.data;
    if (companyId) {
      this.getCompanyInfo(companyId);
    }
    if (liveTypeParentId) {
      req.data.liveTypeChildId = req.data.liveTypeId;
      this.getChildLiveTypeList(liveTypeParentId);
    }
    this.name = name;
    this.auditReason = auditReason;
    this.hrUserId = hrUserId;
    this.jobType = jobType;
    this.liveSegment = liveSegment;
    this.hasTryPost = hasTryPost;
    this.hasTryUse = hasTryUse;
    this.requireLanguage = requireLanguage;
    this.salaryRewardType = salaryRewardType;
    this.tryUseSalaryRewardType = tryUseSalaryRewardType;
    this.needCoordinateWork = needCoordinateWork;
    this.requireAssociatePerformance = requireAssociatePerformance;
    this.restType = restType;
    if (jobType === 2) {
      this.partTimeWorkPeriodType = partTimeWorkPeriodType;
    }
    this.restFixed = restFixed;
    this.salarySettleType = salarySettleType;
    this.status = status;
    this.dataOrigin = req?.data || {};
    if (requireLanguage === 1 && requireLanguageType?.length) {
      req.data.requireLanguageType = requireLanguageType.map((item) => {
        if (item.languageType === 3) {
          req.data.languageName = item.languageName;
        }
        return item.languageType;
      });
    }
    if (platformList?.length) {
      const platformList = [];
      req.data.platformList?.map((item) => {
        if (item?.account?.length) {
          item?.account.forEach((i) => {
            platformList.push({
              platformValue: item.platformValue,
              account: Object.prototype.hasOwnProperty.call(i, 'account') ? i.account : i,
              accountType: i?.accountType || '',
            });
          });
        }
      });
      req.data.platformList = platformList;
    } else {
      req.data.platformList = [{}];
    }
    if (requireAssociateExperience?.length) {
      const requireAssociateExperience = [];
      req.data?.requireAssociateExperience.forEach((item) => {
        if (item.childrenList?.length) {
          item?.childrenList.forEach((i) => {
            requireAssociateExperience.push(i.id?.toString());
          });
        }
      });
      req.data.requireAssociateExperience = requireAssociateExperience;
    }
    if (Object.keys(sysTagVOList || {}).length) {
      Object.keys(sysTagVOList).forEach((key) => {
        req.data[key] = (sysTagVOList[key] || [])?.map((item) => {
          return item.libId;
        });
      });
      req.data.sysTagVOList = undefined;
    }
    if (req?.data?.companyWelfareList?.length) {
      const insureList = [];
      const salaryList = [];
      const vacation = [];
      const subsidyList = [];
      const newCompanyWelfareList = req.data.companyWelfareList.map((item) => {
        return item.id.toString();
      });
      this.insureListDict.forEach((item) => {
        if (newCompanyWelfareList?.includes(item.value)) {
          insureList.push(item.value);
        }
      });

      this.salaryListDict.forEach((item) => {
        if (newCompanyWelfareList?.includes(item.value)) {
          salaryList.push(item.value);
        }
      });
      this.vacationListDict.forEach((item) => {
        if (newCompanyWelfareList?.includes(item.value)) {
          vacation.push(item.value);
        }
      });
      this.subsidyListDict.forEach((item) => {
        if (newCompanyWelfareList.includes(item.value)) {
          subsidyList.push(item.value);
        }
      });
      req.data.insureList = insureList;
      req.data.salaryList = salaryList;
      req.data.vacation = vacation;
      req.data.subsidyList = subsidyList;
    }
    if (req.data.workTimeStart) {
      req.data.workTimeStart = dayjs(req.data.workTimeStart, 'HH:mm');
    }
    if (req.data.workTimeEnd) {
      req.data.workTimeEnd = dayjs(req.data.workTimeEnd, 'HH:mm');
    }
    // 处理临时兼职数据回显
    if (req?.data?.livePeriod?.length && jobType === 3) {
      req.data.livePeriod = (req?.data?.livePeriod || []).map((item) => {
        return {
          dateStart: item?.dateStart ? dayjs(item?.dateStart, 'YYYY-MM-DD') : '',
          dateEnd: item?.dateStart ? dayjs(item?.dateEnd, 'YYYY-MM-DD') : '',
          liveTimeList: (item?.liveTimeList || []).map((i) => {
            let start: any = '';
            let end: any = '';
            if (i.hourStart && i.minuteStart) {
              start = dayjs(`${i.hourStart}:${i.minuteStart}`, 'HH:mm');
            }
            if (i.hourEnd && i.minuteEnd) {
              end = dayjs(`${i.hourEnd}:${i.minuteEnd}`, 'HH:mm');
            }
            return {
              start,
              end,
            };
          }),
        };
      });
    }
    // 处理长期兼职数据回显  每月
    if (req?.data?.partTimeWorkPeriod?.monthPeriod?.length && jobType === 2 && partTimeWorkPeriodType === 3) {
      req.data.monthPeriod = (req?.data?.partTimeWorkPeriod?.monthPeriod || []).map((item) => {
        return {
          dateStart: item?.dateStart ? dayjs(item?.dateStart, 'YYYY-MM-DD') : '',
          dateEnd: item?.dateStart ? dayjs(item?.dateEnd, 'YYYY-MM-DD') : '',
          liveTimeList: (item?.liveTimeList || []).map((i) => {
            let start: any = '';
            let end: any = '';
            if (i.hourStart && i.minuteStart) {
              start = dayjs(`${i.hourStart}:${i.minuteStart}`, 'HH:mm');
            }
            if (i.hourEnd && i.minuteEnd) {
              end = dayjs(`${i.hourEnd}:${i.minuteEnd}`, 'HH:mm');
            }
            return {
              start,
              end,
            };
          }),
        };
      });
    }
    //todo 后端接口还需要调整
    // 处理长期兼职数据回显 每周
    if (req?.data?.partTimeWorkPeriod?.weekPeriod?.length && jobType === 2 && partTimeWorkPeriodType === 2) {
      req.data.weekPeriod = (req?.data?.partTimeWorkPeriod?.weekPeriod || []).map((item) => {
        return {
          weekDay: item?.weekDay || [],
          weekPeriod: (item?.weekPeriod || []).map((i) => {
            let start: any = '';
            let end: any = '';
            if (i.hourStart && i.minuteStart) {
              start = dayjs(`${i.hourStart}:${i.minuteStart}`, 'HH:mm');
            }
            if (i.hourEnd && i.minuteEnd) {
              end = dayjs(`${i.hourEnd}:${i.minuteEnd}`, 'HH:mm');
            }
            return {
              start,
              end,
            };
          }),
        };
      });
      req.data.partTimeWorkPeriod = undefined;
    }
    // 处理长期兼职数据回显  每日
    if (req?.data?.partTimeWorkPeriod?.dayPeriod?.length && jobType === 2 && partTimeWorkPeriodType === 1) {
      req.data.dayPeriod = (req?.data?.partTimeWorkPeriod?.dayPeriod || []).map((i) => {
        let start: any = '';
        let end: any = '';
        if (i.hourStart && i.minuteStart) {
          start = dayjs(`${i.hourStart}:${i.minuteStart}`, 'HH:mm');
        }
        if (i.hourEnd && i.minuteEnd) {
          end = dayjs(`${i.hourEnd}:${i.minuteEnd}`, 'HH:mm');
        }
        return {
          start,
          end,
        };
      });
    }
    if (!req?.data?.coordinateWorkType) {
      req.data.coordinateWorkType = [''];
    }

    // 处理全职上班时段数据回显
    if (req?.data?.workPeriod?.length && jobType === 1) {
      // 处理旧数据兼容
      if (typeof req.data.workPeriod[0] === 'number') {
        req.data.workPeriod = [
          {
            workPeriod: '',
            workTime: [{}],
          },
        ];
      }
      req.data.workPeriod = (req?.data?.workPeriod || []).map((item) => {
        return {
          workPeriod: item?.workPeriod,
          workTime: (item?.workTime || []).map((i) => {
            let start: any = '';
            let end: any = '';
            if (i.hourStart && i.minuteStart) {
              start = dayjs(`${i.hourStart}:${i.minuteStart}`, 'HH:mm');
            }
            if (i.hourEnd && i.minuteEnd) {
              end = dayjs(`${i.hourEnd}:${i.minuteEnd}`, 'HH:mm');
            }
            return {
              start,
              end,
            };
          }),
        };
      });
    }

    this.formRef.current.setFieldsValue(req?.data || {});
  };

  /**
   * 保存职位
   */
  @action
  public savePosition = async () => {
    try {
      const params = this.formRef.current.getFieldsValue();
      if (params.hrUserId) {
        this.hrUserId = params.hrUserId;
      }
      const newParams = this.processingData(params);
      const req = await request<BaseData<string>>({
        method: 'POST',
        url: '/pbb/platform/sys/position/savePosition',
        data: { ...newParams, id: this.id, hrUserId: this.hrUserId },
      });
      this.isModified = false;
      this.onClose();
      this.parentStore.mainSubStructureModel.onQuery();
      message.success('保存成功，该职位暂未上线，可在职位管理中发布');
    } catch (e) {
      console.error(e);
    }
  };
  /**
   * 发布职位
   */
  @action
  public postJob = async () => {
    try {
      const params = await this.formRef.current.validateFields();
      if (params.hrUserId) {
        this.hrUserId = params.hrUserId;
      }
      const newParams = this.processingData(params);
      const req = await request<BaseData<string>>({
        method: 'POST',
        url: '/pbb/platform/sys/position/publishPosition',
        data: { ...newParams, id: this.id, hrUserId: this.hrUserId },
      });
      this.isModified = false;
      this.onClose();
      message.success(req?.data || '操作成功');
    } catch (e: any) {
      if (e?.errorFields?.length) {
        this.formRef.current.scrollToField(e.errorFields[0].name);
      }
    }
  };

  @action
  public resetFields = () => {
    let params: any = {};
    this.initValue();

    switch (this.jobType) {
      case 1: {
        params = {
          jobType: 1,
          requireSex: 0,
          isKeepOffice: 0,
          acceptNewbie: 1,
          liveSegment: 0,
          salaryResponsible: 0,
          salaryAdjustment: 0,
          salaryRewardType: 0,
          salaryRewardLadder: 0,
          bonusType: 0,
          salarySettleType: 2,
          hasSocialSecurity: 1,
          hasMedicalSecurity: 1,
          hasReserveSecurity: 1,
          hasTryPost: 0,
          tryPostHasSalary: 0,
          hasTeaching: 0,
          hasTryUse: 0,
          tryUseSalaryDiscount: 0,
          tryUseSalaryRewardType: 0,
          requireAge: 0,
          requireHeight: 0,
          requireWeight: 0,
          requireEducation: 0,
          requireLanguage: 0,
          requireMajor: 0,
          groupType: 1,
          hasOperation: 1,
          needCoordinateWork: 0,
          hasEveningWork: 0,
          prepareType: 1,
          holidayRestType: 1,
          liveShape: 0,
          showFace: 1,
          requireAssociatePerformance: 0,
        };
        this.salarySettleType = 2;

        break;
      }
      case 2: {
        params = {
          jobType: 2,
          requireSex: 0,
          isKeepOffice: 0,
          acceptNewbie: 1,
          liveSegment: 0,

          salaryRewardType: 0,
          salaryRewardLadder: 0,
          salarySettleType: 1,

          requireAge: 0,
          requireHeight: 0,
          requireWeight: 0,
          requireEducation: 0,
          requireLanguage: 0,
          requireMajor: 0,
          groupType: 1,
          hasOperation: 1,
          needCoordinateWork: 0,
          hasEveningWork: 0,
          prepareType: 1,
          holidayRestType: 1,
          liveShape: 0,
          showFace: 1,
          requireAssociatePerformance: 0,
        };
        break;
      }
      case 3: {
        params = {
          jobType: 3,
          requireSex: 0,
          isKeepOffice: 0,
          acceptNewbie: 1,
          liveSegment: 0,

          salaryRewardType: 0,
          salaryRewardLadder: 0,
          salarySettleType: 1,

          requireAge: 0,
          requireHeight: 0,
          requireWeight: 0,
          requireEducation: 0,
          requireLanguage: 0,
          requireMajor: 0,
          groupType: 1,
          hasOperation: 1,
          needCoordinateWork: 0,
          hasEveningWork: 0,
          prepareType: 1,
          holidayRestType: 1,
          liveShape: 0,
          showFace: 1,
          requireAssociatePerformance: 0,
        };
        break;
      }
    }
    this.formRef.current.setFieldsValue(params);
  };
  /**
   * --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
   */
  public parentStore: ParentStore;
  @observable public open = false;
  @observable public addressDict: Array<{ label: string; value: string | number }> = []; //公司地址集合
  @observable public platformTypeDict: Array<{ label: string; value: string | number }> = []; //平台集合

  @action
  public setValue = (key: string, value: any) => {
    this[key] = value;
    if (key === 'jobType') {
      this.formRef.current.resetFields();
      this.resetFields();
    }
  };
  public formRef = React.createRef<FormInstance>();
  @action
  public __init__ = () => {
    this.getParentLiveTypeList();
    this.getDictPlatformType();
    this.getDictWelfare();
    this.getDictLiveType();
  };

  /**
   * 根据雇主查询公司信息
   * @param value
   */
  @action
  public getCompanyInfo = async (value: string) => {
    const req = await request<BaseData<Partial<CompanyVO>>>({
      method: 'POST',
      url: '/pbb/platform/company/getInfo',
      data: {
        id: value,
      },
    });
    const addressDict = (req?.data?.addressVOList || []).map((item) => {
      return {
        label: item.info,
        value: item.id,
      };
    });
    this.addressDict = addressDict;
    let params: any = {
      companyName: req?.data?.name,
      companyId: value,
    };
    if (!this.id) {
      params = {
        ...params,
        companyAddressId: req.data.companyAddressId,
      };
    }
    this.formRef.current.setFieldsValue(params);
  };
  @observable public parentLiveTypeDict: Array<{ label: string; value: string | number }> = []; //一级类目
  /**
   * 获取所有一级类目
   */
  @action
  public getParentLiveTypeList = async () => {
    const req = await request<BaseData<LiveTypeVO[]>>({
      method: 'POST',
      url: '/pbb/platform/anon/system/liveType/getParentLiveTypeList',
    });
    this.parentLiveTypeDict = (req?.data || []).map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };
  @observable public childLiveTypeDict: Array<{ label: string; value: string | number }> = []; //二级类目
  /**
   * 获取所有二级类目
   */
  @action
  public getChildLiveTypeList = async (id: string) => {
    const req = await request<BaseData<LiveTypeVO[]>>({
      method: 'POST',
      url: '/pbb/platform/anon/system/liveType/getChildLiveTypeList',
      data: { id },
    });
    this.childLiveTypeDict = (req?.data || []).map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };
  /**
   * 获取平台类型
   */
  @action
  public getDictPlatformType = async () => {
    const req = await request<BaseData<any>>({
      method: 'POST',
      url: '/pbb/platform/anon/system/sysDict/getListByType',
      data: {
        dictType: 'platform_type',
      },
    });
    this.platformTypeDict = (req?.data || []).map((item: any) => {
      return {
        label: item.dictName,
        value: item.dictValue,
      };
    });
  };
  @observable public liveTypeDict = [];
  /**
   * 获取类目
   */
  @action
  public getDictLiveType = async () => {
    const req = await request<BaseData<any>>({
      method: 'POST',
      url: '/pbb/platform/anon/system/liveType/list',
      data: {},
    });
    this.liveTypeDict = req.data;
  };
  @observable public insureListDict: Array<{ label: string; value: string | number }> = []; //保险字典
  @observable public salaryListDict: Array<{ label: string; value: string | number }> = []; //薪资字典
  @observable public subsidyListDict: Array<{ label: string; value: string | number }> = []; //补贴字典
  @observable public vacationListDict: Array<{ label: string; value: string | number }> = []; //假期字典

  /**
   * 获取福利待遇
   */
  @action
  public getDictWelfare = async () => {
    const req = await request<
      BaseData<{ insureList: any[]; salaryList: any[]; subsidyList: any[]; vacationList: any[] }>
    >({
      method: 'POST',
      url: '/pbb/platform/system/welfare/list',
      data: {},
    });
    if (req?.data?.insureList) {
      this.insureListDict = (req?.data?.insureList || [])?.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    }
    if (req?.data?.salaryList) {
      this.salaryListDict = (req?.data?.salaryList || [])?.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    }
    if (req?.data?.subsidyList) {
      this.subsidyListDict = (req?.data?.subsidyList || [])?.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    }
    if (req?.data?.vacationList) {
      this.vacationListDict = (req?.data?.vacationList || [])?.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    }
  };

  @action
  public processingData = (params: { [key: string]: any }): { [key: string]: any } => {
    params = JSON.parse(JSON.stringify(params));
    console.log(params, 'ppppppp');

    if (params.requireLanguage === 1 && params?.requireLanguageType?.length) {
      params.requireLanguageType = params.requireLanguageType.map((value) => {
        return {
          languageName: value === 3 ? params.languageName : value === 1 ? '普通话' : value === 2 ? '英语' : '',
          languageType: value,
        };
      });
      params.languageName = undefined;
    }
    if (params?.platformList?.length) {
      const platformList = {};
      params.platformList?.map((item) => {
        if (item?.platformValue?.toString()) {
          if (platformList[item.platformValue]) {
            platformList[item.platformValue] = [
              ...platformList[item.platformValue],
              { account: item.account, accountType: item.accountType },
            ];
          } else {
            platformList[item.platformValue] = [{ account: item.account, accountType: item.accountType }];
          }
        }
      });
      params.platformList = Object.keys(platformList).map((key) => {
        return {
          platformValue: key,
          account: platformList[key],
        };
      });
    }
    if (params?.requireAssociateExperience?.length) {
      const requireAssociateExperience = [];
      JSON.parse(JSON.stringify(this.liveTypeDict)).forEach((item) => {
        const childrenList = item.childrenList.filter((_item) => {
          if (params?.requireAssociateExperience?.includes(_item.id)) {
            return item;
          }
        });
        if (childrenList.length) {
          requireAssociateExperience.push({
            id: item.id,
            name: item.name,
            childrenList: childrenList,
          });
        }
      });
      params.requireAssociateExperience = requireAssociateExperience;
    }
    const companyWelfareList = [];
    if (params?.insureList?.length) {
      this.insureListDict.forEach((item) => {
        if (params.insureList.includes(item.value)) {
          companyWelfareList.push({
            id: item.value,
            name: item.label,
          });
        }
      });
      params.insureList = undefined;
    }
    if (params?.salaryList?.length) {
      this.salaryListDict.forEach((item) => {
        if (params.salaryList.includes(item.value)) {
          companyWelfareList.push({
            id: item.value,
            name: item.label,
          });
        }
      });
      params.salaryList = undefined;
    }
    if (params?.vacation?.length) {
      this.vacationListDict.forEach((item) => {
        if (params.vacation.includes(item.value)) {
          companyWelfareList.push({
            id: item.value,
            name: item.label,
          });
        }
      });
      params.vacation = undefined;
    }
    if (params?.subsidyList?.length) {
      this.subsidyListDict.forEach((item) => {
        if (params.subsidyList.includes(item.value)) {
          companyWelfareList.push({
            id: item.value,
            name: item.label,
          });
        }
      });
      params.subsidyList = undefined;
    }
    const tagVOList = [];
    if (this.parentStore?.categoryIdList?.length && this.id) {
      this.parentStore?.categoryIdList.forEach((id) => {
        if (params[id]) {
          params[id].forEach((item) => {
            tagVOList.push({
              libId: item,
            });
          });
          params[id] = undefined;
        }
      });
    }
    params.companyWelfareList = companyWelfareList?.length ? companyWelfareList : undefined;
    params.tagVOList = Object.keys(tagVOList).length ? tagVOList : [];
    if (params.workTimeStart) {
      params.workTimeStart = dayjs(params.workTimeStart).format('HH:mm');
    }
    if (params.workTimeEnd) {
      params.workTimeEnd = dayjs(params.workTimeEnd).format('HH:mm');
    }
    if (params.jobType === 3 && params?.livePeriod?.length) {
      params.livePeriod = processingTime(params.livePeriod);
    }
    if (params.jobType === 2 && params?.partTimeWorkPeriodType === 3 && params?.monthPeriod?.length) {
      const partTimeWorkPeriod: any = {};
      partTimeWorkPeriod.monthPeriod = processingTime(params.monthPeriod);
      params.partTimeWorkPeriod = partTimeWorkPeriod;
      params.monthPeriod = undefined;
    }
    if (params.jobType === 2 && params?.partTimeWorkPeriodType === 1 && params?.dayPeriod?.length) {
      const partTimeWorkPeriod: any = {};
      partTimeWorkPeriod.dayPeriod = params.dayPeriod.map((i) => {
        const newLivePeriod = {
          hourStart: '',
          hourEnd: '',
          minuteEnd: '',
          minuteStart: '',
        };
        if (i.start) {
          const [hourStart, minuteStart] = dayjs(i.start).format('HH:mm').split(':');
          newLivePeriod.hourStart = hourStart;
          newLivePeriod.minuteStart = minuteStart;
        }
        if (i.end) {
          const [hourEnd, minuteEnd] = dayjs(i.end).format('HH:mm').split(':');
          newLivePeriod.hourEnd = hourEnd;
          newLivePeriod.minuteEnd = minuteEnd;
        }
        return newLivePeriod;
      });
      params.partTimeWorkPeriod = partTimeWorkPeriod;
      params.dayPeriod = undefined;
    }
    if (params.jobType === 2 && params?.partTimeWorkPeriodType === 2 && params?.weekPeriod?.length) {
      const partTimeWorkPeriod: any = {};
      partTimeWorkPeriod.weekPeriod = params?.weekPeriod?.map((item) => {
        let weekPeriod = [];
        if (item.weekPeriod?.length) {
          weekPeriod = item.weekPeriod?.map((i) => {
            const newLivePeriod = {
              hourStart: '',
              hourEnd: '',
              minuteEnd: '',
              minuteStart: '',
            };
            if (i.start) {
              const [hourStart, minuteStart] = dayjs(i.start).format('HH:mm').split(':');
              newLivePeriod.hourStart = hourStart;
              newLivePeriod.minuteStart = minuteStart;
            }
            if (i.end) {
              const [hourEnd, minuteEnd] = dayjs(i.end).format('HH:mm').split(':');
              newLivePeriod.hourEnd = hourEnd;
              newLivePeriod.minuteEnd = minuteEnd;
            }
            return newLivePeriod;
          });
        }
        return {
          weekDay: item.weekDay,
          weekPeriod: weekPeriod,
        };
      });
      params.partTimeWorkPeriod = partTimeWorkPeriod;
      params.weekPeriod = undefined;
    }
    // 处理全职上班时段
    if (params.jobType === 1 && params?.workPeriod?.length) {
      const newWeekPeriod: any = {};
      newWeekPeriod.workPeriod = params?.workPeriod?.map((item) => {
        let workTime = [];
        if (item.workTime?.length) {
          workTime = item.workTime?.map((i) => {
            const newLivePeriod = {
              hourStart: '',
              hourEnd: '',
              minuteEnd: '',
              minuteStart: '',
            };
            if (i.start) {
              const [hourStart, minuteStart] = dayjs(i.start).format('HH:mm').split(':');
              newLivePeriod.hourStart = hourStart;
              newLivePeriod.minuteStart = minuteStart;
            }
            if (i.end) {
              const [hourEnd, minuteEnd] = dayjs(i.end).format('HH:mm').split(':');
              newLivePeriod.hourEnd = hourEnd;
              newLivePeriod.minuteEnd = minuteEnd;
            }
            return newLivePeriod;
          });
        }
        return {
          workTime: workTime,
          workPeriod: item.workPeriod,
        };
      });
      params.workPeriod = newWeekPeriod.workPeriod;
    }
    return params;
  };
}
const processingTime = (data: any[]): any => {
  return data.map((item) => {
    let dateEnd = '';
    let dateStart = '';
    if (item.dateStart) {
      dateStart = dayjs(item.dateStart).format('YYYY-MM-DD');
    }
    if (item.dateEnd) {
      dateEnd = dayjs(item.dateEnd).format('YYYY-MM-DD');
    }
    let liveTimeList = [];
    if (item.liveTimeList?.length) {
      liveTimeList = item.liveTimeList?.map((i) => {
        const newLivePeriod = {
          hourStart: '',
          hourEnd: '',
          minuteEnd: '',
          minuteStart: '',
        };
        if (i.start) {
          const [hourStart, minuteStart] = dayjs(i.start).format('HH:mm').split(':');
          newLivePeriod.hourStart = hourStart;
          newLivePeriod.minuteStart = minuteStart;
        }
        if (i.end) {
          const [hourEnd, minuteEnd] = dayjs(i.end).format('HH:mm').split(':');
          newLivePeriod.hourEnd = hourEnd;
          newLivePeriod.minuteEnd = minuteEnd;
        }
        return newLivePeriod;
      });
    }
    return {
      dateStart: dateStart,
      dateEnd: dateEnd,
      liveTimeList: liveTimeList,
    };
  });
};
