import { type BaseData, request, transformKeys } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';
import { API } from '../api';

export default class EditUserModel {
  @observable public visible = false;
  @observable public ref: FormInstance;
  @observable public parent = null;
  @observable public userList = [];
  @observable public usage = [];
  @observable public userId: number;
  @observable public mobile: string;
  @observable public userName: string;
  @observable public type: string;
  @observable public id: number;
  constructor({ parent }) {
    this.parent = parent;
  }

  @action public setRef = (ref) => {
    this.ref = ref;
  };

  @action public onShow = (type?: string, data?: any) => {
    this.type = type;
    this.visible = true;
    this.whitelistList();
    if (type === 'edit') {
      if (data) {
        const { userId, mobile, userNickName, userName, whitelistUseList, id } = data;
        this.userId = userId;
        this.mobile = mobile;
        this.userName = userNickName || userName;
        const transformedWhitelistUseIdList = transformKeys(whitelistUseList, { useName: 'label', id: 'value' });
        this.id = id;
        setTimeout(() => {
          this.ref.setFieldsValue({
            ...data,
            whitelistUseIdList: transformedWhitelistUseIdList,
          });
        }, 0);
      }
    } else {
      this.ref.resetFields();
    }
  };

  @action public whitelistList = async () => {
    return request<BaseData<any>>({
      method: 'post',
      url: API.whitelistList,
      data: {},
    }).then((resp) => {
      this.usage = transformKeys(resp?.data, { useName: 'label', id: 'value' });
    });
  };

  @action public onClose = () => {
    this.visible = false;
  };

  @action public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    const values = await this.ref.getFieldsValue();
    let postParams = { ...values };
    postParams.whitelistUseIdList = postParams.whitelistUseIdList.map((item) => {
      return item.value;
    });
    if (this.type === 'edit') {
      postParams = {
        ...postParams,
        id: this.id,
      };
    }
    const url = this.type === 'edit' ? API.editUser : API.addUser;

    return request<BaseData<any>>({
      method: 'post',
      url: url,
      data: {
        ...postParams,
      },
    }).then(() => {
      message.success('操作成功');
      this.ref.resetFields();
      this.onClose();
      this.parent.whiteListStore.onQuery();
    });
  };
}
