import { type BaseData, request } from '@/utils';
import { Input, Modal, message } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import type { ModalProps } from './interface';

const RefuseReason = observer(({ visible, onCancel, item, cb }: ModalProps) => {
  const [value, setValue] = useState<string>();
  const [reasonList, setReasonList] = useState<{ dictName: string; dictValue: string; checked: boolean }[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const getList = async () => {
    try {
      const res = await request<BaseData<{ dictName: string; dictValue: string }[]>>({
        url: '/pbb/platform/anon/system/sysDict/getListByType',
        method: 'POST',
        data: { dictType: 'employ_refuse_reason' },
      });
      const list = res.data.map((el) => {
        return { ...el, checked: false };
      });
      setReasonList([
        ...list.filter((item) => item.dictValue != '99'),
        {
          dictName: '其他',
          dictValue: '99',
          checked: false,
        },
      ]);
    } finally {
    }
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!visible) {
      return;
    }
    getList();
  }, [visible]);

  const onChange = (id: string) => {
    // 当前是否选中了其他
    const isOther = reasonList.find((el) => el.dictValue === '99')?.checked;
    // 选择其他原因
    if (id === '99') {
      const list = reasonList.map((el) => {
        if (el.dictValue === id) {
          el.checked = !el.checked;
        } else {
          el.checked = false;
          setValue('');
        }
        return el;
      });
      setShowInput(!isOther);
      setReasonList(list);
    } else {
      const list = reasonList.map((el) => {
        if (el.dictValue === id) {
          el.checked = !el.checked;
        }
        if (el.dictValue === '99') {
          el.checked = false;
          setShowInput(false);
        }
        return el;
      });
      setReasonList(list);
    }
  };

  const onChangeInput = async (text: string) => {
    setValue(text);
  };
  const onSave = async () => {
    let reason = '';
    if (value) {
      reason = value;
    } else {
      reasonList.forEach((el) => {
        if (el.checked) {
          reason += ` ${el.dictName}`;
        }
      });
    }
    console.log(reason);
    if (!reason) {
      message.info('请选择不合适原因');
      return;
    }
    try {
      setLoading(true);
      const res = await request({
        url: '/pbb/platform/sys/recruit/rejectSignUp',
        method: 'POST',
        data: {
          recruitId: item.recruitId,
          reason: reason,
        },
      });
      cb();
      onCancel();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      title="拒绝邀约"
      width={500}
      centered={true}
      onOk={onSave}
      confirmLoading={loading}
      maskClosable={false}
    >
      <div className="text-14 font-500">
        <span className="text-[red]">*</span>不合适原因
      </div>
      <div className="flex px-5 flex-wrap">
        {reasonList.map((el) => {
          return (
            <div
              key={el.dictValue}
              className={'text-heading text-14 px-4 py-3 mt-3 mr-3 relative cursor-pointer'}
              style={{ background: el.checked ? '#E7F4FD' : '#F8FAFB' }}
              onClick={() => onChange(el.dictValue)}
            >
              {el.dictName}
            </div>
          );
        })}
      </div>
      {showInput && (
        <div className="mt-3 px-5">
          <div className="relative h-33 w-full">
            <Input.TextArea
              showCount={true}
              className="rounded-1 w-full px-3 !h-30 !bg-[#F7F7F7]"
              placeholder="请输入其他原因"
              value={value}
              maxLength={200}
              onChange={(e) => onChangeInput(e.target.value)}
            />
          </div>
        </div>
      )}
    </Modal>
  );
});

export default RefuseReason;
