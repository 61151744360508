import { type BaseData, EpUpload, request } from '@/utils';
import compressImgOrVideo from '@/utils/compress';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { InputRef, SelectProps } from 'antd';
import { Form, Image, Input, Modal, Select, Switch, Tag, TreeSelect, Upload, message } from 'antd';
import type { RcFile } from 'antd/es/upload';
import { observer } from 'mobx-react';
import React, { type ChangeEvent, useRef, useState } from 'react';
import type Model from './editorModel';

// 新增或编辑需求配置信息
export default observer(({ store }: { store: Model }) => (
  <Modal
    centered={true}
    open={store.visible}
    onOk={() => {
      store.ref?.submit();
    }}
    maskClosable={false}
    onCancel={store.onCancel}
    confirmLoading={store.saveLoading}
    title={store.type === 'add' ? '新增招聘管家' : '编辑招聘管家'}
  >
    <Form
      ref={store.setRef}
      onFinish={store.onFinish}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      initialValues={{
        canWork: store.type === 'add' ? 1 : store.ref,
        accountStatus: store.type === 'add' ? 1 : store.ref,
      }}
    >
      <Form.Item
        name="cardName"
        label={store.type === 'add' ? '选择招聘管家' : '招聘管家信息'}
        rules={[
          {
            required: true,
            message: '请选择用户',
          },
        ]}
      >
        {store.type === 'edit' && (
          <div style={{ height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '12px', lineHeight: '20px' }}>用户昵称：{store.cardName}</div>
            <div style={{ fontSize: '12px', lineHeight: '20px' }}>ID：{store.id}</div>
            <div style={{ fontSize: '12px', lineHeight: '20px' }}>手机号：{store.mobile}</div>
          </div>
        )}
        {store.type === 'add' && (
          <SearchInput
            placeholder={'输入用户昵称/手机号/ID搜索选择'}
            style={undefined}
            onChange={(value) => {
              store.changePlatform(value.roleList);
            }}
            className={'!w-100'}
          />
        )}
      </Form.Item>
      <Form.Item
        label="承接流量"
        name="canWork"
        rules={[
          {
            required: true,
          },
        ]}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <ImageUpload
        name="mainUrl"
        label="形象照"
        message="请上传形象照"
        size={6}
      />
      <Form.Item
        name="cityList"
        label="服务城市"
        rules={[
          {
            required: true,
            message: '请选择',
          },
        ]}
      >
        <TreeSelect
          filterTreeNode={(inputValue: string, treeNode: any) => {
            return treeNode.label?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1;
          }}
          allowClear={true}
          treeData={store.cityOption}
          maxTagCount={10}
          placeholder="请选择"
          multiple={true}
          treeCheckable={true}
          showSearch={true}
          onChange={(ids: string[]) => {
            store.changeCityIds(ids);
          }}
        />
      </Form.Item>
      <KeyWordTags
        name="keyWord"
        label="关键词"
        message="请输入关键词"
      />
      <Form.Item
        name="briefIntroduction"
        label="简介"
        rules={[
          {
            required: true,
            message: '请输入简介',
          },
        ]}
      >
        <Input.TextArea
          maxLength={200}
          showCount={true}
        />
      </Form.Item>
      <ImageUpload
        name="wechatCode"
        label="企微二维码"
        message="请上传企微二维码"
        size={2}
      />
      <Form.Item
        label="状态"
        name="accountStatus"
        rules={[
          {
            required: true,
          },
        ]}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </Form>
  </Modal>
));

const ImageUpload = (props: {
  readonly name: string;
  readonly label: string;
  readonly message: string;
  readonly size: number;
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Form.Item
      noStyle={true}
      shouldUpdate={(prevValues, nextValues) => {
        return prevValues[props.name] !== nextValues[props.name];
      }}
    >
      {({ getFieldValue, setFieldValue }) => {
        const imageUrl = getFieldValue(props.name);
        const customRequest = async (options: any) => {
          console.log(options.file, 'options.file');

          setLoading(true);
          const unit = options.file.name.split(',');
          const file: any = await compressImgOrVideo(options.file);
          if (file === false) {
            return;
          }
          const url = await EpUpload.singleUpload({
            attachmentType: 1,
            module: 'opus',
            file: file,
          });
          setFieldValue(props.name, url);
          setLoading(false);
        };

        const beforeUpload = (file: RcFile) => {
          if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            message.error('图片格式只能为jpg、jpeg、png');
            return false;
          }
          // 限制文件大小，例如限制为 2MB
          const isLtSzie = file.size / 1024 / 1024 < (props.size || 6);
          if (!isLtSzie) {
            message.error(`图片需要小于${props.size}MB!`);
            return false;
          }
          return true;
        };

        return (
          <Form.Item
            label={props.label}
            name={props.name}
            getValueFromEvent={(e) => imageUrl}
            validateTrigger={false}
            required={true}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              accept=".jpg,.jpeg,.png,.webp"
            >
              {imageUrl ? (
                <Image
                  src={imageUrl}
                  preview={false}
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <button
                  style={{
                    border: 0,
                    background: 'none',
                  }}
                  type="button"
                >
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div className="mt-2">上传</div>
                </button>
              )}
            </Upload>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

const KeyWordTags = (props: { readonly name: string; readonly label: string; readonly message?: string }) => {
  const inputRef = useRef<InputRef>(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  return (
    <Form.Item
      noStyle={true}
      shouldUpdate={(prevValues, nextValues) => {
        return prevValues[props.name] !== nextValues[props.name];
      }}
    >
      {({ getFieldValue, setFieldValue }) => {
        // getValueFromEvent 要返回原始数据 这里返回的是 tags
        const tags: string[] = getFieldValue(props.name);
        const tagsArr: string[] = tags || [];
        const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
          setInputValue(e.target.value);
        };

        const handleInputConfirm = () => {
          if (inputValue && !tagsArr.includes(inputValue)) {
            setFieldValue(props.name, [...tagsArr, inputValue]);
          }
          setInputVisible(false);
          setInputValue('');
        };

        const showInput = () => {
          if (tagsArr.length < 2) {
            setInputVisible(true);
          } else {
            message.error('最多只能添加2个标签');
          }
        };
        const handleClose = (e: React.MouseEvent<HTMLElement>, removedTag: string) => {
          e.preventDefault();
          setFieldValue(
            props.name,
            tagsArr.filter((tag) => tag !== removedTag),
          );
        };

        return (
          <Form.Item
            label={props.label}
            name={props.name}
            getValueFromEvent={() => tags}
            rules={[
              {
                required: true,
                message: props.message,
                validateTrigger: 'blur',
                validator: () => {
                  return new Promise((resolve, reject) => {
                    console.log('validator', tagsArr.length);
                    if (tagsArr.length > 0) {
                      resolve('');
                    } else {
                      reject('请输入2个标签');
                    }
                  });
                },
              },
            ]}
          >
            <div className="flex items-center ">
              {tagsArr?.map((item, index) => (
                <Tag
                  key={`${index.toString()}`}
                  closable={true}
                  style={{ userSelect: 'none' }}
                  onClose={(e) => handleClose(e, item)}
                >
                  <span>{item}</span>
                </Tag>
              ))}

              {inputVisible ? (
                <Input
                  className="w-24"
                  ref={inputRef}
                  type="text"
                  size="small"
                  value={inputValue}
                  maxLength={6}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              ) : (
                <Tag
                  icon={<PlusOutlined />}
                  onClick={showInput}
                >
                  自定义标签
                </Tag>
              )}
            </div>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

const SearchInput: React.FC<{
  placeholder: string;
  style: React.CSSProperties;
  className: string;
  callback?: (value: string) => void;
  [key: string]: any;
}> = React.forwardRef((props, ref) => {
  const [data, setData] = useState<SelectProps['options']>([]);

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };
  return (
    <Select
      // @ts-ignore
      ref={ref}
      labelInValue={true}
      className={props.className}
      showSearch={true}
      value={props.value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={(value, option: any) => {
        props.onChange(option);
        // props?.callback(option);
      }}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        label: d.label,
        value: d.value,
        roleList: d.roleList,
      }))}
    />
  );
});

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
const fetch = (value: string, callback: (data: { label: any; value: any }[] | undefined) => void) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = async () => {
    const req = await request<BaseData<any>>({
      method: 'POST',
      url: '/pbb/platform/sys/user/list/page',
      data: {
        keyWord: value,
      },
    });

    if (currentValue === value) {
      callback(
        (req?.data?.list || [])?.map((item) => {
          return {
            label: item.nickName,
            value: item.id,
            roleList: item.platformRoleVOList,
          };
        }),
      );
    }
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};
