import { type BaseData, request, requestSingleton } from '@/utils/request.ts';
import { message } from 'antd';

/**
 * 包含字母和数字且长度在8到16位的密码：
 */
export const passwordReg = /^(?=.*\d+)(?=.*[a-zA-Z]+)[0-9a-zA-Z~!@#$^*_]{8,18}$/;

/**
 * 大陆手机号码11位数，匹配格式：前三位固定格式+后8位任意数
 * 此方法中前三位格式有：
 * 13+任意数
 * 145,147,149
 * 15+除4的任意数(不要写^4，这样的话字母也会被认为是正确的)
 * 166
 * 17+3,5,6,7,8
 * 18+任意数
 * 198,199
 *
 * 香港手机号码8位数，5|6|8|9开头+7位任意数
 * 前端手机号格式参考了后端
 */
export const phoneReg =
  /^((13[0-9])|(14[014-9])|(15[0-35-9])|(16[2567])|(17[0-8])|(18[0-9])|(19[0-35-9]))\d{8}$|^[5689]\d{7}$/;

/**
 * 格式: name@domin
 * 以大写字母[A-Z]、小写字母[a-z]、数字[0-9]、下滑线[_]、减号[-]及点号[.]开头，并需要重复一次至多次[+]。
 * 中间必须包括@符号。
 * @之后需要连接大写字母[A-Z]、小写字母[a-z]、数字[0-9]、下滑线[_]、减号[-]及点号[.]，并需要重复一次至多次[+]。
 * 必须是点号[.]连接2至6位的大小写字母[A-Za-z]{2,6}。
 */
export const emailReg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;

export const priceReg = /^\d+\.?\d{0,2}$/;

export const requireSource = (src: string): string => {
  if (!src) {
    throw new Error('name can not be null');
  }
  return `${process.env.CDN}pc-admin${src}`;
};

export interface IGetWxUrlProps {
  /**
   * 后台管理系统用户ID
   */
  adminId?: number;
  /**
   * 主播ID
   */
  anchorId?: number;
  /**
   * 渠道 主播端（1推荐职位 2上传简历 3订阅服务 4陪跑服务） 雇主端（5主播切片页 6主播主页 7发布职位 8服务支付页 10雇主端主页） 99 搜索,11交流群
   */
  channel?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10 | 99 | 11;
  /**
   * 1运营平台 2小程序
   */
  environment?: 1 | 2;
  /**
   * 默认值release。要打开的小程序版本。正式版为 release，体验版为trial，开发版为develop，仅在微信外打开时生效
   */
  envVersion?: 'release' | 'trial' | 'develop';
  /**
   * 切片或形象照ID
   */
  opusId?: number;
  /**
   * 小程序链接需携带的parentUserId,推荐人
   */
  parentUserId?: number;
  /**
   * 职位ID
   */
  positionId?: any;
  /**
   * 雇主ID
   */
  employerId?: any;

  /**
   * query 自定义参数 xx=xx
   */
  query?: string;
  [property: string]: any;
}

export const getWxUrl = async (params: IGetWxUrlProps): Promise<string> => {
  try {
    const envVersion = window.location.href.includes('test.')
      ? 'trial'
      : window.location.href.includes('pre.')
        ? 'trial'
        : 'release';
    const res = await request<BaseData<string>>({
      url: '/pbb/platform/anon/getUrlLink',
      method: 'POST',
      data: {
        ...params,
        environment: 1, // 1运营平台 2小程序
        envVersion,
      },
    });
    return res.data;
  } catch (error) {
    return '';
  }
};

export const copyToClipboard = async (text: string) => {
  // 尝试使用新的 Clipboard API
  if (navigator.clipboard && window.isSecureContext) {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text using Clipboard API: ', err);
    }
    return;
  }

  // 回退到使用 document.execCommand 方法
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, textarea.value.length);

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      console.log('Text copied to clipboard');
    } else {
      throw new Error('Failed to copy text using execCommand');
    }
  } catch (err) {
    console.error('Failed to copy text using execCommand: ', err);
  } finally {
    document.body.removeChild(textarea);
  }
};

export const getPageId = () => {
  return new URLSearchParams(window.location.search)?.get('pageId');
};

// 导出
export const exportXlsx = (() => {
  let loading = false;
  return async (url: string, params: { [key: string]: any }, fileName?: string) => {
    if (loading) {
      return;
    }
    loading = true;
    message.loading({ key: 'loading', content: '导出中', duration: 0 });
    try {
      const res = await requestSingleton.getInstance().request({
        url: url,
        method: 'POST',
        data: params,
        responseType: 'blob',
      });
      const downloadLink = document.createElement('a');
      downloadLink.download = fileName || '导出';
      const dataUrl = URL.createObjectURL(
        new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      );
      downloadLink.href = dataUrl;
      downloadLink.click();
      URL.revokeObjectURL(dataUrl);
      message.success('导出成功');
    } catch (error) {
      console.error(error);
    } finally {
      loading = false;
      message.destroy('loading');
    }
  };
})();
