import { Button, Form, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import ServiceModel from './model';
const ServiceManagement = () => {
  const store = useMemo(() => new ServiceModel(), []);

  const { ref, setRef, saveLoading } = store;

  return (
    <div className="pl-20 pt-10">
      <Form
        ref={setRef}
        labelCol={{ className: 'font-800' }}
        disabled={true}
      >
        <div className="font-800 text-16">雇主服务</div>
        <div className="ml-11 mt-5">
          <div className="flex">
            <Form.Item
              label="到面服务单价"
              name="butler_to_face_amount"
            >
              <InputNumber
                placeholder="请输入"
                className="w-50"
                min={0}
                step={1}
                precision={2}
              />
            </Form.Item>
            <span className="ml-2 mt-1">元</span>
          </div>

          <div className="flex">
            <div className="flex">
              <Form.Item
                label="邀约服务单价"
                name="invite_service_amount"
              >
                <InputNumber
                  className="w-50"
                  placeholder="请输入"
                  min={0}
                  step={1}
                  precision={2}
                />
              </Form.Item>
              <span className="ml-2 mt-1">元</span>
            </div>
            <div className="flex ml-5">
              <Form.Item
                label="数量"
                name="invite_service_number"
              >
                <InputNumber
                  placeholder="请输入"
                  min={0}
                  step={1}
                  precision={0}
                />
              </Form.Item>
              <span className="ml-2 mt-1">次</span>
            </div>
          </div>
        </div>
        <div className="font-800 text-16">主播服务</div>
        <div className="flex ml-11 mt-5">
          <Form.Item
            label="进全国主播交流群单价"
            name="social_group_amount"
          >
            <InputNumber
              className="w-50"
              placeholder="请输入"
              step={1}
              precision={2}
            />
          </Form.Item>
          <span className="ml-2 mt-1">元</span>
        </div>
      </Form>
    </div>
  );
};

export default observer(ServiceManagement);
