import { observer } from 'mobx-react';
import React, { Component, useMemo, useState } from 'react';
import { SearchListStructure } from '../../../utils/searchListStructure';
import { ChangeHousekeeper } from './changebutleModal';

import { FeedBack } from './feedBackModal';
import Model from './model';
const JDLibrary = () => {
  const store = useMemo(() => new Model(), []);
  const { mainStore, feedBackStore, changeHousekeeperStore } = store;

  return (
    <div>
      <SearchListStructure store={mainStore} />
      <FeedBack store={feedBackStore} />
      <ChangeHousekeeper store={changeHousekeeperStore} />
    </div>
  );
};

export default observer(JDLibrary);
