import { VITA_AUDIT_STATUS } from '@/pages/anchorDetail/constants';
import type { DictItem } from '@/utils';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Radio, Select, message } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import type React from 'react';

interface AuthModalProps {
  authModalStore: AuthModalStore;
  vitaAuditReasonList: DictItem[];
}

interface callbackParams {
  status: number;
  auditReason: string;
}

interface Options {
  callback?: (params: callbackParams, ...args: any[]) => Promise<any>;
  title?: string;
  header?: React.ReactNode;
}

const otherReasonId = '99';

export class AuthModalStore {
  constructor(options?: Options) {
    this.options = options || {};
  }

  @observable public status = VITA_AUDIT_STATUS.pass.value;
  @action public setStatus = (status: number) => {
    this.status = status;
  };

  @observable public auditReasonSelect = null;
  @action setAuditReasonSelect = (auditReasonSelect: string, auditReason: string) => {
    this.auditReasonSelect = auditReasonSelect;
    this.setAuditReason(auditReason);
  };
  @observable public auditReason = '';
  @action setAuditReason = (auditReason: string) => {
    this.auditReason = auditReason;
  };

  @observable public options: Options = {};
  @observable public open = false;
  @action public handleOpen = (options: Options) => {
    this.open = true;
    this.options = options;
  };
  @action public handleClose = () => {
    this.open = false;
    this.options = { callback: this.options?.callback };
    this.status = VITA_AUDIT_STATUS.pass.value;
    this.auditReasonSelect = null;
    this.auditReason = '';
  };

  @observable public isSubmit = false;
  public handleSubmit = async () => {
    if (this.isSubmit) {
      return;
    }

    if (this.status === VITA_AUDIT_STATUS.refuse.value && !this.auditReason) {
      const error = '请填写不通过原因';
      message.error({ key: error, content: error });
      return;
    }

    const params = {
      status: this.status,
      auditReason: this.auditReason,
    };

    this.isSubmit = true;
    try {
      await this.options.callback(params);
      this.handleClose();
    } finally {
      this.isSubmit = false;
    }
  };
}

export const AuthModal = observer(({ authModalStore, vitaAuditReasonList }: AuthModalProps) => {
  const { open, handleClose, options, handleSubmit, isSubmit } = authModalStore;
  return (
    <Modal
      destroyOnClose={true}
      centered={true}
      open={open}
      width={400}
      onCancel={handleClose}
      maskClosable={false}
      title={options.title}
      closable={true}
      confirmLoading={isSubmit}
      onOk={handleSubmit}
    >
      {options.header}
      <CommonAuth
        authModalStore={authModalStore}
        vitaAuditReasonList={vitaAuditReasonList}
      />
    </Modal>
  );
});

const CommonAuth = observer(
  ({
    authModalStore: { status, setStatus, auditReasonSelect, setAuditReasonSelect, auditReason, setAuditReason },
    vitaAuditReasonList,
  }: AuthModalProps) => {
    return (
      <>
        <div className={'mb-4'}>
          <Radio.Group
            size={'large'}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <Radio value={VITA_AUDIT_STATUS.pass.value}>通过</Radio>
            <Radio value={VITA_AUDIT_STATUS.refuse.value}>不通过</Radio>
          </Radio.Group>
        </div>
        {status === VITA_AUDIT_STATUS.refuse.value && (
          <>
            <div className={'text-heading text-12 mb-3'}>审核不通过原因</div>
            <div className={'mb-3'}>
              <Select
                value={auditReasonSelect}
                rootClassName={'w-full'}
                allowClear={true}
                onChange={(value) =>
                  setAuditReasonSelect(
                    value,
                    vitaAuditReasonList.find((item) => item.dictValue != otherReasonId && item.dictValue === value)
                      ?.dictName || '',
                  )
                }
                options={vitaAuditReasonList.map((item) => ({ value: item.dictValue, label: item.dictName }))}
                placeholder={'请选择不通过原因'}
              />
            </div>
          </>
        )}
        {auditReasonSelect == otherReasonId && (
          <div className={'mb-6'}>
            <Input.TextArea
              value={auditReason}
              onChange={(event) => setAuditReason(event.target.value)}
              allowClear={true}
              autoSize={{ minRows: 2, maxRows: 2 }}
              showCount={true}
              maxLength={30}
              placeholder={'请输入不通过原因'}
            />
          </div>
        )}
      </>
    );
  },
);

export const FlatAuth = observer(
  ({
    type,
    flatAuthStore,
    vitaAuditReasonList,
  }: { type: string; flatAuthStore: AuthModalStore; vitaAuditReasonList: DictItem[] }) => {
    return (
      <div className={'p-4 bg-bg rounded-1'}>
        <div className={'flex items-center gap-1 mb-3'}>
          <ClockCircleOutlined className={'text-primary text-16'} />
          <span className={'text-primary text-16 font-500'}>审核中</span>
        </div>
        {type === 'audit' && (
          <div>
            <CommonAuth
              vitaAuditReasonList={vitaAuditReasonList}
              authModalStore={flatAuthStore}
            />
            <div className={'flex justify-end'}>
              <Button
                loading={flatAuthStore.isSubmit}
                onClick={flatAuthStore.handleSubmit}
                type={'primary'}
              >
                确定
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  },
);
