import { MainSubStructure } from '@/utils';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import type { ServiceRecordModel } from './model';

export const ServiceRecord = observer(({ store }: { store: ServiceRecordModel }) => {
  const { visible, onClose, mainSubStructureModel } = store;
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      title="服务记录"
      centered={true}
      closable={true}
      width="60%"
    >
      <div style={{ height: '500px' }}>
        <MainSubStructure store={mainSubStructureModel} />
      </div>
    </Modal>
  );
});
