import type { AnchorDetailProps } from '@/pages/anchorDetail/anchorDetailStore';
import { FlatAuth } from '@/pages/anchorDetail/authModal';
import { ACCOUNT_TYPE, JOB_STATUS, SEX, VITA_AUDIT_STATUS, formatBooleanLabel } from '@/pages/anchorDetail/constants';
import { ResumeVita } from '@/pages/anchorDetail/resumeVita';
import { RenderByPermission } from '@/utils';
import { ExclamationOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import type React from 'react';

export const ResumeInformationDetail = observer(({ anchorDetailStore }: AnchorDetailProps) => {
  const { anchorDetail, vitaAuditReasonList, flatAuthStore, type } = anchorDetailStore;
  const userJobHuntVO = anchorDetail?.userJobHuntVO || {};
  return (
    <div className={'w-[350px] flex flex-col gap-3'}>
      {userJobHuntVO.vitaAuditStatus === VITA_AUDIT_STATUS.audit.value && (
        <RenderByPermission permissionId={'122_49'}>
          <FlatAuth
            type={type}
            flatAuthStore={flatAuthStore}
            vitaAuditReasonList={vitaAuditReasonList}
          />
        </RenderByPermission>
      )}
      {userJobHuntVO.vitaAuditStatus === VITA_AUDIT_STATUS.refuse.value ? (
        <div className={'p-4 flex items-center bg-warning/10 rounded-1'}>
          <ExclamationOutlined className={'text-warning text-16'} />
          <span className={'text-warning text-16 font-500'}>{userJobHuntVO.vitaAuditReason}</span>
        </div>
      ) : null}
      <ItemContainer
        extra={
          userJobHuntVO.vitaAuditStatus !== VITA_AUDIT_STATUS.audit.value ? (
            <img
              className={'w-20 h-5 absolute right-0 top-0'}
              src={Object.values(VITA_AUDIT_STATUS).find((item) => item.value === userJobHuntVO.vitaAuditStatus)?.img}
              alt=""
            />
          ) : null
        }
        title={'基本信息'}
        data={[
          { title: '姓名', content: anchorDetail.name },
          { title: '性别', content: Object.values(SEX).find((item) => item.value === anchorDetail.sex)?.label },
          { title: '联系方式', content: anchorDetail?.contactInfo || '' },

          {
            title: '出生年月',
            content: [
              anchorDetail.birthday ? dayjs(anchorDetail.birthday).format('YYYY-MM-DD') : '',
              `${anchorDetail.age || 0}岁`,
            ]
              .filter(Boolean)
              .join(' '),
          },
          { title: '身高', content: `${anchorDetail.height || 0}cm` },
          { title: '体重', content: `${anchorDetail.weight || 0}kg` },
          {
            title: '三围',
            content: [anchorDetail.bustSize || '', anchorDetail.waistSize || '', anchorDetail.hipSize || '']
              .filter(Boolean)
              .join('/'),
          },
        ]}
      />
      <ItemContainer
        title={'直播经验'}
        data={[
          { title: '直播年限', content: `${anchorDetail.workYear || 0}年` },
          { title: '单场最高GMV', content: `${userJobHuntVO.gmv || 0}元` },
          { title: '最高在线人数', content: userJobHuntVO.onLineCount || 0 },
          {
            title: '播过的类目',
            content: userJobHuntVO?.oldLiveTypeList
              ?.map((item) =>
                item?.childrenList?.length > 0 ? item.childrenList.map((val) => val.name).join('、') : item.name,
              )
              .join('、'),
          },
          {
            title: '播过的帐号',
            content: (userJobHuntVO.platformAccountInfo || [])
              .reduce((previousValue, currentValue) => {
                currentValue?.account.forEach((item) => {
                  previousValue.push(
                    [
                      Object.values(ACCOUNT_TYPE).find((val) => val.value == item.accountType)?.label || '不限',
                      currentValue.platformValue || '',
                      item.account || '',
                    ].join(' | '),
                  );
                });
                return previousValue;
              }, [])
              .map((item, index) => (
                <span
                  className={'block'}
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={index}
                >
                  {item}
                </span>
              )),
          },
          { title: '起号经验', content: formatBooleanLabel(userJobHuntVO.natureStartAccount, '有', '无') },
          { title: '套路玩法', content: formatBooleanLabel(userJobHuntVO.playMethod, '会', '不会') },
          { title: '面试试镜', content: formatBooleanLabel(userJobHuntVO.acceptAudition, '接受', '不接受') },
          { title: '直播风格', content: userJobHuntVO.liveStyle },
        ]}
      />
      <ItemContainer
        title={'个人优势'}
        data={[{ content: userJobHuntVO.advantage }]}
      />
      <ItemContainer
        title={'求职状态'}
        data={[{ content: Object.values(JOB_STATUS).find((item) => item.value === userJobHuntVO.jobStatus)?.label }]}
      />
      <ResumeVita anchorDetailStore={anchorDetailStore} />
    </div>
  );
});

function ItemContainer({
  title,
  data,
  extra,
}: {
  title?: string;
  extra?: React.ReactNode;
  data: Array<{ title?: string; content?: React.ReactNode; truncate?: boolean }>;
}) {
  return (
    <div className={'relative p-4 border border-solid border-divide rounded-1 flex flex-col gap-4 min-w-0'}>
      {title ? <div className={'text-heading text-14 font-500'}>{title}</div> : null}
      {data.map((item, index) => (
        <div
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          key={index}
          className={'flex items-center justify-between text-12'}
        >
          {item.title ? <span className={'text-normal mr-2 text-right'}>{item.title}:</span> : null}
          <span className={`flex-1 text-heading${item.truncate ? ' truncate' : ''}`}>{item.content}</span>
        </div>
      ))}
      {extra}
    </div>
  );
}
