import { observer } from 'mobx-react';
import React, { Component, useMemo, useState } from 'react';
import { SearchListStructure } from '../../../utils/searchListStructure';

import { ImportFile } from '../../../compnents/import/importFile';
import { ImportFailList } from '../../../compnents/importFailList';
import { Approval } from './checkModal';
import Model from './model';
import PositionCard from './positionCard';
import { RecommendedPositionModal } from './recommendedPositionModel';
import { RelevancyEmployerModal } from './relevancyEmployer';
import { ShowToPartBroker } from './showToPartBroker';

const JDLibrary = () => {
  const store = useMemo(() => new Model(), []);
  const {
    mainStore,
    importStore,
    relevancyEmployerStore,
    approvalStore,
    recommendedPositionModel,
    showToPartBrokerModel,
  } = store;

  return (
    <div>
      <SearchListStructure store={mainStore} />
      {/* 关联雇主 */}
      <RelevancyEmployerModal store={relevancyEmployerStore} />
      <Approval store={approvalStore} />
      {/* 下载职位卡片 */}
      <PositionCard store={store} />
      {/* 推荐 */}
      <RecommendedPositionModal store={recommendedPositionModel} />
      <ShowToPartBroker store={showToPartBrokerModel} />
      {/* 导入弹窗 */}
      <ImportFile store={importStore} />
    </div>
  );
};

export default observer(JDLibrary);
