import { observer } from 'mobx-react';
import { useMemo } from 'react';

import { VideoModal } from '@/compnents/videoModal.tsx';
import { MainSubStructure } from '@/utils';
import Model from './model';
const AuditLog = () => {
  const store = useMemo(() => new Model(), []);
  // const userId = new URLSearchParams(window.location.search)?.get('id');
  const { mainSubStructureModel, videoModalStore } = store;

  return (
    <div className="h-screen">
      <MainSubStructure store={mainSubStructureModel} />
      <VideoModal videoModalStore={videoModalStore} />
    </div>
  );
};

export default observer(AuditLog);
