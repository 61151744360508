import { type BaseData, MainSubStructureModel, NormalProgramme, request } from '@/utils';
import { message } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';

export default class EstimatedRevenueModel {
  @observable public visible = false;
  @observable public id = null;
  @observable public anchorInviteCount = 0;
  @observable public totalAmount = 0;

  @action public onClose = () => {
    this.visible = false;
  };

  @action public onShow = (id) => {
    this.visible = true;
    this.id = id;
    this.mainSubStructureModel.onQuery();
  };

  public filterSet: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'dateStart',
        format: 'YYYY-MM-DD',
        field: 'estimatedData',
        label: '预估收益日期：',
        span: 6,
        placeholder: '请选择预估收益日期',
        value: dayjs().subtract(1, 'day'),
        disabledDate: (current) => {
          return current && current > dayjs().endOf('day');
        },
      },
    ],
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'name',
          name: '收益名称',
        },
        {
          key: 'type',
          name: '收益类型',
        },
        {
          key: 'num',
          name: '人次',
        },
        {
          key: 'money',
          name: '预估收益（元）',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: false,
      showGridOrderNo: false,
    },
    hiddenSubTable: true,

    api: {
      onQuery: () => {
        const filterParams = this.filterSet.filterItems.params;
        if (!filterParams.estimatedData) {
          message.error('查询收益时间不能为空');
          return Promise.reject();
        }
        if (filterParams.estimatedData) {
          filterParams.incomeTime = filterParams.estimatedData.split(' ')[0];
          filterParams.estimatedData = undefined;
        }

        return request<BaseData<any>>({
          url: '/pbb/platform/broker/income',
          method: 'POST',
          data: {
            brokerId: this.id,
            ...filterParams,
          },
        }).then((resp) => {
          this.anchorInviteCount = resp.data.anchorInviteCount;
          this.totalAmount = resp.data.totalAmount;
          resp.data.list = [
            {
              id: 0,
              name: '简历通过',
              type: '拉新主播',
              num: resp.data.anchorVitaAuditSuccessCount,
              money: resp.data.anchorVitaAuditSuccessAmount,
            },
            {
              id: 1,
              name: '主播报名',
              type: '报名服务',
              num: resp.data.anchorSignUpCount,
              money: resp.data.anchorSignUpAmount,
            },
            {
              id: 2,
              name: '主播到面',
              type: '撮合到面',
              num: resp.data.anchorToFaceCount,
              money: resp.data.anchorToFaceAmount,
            },
            {
              id: 3,
              name: '主播付费报名',
              type: '注册躺赚',
              num: resp.data.anchorPaySignUpCount,
              money: resp.data.anchorPaySignUpAmount,
            },
            {
              id: 4,
              name: '购买全国主播交流群',
              type: '注册躺赚',
              num: resp.data.anchorPaySocialGroupCount,
              money: resp.data.anchorPaySocialGroupAmount,
            },

            {
              id: 5,
              name: '雇主付费自主邀约',
              type: '注册躺赚',
              num: resp.data.employerPayOwnInviteCount,
              money: resp.data.employerPayOwnInviteAmount,
            },
            {
              id: 6,
              name: '雇主付费人工邀约',
              type: '注册躺赚',
              num: resp.data.employerPayButlerInviteCount,
              money: resp.data.employerPayButlerInviteAmount,
            },
          ];
          return resp;
        });
      },
    },
  });
}
