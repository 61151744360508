import { type BaseData, request } from '@/utils';
import type { FormInstance } from 'antd';
import { action, observable } from 'mobx';
import { set } from 'nprogress';
import { API } from '../api';

export default class InsertUserModel {
  @observable public visible = false;
  @observable public parent = null;
  @observable public ref: FormInstance;
  @observable public options = [];
  @observable public currentValue = '';

  constructor({ parent }) {
    this.parent = parent;
  }

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };
  @action public onShow = async () => {
    this.visible = true;
  };

  @action public onCancel = () => {
    this.visible = false;
  };

  @action public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    const values = this.ref.getFieldsValue();
    const postParams = { ...values };
    const { sysUser } = postParams;

    return request<BaseData<any>>({
      url: API.addCorpWechatUser,
      method: 'POST',
      data: {
        sysUserId: sysUser,
      },
    }).then(() => {
      this.visible = false;
      this.parent.weComAIgrid.onQuery();
    });
  };
}
