import type { AnchorDetailStore } from '@/pages/anchorDetail/anchorDetailStore';
import type { ServiceOrderDetailProjectVO } from '@/pages/anchorDetail/types';
import { requireSource } from '@/utils';
import type { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';

export const ServiceInformation = observer(({ anchorDetailStore }: { anchorDetailStore: AnchorDetailStore }) => {
  const { anchorDetail, endServiceStore } = anchorDetailStore;
  const columnDefs: ColDef<ServiceOrderDetailProjectVO>[] = [
    {
      field: 'projectName',
      headerName: '服务名称',
    },
    {
      field: 'amount',
      headerName: '支付金额(元)',
      cellRenderer: (params) => <span className={'text-error'}>{params.value || 0}</span>,
    },
    {
      field: 'channelName',
      headerName: '付费来源',
    },
    {
      field: 'inviteName',
      headerName: '付费推荐人',
      cellRenderer: (params) => {
        const { inviteName, inviteAvatar, inviteId, inviteMobile, serviceObjectList } = params.data;
        return (
          <div className="flex items-center cursor-pointer h-full gap-2">
            {inviteAvatar && (
              <div className="relative">
                <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                  <img
                    src={inviteAvatar}
                    className="w-full h-full"
                    alt=""
                  />
                </div>
              </div>
            )}
            {inviteName || inviteId || inviteMobile ? (
              <div className="text-12 leading-[18px]">
                <div className="flex items-center">
                  {serviceObjectList && serviceObjectList.length > 0 && (
                    <div className="flex h-4">
                      <img
                        src={requireSource('/common/payStatus.png')}
                        alt={''}
                        className={'w-12 h-4.5 mb-1'}
                      />
                      <div style={{ lineHeight: '16px', color: 'red' }}>({serviceObjectList.length})</div>
                    </div>
                  )}
                </div>
                <div className="flex items-center">
                  用户昵称：{inviteName}
                  <img
                    src={requireSource('/common/idcard.png')}
                    alt={''}
                    className={'w-4 h-4 ml-1'}
                  />
                </div>
                <div>ID：{inviteId}</div>
                <div>手机号：{inviteMobile}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: '服务状态',
      cellRenderer: (params) => {
        return params?.data?.status === 1 ? '进行中' : '已结束';
      },
    },
    {
      field: 'createTime',
      headerName: '创建时间',
      valueFormatter: (params) => (params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      field: 'validEndTime',
      headerName: '到期时间',
      valueFormatter: (params) => (params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      headerName: '操作',
      cellRenderer: (params) => (
        <div>
          {(params.data.validEndTime && dayjs(params.data.validEndTime).isBefore(dayjs())) ||
          params.data.status === 2 ? (
            <Button
              onClick={() => {
                endServiceStore.onShow(params.data.id);
              }}
              type="link"
            >
              服务反馈
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => {
                endServiceStore.onShow(params.data.id);
              }}
            >
              结束服务
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <AgGridReact
      rowHeight={80}
      rowData={anchorDetail?.serviceOrderDetailProjectList || []}
      columnDefs={columnDefs}
      defaultColDef={{ flex: 1 }}
    />
  );
});
