import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { action, observable } from 'mobx';

export default class ShareCardModel {
  readonly textObj = {
    2: {
      text: '完善简历\n获得更多精准职位机会',
      qrText: '完善您的简历',
    },
    3: {
      text: '订阅岗位\n每天精准推荐',
      qrText: '了解更多',
    },
    4: {
      text: '经纪人全程陪跑\n保证您面试期望企业',
      qrText: '了解更多',
    },
    7: {
      text: '发布职位\n获得更多优质主播报名',
      qrText: '发布职位',
    },
    8: {
      text: '百万主播\n自主邀约',
      qrText: '了解更多',
    },
    11: {
      text: '当地主播交流群\n优质直招岗位',
      qrText: '了解更多',
    },
  };
  @observable channel: keyof typeof this.textObj;
  @observable wxUrl: string;
  @observable text: string;
  @observable qrText: string;

  @action public onDwonload(channel: number, str: string) {
    this.channel = channel as keyof typeof this.textObj;
    this.text = this.textObj[channel].text;
    this.qrText = this.textObj[channel].qrText;
    this.wxUrl = str;
    /** 延迟10毫秒，0不行 */
    setTimeout(() => {
      this.downPic(this.text);
    }, 10);
  }
  // 下载图片
  public downPic = async (name: string) => {
    const canvas2 = document.createElement('canvas');
    const _element = document.getElementById('ShareCardElement');

    const w = Number.parseInt(window.getComputedStyle(_element).width, 10);
    const h = Number.parseInt(window.getComputedStyle(_element).height, 10);

    canvas2.width = w * 4;
    canvas2.height = h * 4;
    const context = canvas2.getContext('2d');
    context.imageSmoothingEnabled = false;
    context.scale(4, 4);

    const canvas = await html2canvas(_element, {
      canvas: canvas2,
      scale: 1,
      useCORS: true,
    });
    canvas.toBlob((blob) => {
      FileSaver.saveAs(blob, name);
    });
  };
}
