import { type BaseData, getStaticResourceUrl, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, computed, observable } from 'mobx';

type PageType = '0' | '1'; // 0未合作职位 1职位管理
const dirDict = [
  { label: 'jdLibrary', value: '0' },
  { label: 'positionManagement', value: '1' },
];

export default class ImportModel {
  @observable public loading = false;
  @observable public importModalVisible = false;
  @observable public importFailModalVisible = false;
  @observable public importFailList: any[] = [];
  @observable public ref: FormInstance;
  @observable public pageType: PageType = null;

  public parentStore: any;

  constructor(parentStore: any) {
    this.parentStore = parentStore;
  }

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action openImportModal = (pageType: PageType) => {
    this.importModalVisible = true;
    this.pageType = pageType;
  };

  @action onImport = async () => {
    this.loading = true;
    try {
      await this.ref.validateFields();
      const values = await this.ref.getFieldsValue();
      const fd = new FormData();
      fd.append('file', values.file[0].originFileObj);
      fd.append('type', values.type);
      fd.append('flat', this.pageType);
      const res = await request<BaseData<any[]>>({
        url: '/pbb/platform/anon/sys/excel/import',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: fd,
      });
      console.log(res, 'res');
      if (res?.data?.length) {
        this.loading = false;
        this.importFailList = res.data;
        this.importFailModalVisible = true;
      } else {
        message.success('导入成功');
        this.loading = false;
        this.importModalVisible = false;
        if (this.parentStore.refresh) {
          this.parentStore.refresh();
        } else {
          this.parentStore.mainStore?.grid?.gridModel?.onQuery();
        }
      }
    } catch (error) {
      console.log('error', error);
      this.loading = false;
    }
  };

  @action onCancelFailModal = () => {
    this.importFailModalVisible = false;
    this.importFailList = [];
    this.ref.resetFields();
    this.ref.setFieldsValue({
      file: null,
    });
  };

  private env = process.env.NODE_ENV === 'production' ? 'prod' : 'dev';

  @action onDownloadTemplate = () => {
    const importTemplateDir = dirDict.find((item) => item.value == this.pageType)?.label; // 模版路径父文件夹
    const templateUrlMap = {
      0: getStaticResourceUrl(`pc-admin/import_template/${this.env}/${importTemplateDir}/full_time.xlsx`),
      1: getStaticResourceUrl(`pc-admin/import_template/${this.env}/${importTemplateDir}/long_part_time.xlsx`),
      2: getStaticResourceUrl(`pc-admin/import_template/${this.env}/${importTemplateDir}/short_part_time.xlsx`),
    };
    window.open(templateUrlMap[this.ref.getFieldValue('type')]);
  };

  @action onCancel = () => {
    this.importModalVisible = false;
  };
}
