import { MainSubStructure } from '@/utils';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import type Model from './model';

const GroupList = ({ store }: { store: Model }) => {
  const { groupListGrip, visible, onCancel } = store;

  return (
    <Modal
      title="企业微信群列表"
      footer={null}
      open={visible}
      onCancel={onCancel}
      width="40%"
      centered={true}
      maskClosable={false}
    >
      <div className="h-60">
        <MainSubStructure store={groupListGrip} />
      </div>
    </Modal>
  );
};

export default observer(GroupList);
