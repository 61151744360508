import { ASSETS } from '@/assets';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import styles from './index.less';
import type AnchorInfoModel from './model';

const AnchorInfoModal = ({ store }: { store: AnchorInfoModel }) => {
  const pageId = new URLSearchParams(window.location.search)?.get('pageId');
  const { onClose, userVo, visible, shareAnchor, type } = store;
  if (!userVo) {
    return null;
  }
  const {
    avatar,
    name,
    nickName,
    mobile,
    channelName,
    accountStatus,
    serviceObjectList,
    createTime,
    invitedCount,
    interviewCount,
    interviewedCount,
    signUpCount,
    brokerName,
    mediumName,
    canOwnInvite,
    inviteName,
    inviteUserId,
    inviteMobile,
    inviteAvatar,
    tagList,
    id,
    isVirtual,
  } = userVo;
  const renderServiceItem = () => {
    return serviceObjectList.map((item) => {
      const { serviceName, validEndTime } = item;
      return (
        <div
          className={'flex flex-col gap-y-3'}
          key={serviceName}
        >
          <div className="text-heading text-12">服务类型：{serviceName}</div>
          <div className="text-heading text-12">
            服务到期时间：{validEndTime ? dayjs(validEndTime).format('YYYY-MM-DD HH:mm:ss') : '-'}
          </div>
        </div>
      );
    });
  };
  const isFee = userVo?.userAccountVO?.cpay === 1;
  return (
    <Modal
      open={visible}
      className={styles.page}
      width={800}
      onCancel={onClose}
      maskClosable={true}
      footer={null}
    >
      <div className="flex flex-row gap-x-4  flex-1">
        <div>
          <img
            src={avatar}
            className={`${styles.img} rounded-1`}
            alt=""
          />
          <div className="flex flex-row items-center mt-3 flex-1 justify-between ">
            <div className="flex flex-row items-center flex-1">
              <div className="text-heading text-14">{name ? `姓名：${name}` : `昵称：${nickName}`}</div>
              {isFee && (
                <div className="flex h-4 ml-2">
                  <img
                    src={ASSETS.COMMON.PAY_STATUS}
                    alt={''}
                    className={'w-12 h-4.5 mb-1'}
                  />
                  <div style={{ lineHeight: '16px', color: 'red', marginLeft: 2 }}>({userVo?.userOrderCount})</div>
                </div>
              )}
            </div>
          </div>
          <div className="text-[#4E546E] text-12 mt-2 mb-4">ID：{id}</div>

          <div className="text-[#4E546E] text-12 mt-2 mb-4">手机号：{mobile}</div>
          <div className="flex flex-row items-center">
            <div className="text-heading text-12">帐号状态：</div>
            <div className="flex flex-row items-center">
              <div className={`w-1.5 h-1.5 ${accountStatus === 0 ? 'bg-[#52C41A]' : 'bg-[#FF4D4F]'} rounded-1.5`} />
              <div className="text-heading text-12 ml-2">{accountStatus === 0 ? '正常' : '已封禁'}</div>
            </div>
          </div>
          <div className="text-[#4E546E] text-12 mt-2 mb-4">激活状态：{isVirtual === 0 ? '已激活' : '未激活'}</div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex justify-between">
            <div className="text-heading text-14 font-500">平台信息</div>
            <div className="flex pr-12">
              <div
                className="text-primary text-12"
                onClick={() => {
                  window.top.RingPermission.openTab(
                    `/pbb-pc-management/anchorDetail/${id}?type=${type}`,
                    '67',
                    '主播详情',
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                {'查看简历 >'}
              </div>
              <div
                className="text-primary text-12 ml-3"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  shareAnchor(id);
                }}
                style={{ cursor: 'pointer' }}
              >
                {'分享主播 >'}
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-6 gap-y-1">
            <div className="flex flex-col gap-y-3 px-2 py-4 border-solid border-[1px] border-[#CBCEDB] rounded-1">
              <div className="text-12 text-heading font-500">服务信息</div>
              {serviceObjectList && serviceObjectList.length > 0 ? (
                renderServiceItem()
              ) : (
                <div className="text-12 text-heading">免费服务</div>
              )}
            </div>
            <div className="flex flex-col gap-y-3 px-2 py-4 border-solid border-[1px] border-[#CBCEDB] rounded-1">
              <div className="text-12 text-heading font-500">求职数据</div>
              <div className="flex flex-row items-center flex-1">
                <div className="text-heading text-12 flex-1">被邀约数：{invitedCount || 0}</div>
                <div className="text-heading text-12 flex-1">报名数：{signUpCount || 0}</div>
              </div>
              <div className="flex flex-row items-center">
                <div className="text-heading text-12 flex-1">约面数：{interviewCount || 0}</div>
                <div className="text-heading text-12 flex-1">到面数：{interviewedCount || 0}</div>
              </div>
            </div>

            <div className="flex flex-col gap-y-3 px-2 py-4 border-solid border-[1px] border-[#CBCEDB] rounded-1">
              <div className="text-12 text-heading font-500">注册信息</div>
              <div className="flex flex-row items-center flex-1">
                <div className="text-heading text-12 flex-1">注册来源：{channelName}</div>
                <div className="text-heading text-12 flex-1 flex align-middle ">
                  注册推荐人：
                  {inviteName || inviteUserId || inviteMobile ? (
                    <div className="flex ml-2">
                      <div
                        style={{
                          height: '80px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div style={{ fontSize: '12px', lineHeight: '20px' }}>用户昵称：{inviteName}</div>
                        <div style={{ fontSize: '12px', lineHeight: '20px' }}>ID：{inviteUserId}</div>
                        <div style={{ fontSize: '12px', lineHeight: '20px' }}>手机号：{inviteMobile}</div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="text-heading text-12 flex-1">
                  注册时间：{dayjs(createTime).format('YYYY-MM-DD HH:mm:ss')}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-y-3 px-2 py-4 border-solid border-[1px] border-[#CBCEDB] rounded-1">
              <div className="text-12 text-heading font-500">业务信息</div>
              <div className="text-12 text-heading">雇主是否可自主邀约：{canOwnInvite === 0 ? '否' : '是'}</div>
            </div>
            <div className="flex flex-col gap-y-3 px-2 py-4 border-solid border-[1px] border-[#CBCEDB] rounded-1">
              <div className="text-12 text-heading font-500">标签</div>
              {tagList && tagList.length > 0 ? (
                <div className="flex flex-row items-center flex-1 gap-1 flex-wrap">
                  {tagList?.map((item) => {
                    return (
                      <div
                        className="text-primary text-12 px-2 bg-[#1D53D91A] border-[#3A6EF866] border-[1px] rounded-0.5"
                        key={item.libId}
                      >
                        {item.libName}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-12 text-heading">暂无标签</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default observer(AnchorInfoModal);
