import AMapLoader from '@amap/amap-jsapi-loader';
import { AutoComplete, Form, type FormInstance, Modal, message } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { createRef } from 'react';

export class AmapModelStore {
  /**
   * 高德地图key
   */
  private readonly key = '39b2e1563406d1ff6ab0bb03ebf74103';
  /**
   * 高德地图JsKey
   */
  private readonly jsKey = '981a318cb715adfb659129c1b4f98b37';
  /**
   * 高德地图
   */
  public map: any;
  /**
   * 高德地图实例
   */
  public amap: any;

  @observable public visible = false;

  @observable public ref = createRef<FormInstance>();

  @observable public cb;
  /**
   * 打开高德地图弹窗
   */
  @action public onOpen = (cb) => {
    this.visible = true;
    this.cb = cb;
    if (!this.amap) {
      AMapLoader.load({
        key: this.key,
        version: '2.0',
        plugins: ['AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete'],
      })
        .then((AMap: any) => {
          this.amap = AMap;
          this.map = new AMap.Map('containerMap', {
            viewMode: '3D', // 是否为3D地图模式
            zoom: 15, // 初始化地图级别
            mapStyle: 'amap://styles/normal', // 设置地图的显示样式
          });
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  /**
   * 高德地图弹窗确定
   */
  @action public onFinish = async () => {
    const values = this.ref.current.getFieldValue('address');
    console.log(values);
    if (!values) {
      message.error('请选择一个地址');
    } else {
      this.visible = false;
      this.ref.current.resetFields();
      this.cb(values);
    }
  };
  /**
   * 高德地图模糊搜索数据
   */
  @observable public options: Record<string, any>[] = [];

  /**
   * 高德地图模糊搜索
   * @param keywords 搜索关键词
   */
  @action public onSearch = (keywords: string) => {
    fetch(`https://restapi.amap.com/v3/assistant/inputtips?key=${this.jsKey}&keywords=${keywords}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.info === 'OK') {
          this.options =
            data.tips
              ?.filter((item: Record<string, any>) => item.location?.length > 0)
              ?.map((item: Record<string, any>) => ({
                label: `${item.district}${item.name}`,
                value: `${item.district}${item.name}`,
                info: item,
              })) || [];
        } else {
          this.options = [];
        }
      });
  };

  /**
   *
   * @param id
   * @param options 选择的数据
   */
  @action public onSelect = (id: string, options: Record<string, any>) => {
    console.log('onSelect', id, options);
    const position = options.info?.location.split(',') || [0, 0];
    const marker = new this.amap.Marker({
      icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
      position,
    });
    this.map.clearMap();
    this.map.setCenter(position);
    this.map.add(marker);
    this.ref?.current.setFieldValue('address', options.info);
  };

  @action public onCancel = () => {
    this.visible = false;
    this.ref?.current.resetFields();
  };
}

export const AmapModal = observer(({ store }: { store: any }) => {
  return (
    <Modal
      zIndex={2001}
      centered={true}
      onCancel={store.onCancel}
      open={store.visible}
      onOk={() => {
        store.ref?.current.submit();
      }}
      title="搜索地址"
    >
      <Form
        ref={store.ref}
        onFinish={() => {
          store.onFinish();
        }}
      >
        <Form.Item
          name="addressText"
          label="搜索地址"
        >
          <AutoComplete
            options={store.options}
            onSelect={store.onSelect}
            onSearch={store.onSearch}
            placeholder="输入关键词模糊查询地址"
          />
        </Form.Item>
      </Form>
      <div
        id="containerMap"
        className="w-full h-[400px]"
      />
    </Modal>
  );
});
