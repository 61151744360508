import { ASSETS } from '@/assets';
import AnchorInfoModel from '@/compnents/anchorInfo/model';
import { type BaseData, MainSubStructureModel, request, requireSource } from '@/utils';
import { Button, Modal, Tag, message } from 'antd';
import dayjs from 'dayjs';
import { observable } from 'mobx';
import { API } from './api';
import EditProtocolModel from './modal/editProtocolModel';

export default class WhiteListModel {
  constructor() {
    this.refresh();
  }
  public editProtocolStore = new EditProtocolModel(this);
  public protocolStore: MainSubStructureModel = new MainSubStructureModel({
    // buttons: [
    //   {
    //     text: '新增协议',
    //     // permissionId: '2',
    //     handleClick: () => {
    //       this.editProtocolStore.handleOpen();
    //     },
    //   },
    // ],
    grid: {
      columns: [
        {
          name: '操作',
          key: 'operations',
          formatter: ({ row }) => {
            return (
              <div>
                <Button
                  type="link"
                  onClick={() => {
                    this.editProtocolStore.handleOpen(row);
                  }}
                >
                  编辑协议
                </Button>
              </div>
            );
          },
        },
        {
          name: '协议标题',
          key: 'title',
        },
        {
          name: '协议类型',
          key: 'type',
          formatter: ({ row }) => {
            return this.editProtocolStore.typeDict?.find((type) => type.value === row.type)?.label;
          },
        },
        {
          name: '协议内容',
          key: 'content',
        },
        {
          name: '终端',
          key: 'terminal',
          formatter: ({ row }) => {
            return this.editProtocolStore.terminalDict?.find((type) => type.value === row.terminal)?.label;
          },
        },
        {
          name: '更新人',
          key: 'updatePerson',
          width: 200,
          formatter: ({ row }) => {
            return (
              <div className="h-[100%] leading-[20px] flex flex-col justify-center">
                <p>
                  用户昵称：<span>{row.updateNickName}</span>
                </p>
                <p>
                  ID：<span>{row.updateId}</span>
                </p>
                <p>
                  手机号：<span>{row.updateMobile}</span>
                </p>
              </div>
            );
          },
        },
        {
          name: '更新时间',
          key: 'updateTime',
          formatter: ({ row }) => {
            if (row.updateTime) {
              return dayjs(row.updateTime).format('YYYY-MM-DD HH:mm:ss');
            }
            return null;
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rowHeight: 80,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: false,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: async (params) => {
        const { ...rest } = params;
        return request<BaseData<any>>({
          url: API.protocolList,
          method: 'post',
          data: { ...rest },
        });
      },
    },
  });

  public refresh = () => {
    this.protocolStore.onQuery();
  };
}
