import { observer } from 'mobx-react';
import React, { Component, useMemo, useState } from 'react';
import { SearchListStructure } from '../../../utils/searchListStructure';
import { AlternativeSliceModal } from './alternativeSlice';
import { ChangeButler } from './changeButler';
import { EmployerDetailModal } from './employerDetail';
import Model from './model';
import { ServiceRecord } from './serviceRecord';
// import { EditEnterprise } from './editEnterprise';

const EmployerManagement = () => {
  const store = useMemo(() => new Model(), []);
  const { mainStore, employerDetailStore, alternativeSliceStore, serviceRecordStore, changeBulterStore } = store;

  return (
    <div>
      <SearchListStructure store={mainStore} />
      <AlternativeSliceModal store={alternativeSliceStore} />
      <EmployerDetailModal store={employerDetailStore} />
      <ServiceRecord store={serviceRecordStore} />
      <ChangeButler store={changeBulterStore} />
      {/* <EditEnterprise /> */}
    </div>
  );
};

export default observer(EmployerManagement);
