import JoditEditor from 'jodit-react';
import { observer } from 'mobx-react';
import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';

interface RichTextProps {
  value?: string; // formItem透传value
  id?: string; // formItem透传name
  onChange?: (value: string) => void; // formItem透传onChange
}

const RichText = ({ value, onChange, ...r }: RichTextProps) => {
  const editor = useRef();
  const config = useMemo(
    () => ({
      placeholder: '请输入',
      buttons: ['bold', 'italic', 'source'],
      disablePlugins: ['paste'],
      hidePoweredByJodit: true,
    }),
    [],
  );
  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={config}
      onChange={onChange}
    />
  );
};
export default observer(RichText);
