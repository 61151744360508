import type { BrokerVO, UserVO } from '@/pages/anchor/anchorManagement/type.ts';
import { api } from '@/utils/api.ts';
import { Button, Modal, Space, Tag, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable, toJS } from 'mobx';
import {
  type BaseData,
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  type PaginationData,
  RenderByPermission,
  SearchListModal,
  copyToClipboard,
  exportXlsx,
  extractValuesByKey,
  getWxUrl,
  request,
  requireSource,
  transformKeys,
} from '../../../utils';

interface areaList {
  provinceId?: string;
  provinceName?: string;
  cityId?: string;
  cityName?: string;
  countyId?: string;
  countyName?: string;
}

import { ASSETS } from '@/assets/index.ts';
import AnchorInfoModel from '@/compnents/anchorInfo/model.ts';
import type { AnchorDetail } from '@/pages/anchorDetail/types.ts';
import { MyModelCardModel } from '@/pages/section/sectionAdmin/components/modelCard.tsx';
import { UploadOutlined } from '@ant-design/icons';
import FileSaver from 'file-saver';
import { API } from './api.ts';
import { AnchorTagsModalStore } from './components/anchorTags/model.ts';
import { AuditModalStore } from './components/audit/model.ts';
import { CanOwnInviteModel } from './components/canOwnInvite/model.tsx';
import { status, vitaAuditStatus } from './options.ts';

interface Option {
  label: any;
  value: string;
  id?: string | number | null;
  name?: string;
  children?: Option[];
  isLeaf?: boolean;
}

interface GroupedCity {
  cityId: string;
  cityName: string;
  counties: {
    countyId: string;
    countyName: string;
    provinceName: string;
  }[];
}

export default class AnchorManagementModel {
  private exportParams = {};
  @observable auditModalStore: AuditModalStore = new AuditModalStore(this); //审核弹框store
  @observable anchorTagsModalStore: AnchorTagsModalStore = new AnchorTagsModalStore(this); //主播标签弹框store
  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框
  @observable canOwnInviteModalStore: CanOwnInviteModel = new CanOwnInviteModel(this);

  public cities = [];

  myModelCardStore = new MyModelCardModel(); // 模卡
  //初始化查询条件数据
  init = async () => {
    //主播标签
    const categoriesList = await request<BaseData<Option[]>>({
      url: api.tag.getAllLibList,
      method: 'get',
      params: { categoryType: 5 },
    });
    this.mainStore.programme.filterItems.updateFilterItem([
      {
        field: 'categoryIdList',
        treeData: transformKeys(
          categoriesList.data.filter((e) => e.children.length > 0),
          {
            name: 'label',
            id: 'value',
          },
        ),
      },
    ]);

    const dictMaps = await request<BaseData<any>>({
      url: api.system.getDictMap,
      method: 'post',
      data: {
        dictTypeList: ['register_channel', 'service_name'],
      },
    });
    //注册来源
    const channel = dictMaps.data?.register_channel?.map((e) => {
      return { label: e.dictName, value: e.dictValue };
    });
    this.mainStore.programme.filterItems.updateFilterItem([{ field: 'channel', data: channel }]);
    this.mainStore.programme.filterItems.updateFilterItem([{ field: 'opusChannel', data: channel }]);

    //服务名称
    const serviceName = dictMaps.data?.service_name?.map((e) => {
      return { label: e.dictName, value: e.dictValue };
    });
    this.mainStore.programme.filterItems.updateFilterItem([{ field: 'functionTypeList', data: serviceName }]);
    const brokerListResult = await request<PaginationData<BrokerVO>>({
      url: API.getBrokerList,
      method: 'post',
      data: { pageNum: 1, pageSize: 1000 },
    });
    /**
     * 省市区
     */
    const cityResult = await request<BaseData<any>>({
      url: '/baseinfo/area/province/city/county/list',
      method: 'get',
      data: {},
    });
    const cityList = transformKeys(cityResult?.data || [], {
      name: 'label',
      id: 'value',
      childrenList: 'children',
    });

    this.cities = [...cityList];

    this.mainStore.programme.filterItems.updateFilterItem([
      {
        field: 'cityIdList',
        treeData: [{ label: '全国', value: '0' }, ...cityList],
      },
    ]);
  };

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'name',
        label: '主播信息',
        placeholder: '请输入主播昵称/姓名/手机号/ID',
      },
      {
        type: 'input',
        field: 'inviteKeyWord',
        label: '注册推荐人',
        placeholder: '请输入推荐人昵称/姓名/手机号/ID',
      },

      {
        type: 'select',
        field: 'functionTypeList',
        label: '服务名称',
        placeholder: '请选择',
        isParamList: true,
        mode: 'multiple',
      },
      {
        type: 'select',
        field: 'vitaComplete',
        label: '简历完善',
        placeholder: '请选择',
        data: [
          { label: '已完善', value: '1' },
          { label: '未完善', value: '0' },
        ],
      },
      {
        type: 'select',
        field: 'channel',
        label: '注册来源',
        placeholder: '请选择',
      },
      {
        type: 'treeSelect',
        field: 'categoryIdList',
        label: '主播标签',
        placeholder: '请选择',
        multiple: true,
        isParamList: true,
        treeCheckable: true,
        showCheckedStrategy: 'SHOW_CHILD',
        allowClear: true,
      },
      {
        type: 'treeSelect',
        field: 'cityIdList',
        label: '期望工作城市',
        placeholder: '请选择',
        multiple: true,
        isParamList: true,
        treeCheckable: true,
        showCheckedStrategy: 'SHOW_PARENT',
        allowClear: true,

        onChange: (ids: string[]) => {
          this.changeCityIds(ids);
        },
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '注册时间',
      },
      {
        type: 'dateRange',
        field: 'firstUploadTime',
        label: '首次上传简历时间',
      },
      {
        type: 'select',
        field: 'canOwnInvite',
        label: '雇主可自主邀约',
        data: [
          {
            label: '是',
            value: '1',
          },
          {
            label: '否',
            value: '0',
          },
        ],
      },
      {
        type: 'input',
        field: 'opusInviteKeyWord',
        label: '切片推荐人',
        placeholder: '请输入切片推荐人昵称/姓名/手机号/ID',
      },
      {
        type: 'select',
        field: 'opusChannel',
        label: '切片来源',
      },
      {
        type: 'select',
        field: 'isVirtual',
        label: '激活状态',
        value: '0',
        data: [
          { label: '已激活', value: '0' },
          { label: '未激活', value: '1' },
        ],
      },
      {
        type: 'select',
        field: 'vitaAuditStatus',
        label: '文本状态',
        data: vitaAuditStatus,
      },
      {
        type: 'select',
        field: 'sliceVitaStatus',
        label: '切片状态',
        data: status,
      },
      {
        type: 'select',
        field: 'imageVitaStatus',
        label: '形象照状态',
        data: status,
      },
      {
        type: 'select',
        field: 'muckUpVitaStatus',
        label: '模卡状态',
        data: status,
      },
    ],
  };

  constructor() {
    this.init();
    this.mainStore.grid.onQuery();
  }

  @action private changeCityIds = (ids: string[]) => {
    const filterItem: any = this.mainStore.programme.filterItems.getFilterItem('cityIdList');
    const allIds = extractValuesByKey(toJS(filterItem?.treeData), 'value', 'children');

    const prevIsCheckedAll = filterItem?.value?.includes('0');
    const nowIsCheckedAll = ids?.includes('0');

    if (ids[ids?.length - 1] === '0' || (prevIsCheckedAll && !nowIsCheckedAll)) {
      this.mainStore.programme.filterItems.updateFilterItem([
        {
          field: 'cityIdList',
          value: prevIsCheckedAll && !nowIsCheckedAll ? [] : allIds,
        },
      ]);
    } else {
      if (ids.filter((item) => item !== '0')?.length === allIds.filter((item) => item !== '0')?.length) {
        this.mainStore.programme.filterItems.updateFilterItem([{ field: 'cityIdList', value: allIds }]);
        return;
      }
      this.mainStore.programme.filterItems.updateFilterItem([
        { field: 'cityIdList', value: ids.filter((item) => item !== '0') },
      ]);
    }
  };

  public getParentId(list: any[], id: string) {
    for (const i in list) {
      if (list[i].value == id) {
        return [list[i]];
      }
      if (list[i].children) {
        const node = this.getParentId(list[i].children, id);
        if (node !== undefined) {
          return node.concat(list[i]);
        }
      }
    }
  }

  @action private getAreaList = (ids: string[]) => {
    const areaList: areaList[] = [];

    const value = ['provinceId', 'cityId', 'countyId'];
    const label = ['provinceName', 'cityName', 'countyName'];

    ids.map((item) => {
      const area = {
        provinceId: '0',
        provinceName: '',
        cityId: '',
        cityName: '',
        countyId: '0',
        countyName: '',
      };

      const result = this.getParentId(this.cities, item);

      if (result.length === 1) {
        const province = {
          provinceId: result[0].value,
          provinceName: result[0].label,
          cityId: '',
          cityName: '',
          countyId: '0',
          countyName: '',
        };
        result[0].children.forEach((city) => {
          const area = {
            ...province,
            cityId: city.value,
            cityName: city.label,
            countyId: '0', // 如果没有县信息，可以设置为0或其他默认值
            countyName: '',
          };
          areaList.push(area);
        });
      } else {
        for (let i = result.length; i--; ) {
          area[value[result.length - i - 1]] = result[i].value;
          area[label[result.length - i - 1]] = result[i].label;
        }
        areaList.push(area);
      }
    });
    return areaList;
  };

  /** 下载模卡 */
  @action public onModelCardDownload = async (item: UserVO) => {
    try {
      const res = await request<BaseData<AnchorDetail>>({
        url: '/pbb/platform/anchor/detail',
        method: 'POST',
        data: {
          id: item.id,
        },
      });
      if (res?.data?.muckUpUrl) {
        const fileExt = res.data.muckUpUrl.split('.').pop();
        FileSaver.saveAs(res.data.muckUpUrl, `主播模卡-${item.name}.${fileExt}`);
      } else {
        if (res.data) {
          await this.myModelCardStore.getWxCode(String(item.id));
          this.myModelCardStore.setUserData(res.data);
          setTimeout(() => {
            this.myModelCardStore.downPic(`主播模卡-${item.name}`);
          }, 10);
        } else {
          message.error('获取模卡失败');
        }
      }

      // await
    } catch (error) {
      throw new Error();
    }
  };
  // 封禁
  private onHandleDisabled = (userId: string, accountStatus: number) => {
    const tips = accountStatus === 0 ? '封禁' : '解封';
    Modal.confirm({
      title: '提示',
      content: `确认${tips}该主播账号?`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({
          url: API.updateAccountStatus,
          method: 'post',
          data: {
            userId,
            accountStatus: accountStatus === 0 ? 1 : 0,
          },
        });
        this.mainStore.grid.onQuery();
        message.success(`${tips}成功`);
      },
    });
  };

  public grid: IMainSubStructureModel<UserVO> = {
    buttons: [
      {
        text: '批量主播标签',
        permissionId: '48',
        handleClick: () => {
          const selectedRows = this.mainStore.grid.gridModel.selectRows;
          if (selectedRows.length === 0) {
            message.warning('请选择需要设置标签的主播');
            return;
          }
          this.anchorTagsModalStore.onShow(selectedRows);
        },
      },
      {
        text: '批量雇主可自主邀约',
        permissionId: '117',
        handleClick: () => {
          const selectedIds = this.mainStore.grid.gridModel.selectedIds;
          if (Array.from(selectedIds).length === 0) {
            message.warning('请选择需要雇主可自主邀约的主播');
            return;
          }
          const userIdList: string[] = Array.from(selectedIds) as any;
          this.canOwnInviteModalStore.onShow(userIdList);
        },
      },
      // {
      //   permissionId: '92',
      //   text: '导出',
      //   iconNode: <UploadOutlined />,
      //   handleClick: () => {
      //     exportXlsx('/pbb/platform/anchor/list/export', this.exportParams, '主播管理');
      //   },
      // },
    ],
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 360,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            const { accountStatus, auditStatus } = row; //0正常 1封禁
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <Button
                    onClick={() => {
                      //查看主播详情
                      window.top.RingPermission.openTab(
                        `/pbb-pc-management/anchorDetail/${row.id}?type=management`,
                        '67',
                        '主播详情',
                      );
                    }}
                    type="link"
                  >
                    详情
                  </Button>
                </RenderByPermission>
                {/* <RenderByPermission permissionId={`${pageId}_84`}>
                  <Button
                    onClick={() => {
                      this.onModelCardDownload?.(row);
                    }}
                    type="link"
                  >
                    下载模卡
                  </Button>
                </RenderByPermission> */}
                <RenderByPermission permissionId={`${pageId}_116`}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.shareAnchor(row.id);
                    }}
                    type="link"
                  >
                    分享主播
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_45`}>
                  <Button
                    onClick={() => {
                      this.anchorTagsModalStore.onShow([row]);
                    }}
                    type="link"
                  >
                    主播标签
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_46`}>
                  <Button
                    onClick={() => {
                      this.onHandleDisabled(row.id, accountStatus);
                    }}
                    type="link"
                  >
                    {accountStatus === 0 ? '封禁' : '解封'}
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '主播信息',
          width: 300,
          formatter: ({ row }) => {
            const { name, nickName, avatar, serviceObjectList, id, mobile } = row;
            const isPay = row?.userAccountVO?.cpay === 1;
            return (
              <div
                className="flex items-center cursor-pointer h-full gap-2"
                onClick={() => {
                  //弹出主播信息资料
                  this.anchorInfoModalStore.onShow(id, 'management');
                }}
              >
                <div className="relative">
                  <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                    <img
                      src={avatar}
                      className="w-full h-full"
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-12 leading-[18px]">
                  <div className="flex items-center">
                    {isPay ? (
                      <>
                        <img
                          src={ASSETS.COMMON.PAY_STATUS}
                          className="w-13"
                          alt=""
                        />
                        <div className="text-[red]"> ({row?.userOrderCount})</div>
                      </>
                    ) : null}
                  </div>
                  <div className="flex items-center">
                    {name ? `姓名：${name}` : `昵称：${nickName}`}
                    <img
                      src={requireSource('/common/idcard.png')}
                      alt={''}
                      className={'w-4 h-4 ml-1'}
                    />
                  </div>
                  <div>ID：{id}</div>
                  <div>手机号：{mobile}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'serviceObjectList',
          name: '服务名称',
          formatter: ({ row }) => {
            const { serviceObjectList } = row;
            if (!serviceObjectList || serviceObjectList.length === 0) {
              return '免费服务';
            }
            return (
              <Space className="flex  flex-col  items-start justify-center h-full">
                {serviceObjectList.map((e, index) => {
                  return (
                    <p
                      key={`${e.serviceName}_${index}`}
                      className="leading-[normal]"
                    >
                      {e.serviceName}
                    </p>
                  );
                })}
              </Space>
            );
          },
        },
        {
          key: 'tagList',
          name: '主播标签',
          formatter: ({ row }) => {
            const { tagList } = row;
            if (!tagList) {
              return '';
            }
            return (
              <Tooltip title={tagList.map((item) => item.libName).join(', ')}>
                {tagList?.map((item) => {
                  return (
                    <Tag
                      className="text-primary text-12 px-2 bg-[#1D53D91A] border-[#3A6EF866] border-[1px]"
                      key={item.libId}
                    >
                      {item.libName}
                    </Tag>
                  );
                })}
              </Tooltip>
            );
          },
        },
        {
          key: 'areaList',
          name: '期望工作城市',
          formatter: ({ row }) => {
            const { areaList } = row.userJobHuntVO;
            if (!areaList || areaList.length === 0) {
              return '';
            }
            const groupedAreaList = areaList.reduce((acc, item) => {
              const cityId = item.cityId;
              if (!acc[cityId]) {
                acc[cityId] = {
                  cityId: cityId,
                  cityName: item.cityName,
                  counties: [],
                };
              }
              acc[cityId].counties.push({
                countyId: item.countyId,
                countyName: item.countyName,
                provinceName: item.provinceName,
              });
              return acc;
            }, {});

            const groupedArray: GroupedCity[] = Object.values(groupedAreaList);

            return (
              <div className="flex">
                {groupedArray.map((item) => {
                  return (
                    <div
                      key={item.cityId}
                      className="ml-1"
                    >
                      {item.cityName}-
                      {item.counties
                        .map((county) => {
                          return county.countyName;
                        })
                        .join('、')}
                    </div>
                  );
                })}
              </div>
            );
          },
        },
        {
          key: 'canOwnInvite',
          name: '雇主可自主邀约',
          formatter: ({ row }) => {
            const { canOwnInvite } = row;
            return canOwnInvite === 1 ? '是' : '否';
          },
        },
        {
          key: 'vitaComplete',
          name: '简历完善',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            if (userJobHuntVO?.vitaComplete === 1) {
              return (
                <div className={'flex flex-row items-center'}>
                  <img
                    src={requireSource('/common/complete.png')}
                    className={'w-3 h-3'}
                    alt={''}
                  />
                  <div className={'text-[#52C41A] ml-1'}>已完善</div>
                </div>
              );
            }
            return (
              <div className={'flex flex-row items-center'}>
                <img
                  src={requireSource('/common/uncomplete.png')}
                  className={'w-3 h-3'}
                  alt={''}
                />
                <div className={'text-[#F5222D] ml-1'}>未完善</div>
              </div>
            );
          },
        },
        {
          key: 'userJobHuntV1',
          name: '首次上传简历时间',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.vitaFirstUploadTime
              ? dayjs(userJobHuntVO?.vitaFirstUploadTime)?.format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
        {
          key: 'userJobHuntVO',
          name: '简历更新时间',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.vitaUpdateTime
              ? dayjs(userJobHuntVO?.vitaUpdateTime)?.format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
        {
          key: 'vitaAuditStatus',
          name: '文本状态',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return vitaAuditStatus.find((item) => item.value == userJobHuntVO?.vitaAuditStatus)?.label || '';
          },
        },
        {
          key: 'textVitaFirstAuditSuccessTime',
          name: '文本首次审核通过时间',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.textVitaFirstAuditSuccessTime
              ? dayjs(userJobHuntVO?.textVitaFirstAuditSuccessTime)?.format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
        {
          key: 'sliceVitaStatus',
          name: '切片状态',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return status.find((item) => item.value == userJobHuntVO?.sliceVitaStatus)?.label || '';
          },
        },
        {
          key: 'sliceVitaFirstAuditSuccessTime',
          name: '切片首次审核通过时间',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.sliceVitaFirstAuditSuccessTime
              ? dayjs(userJobHuntVO?.sliceVitaFirstAuditSuccessTime)?.format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
        {
          key: 'imageVitaStatus',
          name: '形象照状态',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return status.find((item) => item.value == userJobHuntVO?.imageVitaStatus)?.label || '';
          },
        },
        {
          key: 'imageVitaFirstAuditSuccessTime',
          name: '形象照首次审核通过时间',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.imageVitaFirstAuditSuccessTime
              ? dayjs(userJobHuntVO?.imageVitaFirstAuditSuccessTime)?.format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
        {
          key: 'muckUpVitaStatus',
          name: '模卡状态',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;

            return status.find((item) => item.value == userJobHuntVO?.muckUpVitaStatus)?.label || '';
          },
        },
        {
          key: 'muckUpVitaFirstAuditSuccessTime',
          name: '模卡首次审核通过时间',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.muckUpVitaFirstAuditSuccessTime
              ? dayjs(userJobHuntVO?.muckUpVitaFirstAuditSuccessTime)?.format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
        {
          key: 'opusChannelName',
          name: '切片来源',
        },
        {
          key: 'opusInvite',
          name: '切片推荐人',
          formatter: ({ row }) => {
            const { opusInviteUserId, opusInviteName, opusInviteMobile } = row;

            if (!opusInviteUserId) {
              return '';
            }
            return (
              <div className="flex flex-col items-start justify-center  h-full">
                <div style={{ fontSize: '12px', lineHeight: '20px' }}>用户昵称：{opusInviteName}</div>
                <div style={{ fontSize: '12px', lineHeight: '20px' }}>ID：{opusInviteUserId}</div>
                <div style={{ fontSize: '12px', lineHeight: '20px' }}>手机号：{opusInviteMobile}</div>
              </div>
            );
          },
        },
        {
          key: 'accountStatus',
          name: '账号状态',
          formatter: ({ row }) => {
            const { accountStatus } = row;
            if (accountStatus === 0) {
              return (
                <div className={'flex flex-row items-center'}>
                  <div className={'w-1.5 h-1.5 rounded-1.5 bg-[#52C41A]'} />
                  <div className={'ml-1'}>正常</div>
                </div>
              );
            }
            return (
              <div className={'flex flex-row items-center'}>
                <div className={'w-1.5 h-1.5 rounded-1.5 bg-[#FF4D4F]'} />
                <div className={'ml-1'}>已封禁</div>
              </div>
            );
          },
        },
        {
          key: 'channelName',
          name: '注册来源',
        },
        {
          key: 'invite',
          name: '注册推荐人',
          with: '100',
          formatter: ({ row }) => {
            const { inviteUserId, inviteMobile, inviteName } = row;
            if (!inviteUserId) {
              return '';
            }

            return (
              <div className="flex flex-col items-start justify-center  h-full">
                <div style={{ fontSize: '12px', lineHeight: '20px' }}>用户昵称：{inviteName}</div>
                <div style={{ fontSize: '12px', lineHeight: '20px' }}>ID：{inviteUserId}</div>
                <div style={{ fontSize: '12px', lineHeight: '20px' }}>手机号：{inviteMobile}</div>
              </div>
            );
          },
        },
        {
          key: 'isVirtual',
          name: '激活状态',
          formatter: ({ row }) => {
            const { isVirtual } = row;
            return isVirtual === 1 ? '未激活' : '已激活';
          },
        },
        {
          key: 'createTime',
          name: '注册时间',
          formatter: ({ row }) => {
            const { createTime } = row;
            return createTime ? dayjs(createTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      rowHeight: 120,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
      pageSize: 20,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        if (postParams.cityIdList) {
          postParams.areaList = this.getAreaList(postParams.cityIdList);
          postParams.cityIdList = undefined;
        }

        if (postParams.createTime) {
          postParams.startTime = postParams.createTime.split(',')[0];
          postParams.endTime = postParams.createTime.split(',')[1];
          postParams.createTime = undefined;
        }

        if (postParams.firstUploadTime) {
          postParams.vitaFirstUploadStartTime = postParams.firstUploadTime.split(',')[0];
          postParams.vitaFirstUploadEndTime = postParams.firstUploadTime.split(',')[1];
          postParams.firstUploadTime = undefined;
        }
        if (postParams.latestAuditTime) {
          postParams.vitaAuditStartTime = postParams.latestAuditTime.split(',')[0];
          postParams.vitaAuditEndTime = postParams.latestAuditTime.split(',')[1];
          postParams.latestAuditTime = undefined;
        }
        this.exportParams = postParams;
        return request<PaginationData<UserVO>>({
          url: API.queryAnchorList,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  @action public shareAnchor = (id: number) => {
    getWxUrl({
      channel: 6,
      anchorId: id,
    }).then((url) => {
      copyToClipboard(url);
      message.success('复制分享主播链接成功');
    });
  };
}
