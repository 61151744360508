export const jobTypeDict = [
  {
    label: '全职',
    value: 1,
  },
  {
    label: '长期兼职',
    value: 2,
  },
  {
    label: '临时兼职',
    value: 3,
  },
];

export const isShow = [
  {
    label: '是',
    value: 1,
  },
  {
    label: '否',
    value: 0,
  },
];
export const requireSexDict = [
  { label: '不限', value: 0 },
  { label: '男', value: 1 },
  { label: '女', value: 2 },
];
export const isKeepOfficeDict = [
  { label: '需要', value: 1 },
  { label: '不需要', value: 0 },
];
export const acceptNewbieDict = [
  { label: '不接受', value: 0 },
  { label: '接受', value: 1 },
];
export const workPeriodDict = [
  { label: '上午时段', value: 1 },
  { label: '下午时段', value: 2 },
  { label: '晚间时段', value: 3 },
  { label: '深夜时段', value: 4 },
];
export const liveSegmentDict = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
];
export const publicDict = [
  { label: '否', value: 0 },
  { label: '是', value: 1 },
];
export const salaryRewardTypeDict = [
  { value: 0, label: '无提成' },
  { value: 1, label: '按销售额提成' },
  { value: 2, label: '按实际销售提成' },
  { value: 3, label: '按销售目标提成' },
  { value: 4, label: '按实际销量' },
];
export const bonusTypeDict = [
  { label: '无奖金', value: 0 },
  { label: '个人奖金', value: 1 },
  { label: '团队奖金', value: 2 },
];
export const salarySettleTypeDict = [
  { label: '次月结算', value: 2 },
  { label: '当月结算', value: 3 },
];
export const socialSecurityDict = [
  { label: '不缴纳', value: 0 },
  { label: '转正缴纳', value: 2 },
  { label: '入职缴纳', value: 1 },
];
export const tryUseSalaryDiscountDict = [
  { label: '不打折', value: 0 },
  { label: '打折', value: 1 },
];
export const requireAgeDict = [
  { value: 0, label: '无限制' },
  { value: 1, label: '有限制' },
];
export const requireDict = [
  { label: '无限制', value: 0 },
  { label: '有限制', value: 1 },
];
export const requireEducationDict = [
  {
    label: '无限制',
    value: 0,
  },
  {
    label: '专科以上',
    value: 1,
  },
  {
    label: '本科以上',
    value: 2,
  },
];
export const requireLanguageDict = [
  { label: '无', value: 0 },
  { label: '有', value: 1 },
];
export const requireLanguageTypeDict = [
  {
    label: '普通话',
    value: 1,
  },
  {
    label: '英语',
    value: 2,
  },
  {
    label: '其它',
    value: 3,
  },
];
export const accountTypeDict = [
  {
    label: '成熟号',
    value: 1,
  },
  {
    label: '新建号',
    value: 2,
  },
  {
    label: '老号重启',
    value: 3,
  },
];
export const flowTypeDict = [
  {
    label: '投放流量',
    value: 2,
  },
  {
    label: '自然流',
    value: 1,
  },
];
export const groupTypeDict = [
  {
    label: '成熟团队',
    value: 1,
  },
  {
    label: '新建团队',
    value: 2,
  },
];
export const needCoordinateWorkDict = [
  {
    label: '不需要',
    value: 0,
  },
  {
    label: '需要',
    value: 1,
  },
];
export const prepareTypeDict = [
  {
    label: '轮班',
    value: 2,
  },
  {
    label: '固定',
    value: 1,
  },
];
export const holidayRestTypeDict = [
  {
    label: '常规休',
    value: 1,
  },
  {
    label: '调休',
    value: 2,
  },
];
export const restTypeDict = [
  {
    label: '每月',
    value: 1,
  },
  {
    label: '每周',
    value: 2,
  },
];
export const restFixedDict = [
  {
    label: '固定',
    value: 1,
  },
  {
    label: '不固定',
    value: 0,
  },
];
export const weeksDict = [
  {
    label: '周一',
    value: '周一',
  },
  {
    label: '周二',
    value: '周二',
  },
  {
    label: '周三',
    value: '周三',
  },
  {
    label: '周四',
    value: '周四',
  },
  {
    label: '周五',
    value: '周五',
  },
  {
    label: '周六',
    value: '周六',
  },
  {
    label: '周天',
    value: '周天',
  },
];

export const liveShapeDict = [
  {
    label: '无',
    value: 0,
  },
  {
    label: '站播',
    value: 1,
  },
  {
    label: '坐播',
    value: 2,
  },
];
export const liveStyleDict = [
  {
    label: '激情播',
    value: '1',
  },
  {
    label: '种草播',
    value: '2',
  },
  {
    label: '平播',
    value: '3',
  },
  {
    label: '娱乐播',
    value: '4',
  },
];
export const requireAssociatePerformanceDict = [
  {
    label: '不限',
    value: 0,
  },
  {
    label: '有限制',
    value: 1,
  },
];
export const requireAssociateLiveStyleDict = [
  {
    label: '激情播',
    value: '激情播',
  },
  {
    label: '种草播',
    value: '种草播',
  },
  {
    label: '平播',
    value: '平播',
  },
  {
    label: '娱乐播',
    value: '娱乐播',
  },
];
export const datePeriodDict = [
  {
    label: '每日',
    value: 1,
  },
  {
    label: '每周',
    value: 2,
  },
  {
    label: '每月',
    value: 3,
  },
];
