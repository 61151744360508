import type { AnchorDetailStore } from '@/pages/anchorDetail/anchorDetailStore';
import { SEX, formatBooleanLabel } from '@/pages/anchorDetail/constants';
import { observer } from 'mobx-react';
import type React from 'react';
interface GroupedCity {
  cityId: string;
  cityName: string;
  counties: {
    countyId: string;
    countyName: string;
    provinceName: string;
  }[];
}

export const SubscribeInformation = observer(({ anchorDetailStore }: { anchorDetailStore: AnchorDetailStore }) => {
  const { anchorDetail } = anchorDetailStore;
  const userJobHuntVO = anchorDetail?.userJobHuntVO || {};
  const userPartSubscribeVO = anchorDetail?.userPartSubscribeVO || {};
  return (
    <div className={'flex flex-col gap-3'}>
      <ItemContainer
        title={'全职岗位订阅信息'}
        data={[
          {
            title: '工作城市',
            content:
              userJobHuntVO?.areaList
                ?.map((item) => (item.cityName ? `${item.cityName} - ${item.countyName}` : item.provinceName))
                ?.join('、') || '',
            required: true,
          },

          {
            title: '直播类目',
            content: userJobHuntVO?.expectLiveTypeList
              ?.map((item) =>
                item?.childrenList?.length > 0 ? item.childrenList.map((val) => val.name).join('、') : item.name,
              )
              .join('、'),
            required: true,
          },
          {
            title: '每日直播时长',
            content: `${userJobHuntVO.totalLiveHourStart || ''}-${userJobHuntVO.totalLiveHourEnd || ''}`,
          },
          {
            title: '底薪',
            content: `${userJobHuntVO.expectSalaryStart || ''}-${userJobHuntVO.expectSalaryEnd || ''}元`,
          },
          {
            title: '接受小白',
            content: formatBooleanLabel(userJobHuntVO.acceptNewbie, '接受', '不接受'),
          },
          {
            title: '坐班',
            content: formatBooleanLabel(userJobHuntVO.isKeepOffice, '否', '是'),
          },
          {
            title: '提成',
            content: formatBooleanLabel(userJobHuntVO.salaryReward, '有', '无'),
          },
          {
            title: '性别要求',
            content: Object.values(SEX).find((item) => item.value === anchorDetail.sex)?.label,
          },
        ]}
      />
      <ItemContainer
        title={'兼职岗位订阅信息'}
        data={[
          {
            title: '工作城市',
            content:
              userPartSubscribeVO?.areaList
                ?.map((item) => (item.cityName ? `${item.cityName} - ${item.countyName}` : item.provinceName))
                ?.join('、') || '',
            required: true,
          },
          {
            title: '直播类目',
            content: userPartSubscribeVO?.expectLiveTypeList
              ?.map((item) =>
                item?.childrenList?.length > 0 ? item.childrenList.map((val) => val.name).join('、') : item.name,
              )
              .join('、'),
            required: true,
          },
          {
            title: '单场直播时长',
            content: `${userPartSubscribeVO.liveHourStart || ''}-${userPartSubscribeVO.liveHourEnd || ''}`,
          },
          {
            title: '兼职薪资',
            content: `${userPartSubscribeVO.expectPtSalaryStart || ''}-${userPartSubscribeVO.expectPtSalaryEnd || ''}元/小时`,
          },
          { title: '接受小白', content: formatBooleanLabel(userPartSubscribeVO.partAcceptNewbie, '接受', '不接受') },
          // { title: '坐班', content: formatBooleanLabel(userPartSubscribeVO.isKeepOffice, '否', '是') },
          { title: '提成', content: formatBooleanLabel(userPartSubscribeVO.partSalaryReward, '有', '无') },
          {
            title: '性别要求',
            content: Object.values(SEX).find((item) => item.value === userPartSubscribeVO.sex)?.label,
          },
        ]}
      />
    </div>
  );
});

function ItemContainer({
  title,
  data,
}: {
  title?: string;
  data: Array<{ title: string; content: React.ReactNode; required?: boolean }>;
}) {
  return (
    <div className={'p-4 border border-solid border-divide rounded-1 flex flex-col gap-4 min-w-0'}>
      {title ? <div className={'text-heading text-14 font-500'}>{title}</div> : null}
      <div className={'grid grid-cols-3 gap-4'}>
        {data.map((item, index) => (
          <div
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            className={'flex items-center justify-between text-12'}
          >
            {item.required ? <span className={'text-warning'}>*</span> : null}
            <span className={'text-normal mr-2'}>{item.title}:</span>
            <span className={'flex-1 text-heading truncate'}>{item.content}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
