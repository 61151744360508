import { ASSETS } from '@/assets';
import AnchorInfoModel from '@/compnents/anchorInfo/model.ts';
import { Button, Image, Modal, Switch, Tag, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import { observable } from 'mobx';
import { MainSubStructureModel, type PaginationData, RenderByPermission, request, requireSource } from '../../../utils';
import EditorModel from './modal/editorModel';
import EstimatedRevenueModel from './modal/estimatedRevenueModel';
import type { PlatformSysBusinessCardVO } from './type';

interface UpdateData {
  adminId?: string | number;
  userId?: string | number;
}
/**
 * 业务卡片管理模型
 */
export default class Model {
  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框

  @observable estimatedRevenueStore: EstimatedRevenueModel = new EstimatedRevenueModel();

  constructor() {
    this.mainSubStructureModel?.gridModel?.onQuery();
  }

  /** 编辑模型 */
  public accessor editorModel = new EditorModel(this);

  /** 主从结构模型 */
  public accessor mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增经纪人',
        permissionId: '2',
        handleClick: () => {
          this.editorModel.onOpen('add');
        },
      },
    ],
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    onClick={() => {
                      this.editorModel.onOpen('edit', row);
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    onClick={() => {
                      this.onDeleteHandler(row.adminId, row.userId, row.id);
                    }}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_2`}>
                  <Button
                    onClick={() => {
                      this.estimatedRevenueStore.onShow(row.id);
                    }}
                    type="link"
                  >
                    预估收益
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'userInfo',
          name: '经纪人信息',
          width: 450,
          formatter: ({ row }) => {
            const { adminId, adminMobile, adminNickName, userAvatar, userId, userMobile, userNickName } = row;
            return (
              <div className="flex items-center gap-2 h-full">
                {adminId && (
                  <div className="flex items-center cursor-pointer h-full gap-2">
                    <div className="text-12 leading-[18px]">
                      <div>用户昵称：{adminNickName}</div>
                      <div>ID：{adminId}</div>
                      <div>手机号：{adminMobile}</div>
                    </div>
                  </div>
                )}
                {userId && (
                  <div
                    className="flex items-center cursor-pointer h-full ml-5 gap-2"
                    onClick={() => {
                      // 弹出主播信息资料
                      this.anchorInfoModalStore.onShow(userId);
                    }}
                  >
                    {userAvatar && (
                      <div className="relative">
                        <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                          <img
                            src={userAvatar}
                            className="w-full h-full"
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                    <div className="text-12 leading-[18px]">
                      <div className="flex items-center">
                        用户昵称：{userNickName}
                        <img
                          src={requireSource('/common/idcard.png')}
                          alt={''}
                          className={'w-4 h-4 ml-1'}
                        />
                      </div>
                      <div>ID：{userId}</div>
                      <div>手机号：{userMobile}</div>
                    </div>
                  </div>
                )}
              </div>
            );
          },
        },
        {
          key: 'cityList',
          name: '服务城市',
          formatter: ({ row }) => {
            const { cityList } = row;
            // biome-ignore lint/style/useBlockStatements: <explanation>
            if (!cityList?.length) return '';

            const tags = cityList.map((v: { id: number; name: string }) => <Tag key={v.id}>{v.name}</Tag>);

            const allTagsText = cityList.map((v: { id: number; name: string }) => v.name).join(', ');

            return (
              <Tooltip title={allTagsText}>
                <div>{tags}</div>
              </Tooltip>
            );
          },
        },
        {
          key: 'amount',
          name: '服务主播价格（元）',
          formatter: ({ row }) => {
            return `${row.amount}元` || '';
          },
        },
        {
          key: 'shareFreeSwitch',
          name: '分享免费',
          formatter: ({ row }) => {
            const { adminId, userId } = row;
            return (
              <Switch
                checked={row.shareFreeSwitch === 1}
                onChange={() => {
                  this.onSwitchChange(adminId, userId, row.id, row.shareFreeSwitch, 'shareFreeSwitch');
                }}
              />
            );
          },
        },
        {
          key: 'canWork',
          name: '承接流量',
          formatter: ({ row }) => {
            const { adminId, userId } = row;

            return (
              <Switch
                checked={row.canWork === 1}
                onChange={() => {
                  this.onSwitchChange(adminId, userId, row.id, row.canWork, 'canWork');
                }}
              />
            );
          },
        },
        {
          key: 'mainUrl',
          name: '形象照',
          width: 50,
          formatter: ({ row }) =>
            row.mainUrl && (
              <div className="flex items-center h-full">
                <Image
                  src={row.mainUrl}
                  style={{
                    width: '56px',
                    height: '56px',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ),
        },
        {
          key: 'keyWord',
          name: '关键词',
          formatter: ({ row }) => {
            if (row.keyWord) {
              const keyWord = JSON.parse(row.keyWord);
              return keyWord?.map((v: string) => <Tag key={v}>{v}</Tag>);
            } else {
              return '';
            }
          },
        },
        {
          key: 'briefIntroduction',
          name: '简介',
          with: 300,
          formatter: ({ row }) => <Tooltip title={row.briefIntroduction}>{row.briefIntroduction}</Tooltip>,
        },
        {
          key: 'wechatCode',
          name: '企微二维码',
          width: 50,
          formatter: ({ row }) =>
            row.wechatCode && (
              <div className="flex items-center h-full">
                <Image
                  src={row.wechatCode}
                  style={{
                    width: '56px',
                    height: '56px',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ),
        },
        {
          key: 'accountStatus',
          name: '状态',
          formatter: ({ row }) => {
            const { adminId, userId } = row;
            return (
              <Switch
                checked={row.accountStatus === 0}
                onChange={() => {
                  this.onSwitchChange(adminId, userId, row.id, row.accountStatus, 'accountStatus');
                }}
              />
            );
          },
        },
        {
          key: 'latestUpdate',
          name: '更新人',
          formatter: ({ row }) => {
            const { latestUpdateByName, latestUpdateByMobile, latestUpdateBy } = row;
            if (!latestUpdateBy) {
              return '';
            }
            return (
              <div className="flex items-center cursor-pointer h-full gap-2">
                <div className="text-12 leading-[18px]">
                  <div>用户昵称：{latestUpdateByName}</div>
                  <div>ID：{latestUpdateBy}</div>
                  <div>手机号：{latestUpdateByMobile}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'latestUpdateTime',
          name: '最后更新时间',
          sortable: true,
          formatter: ({ row }) => dayjs(row.latestUpdateTime).format('YYYY-MM-DD HH:mm:ss'),
        },
      ].map((v) => ({
        sortable: false,
        resizable: true,
        ...v,
      })),
      rows: [],
      rowHeight: 100,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      /**
       * 查询接口
       * @param {object} params - 请求参数
       * @returns {Promise<PaginationData<PlatformSysBusinessCardVO>>} 请求结果
       */
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        return request<PaginationData<PlatformSysBusinessCardVO>>({
          url: '/pbb/platform/broker/businessCard/list/page',
          method: 'POST',
          data: {
            ...postParams,
            type: 0,
          },
        });
      },
    },
  });

  // 删除标签分类
  private onDeleteHandler = (adminId, userId, id: string) => {
    const data: UpdateData = {};
    if (adminId) {
      data.adminId = adminId;
    }
    if (userId) {
      data.userId = userId;
    }

    Modal.confirm({
      title: '提示',
      content: '是否确认删除该用户名片吗?',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({
          url: '/pbb/platform/broker/businessCard/delete',
          method: 'post',
          data: { ...data, id, type: 0 },
        });
        message.success('删除成功');
        this.mainSubStructureModel.onQuery();
      },
    });
  };

  private onSwitchChange = async (adminId: number, userId: number, id: number, value: number, type: string) => {
    const updateValue = value === 1 ? 0 : 1;

    const data: UpdateData = {};

    if (adminId) {
      data.adminId = adminId;
    }
    if (userId) {
      data.userId = userId;
    }

    const resp = await request({
      url: '/pbb/platform/broker/businessCard/edit',
      method: 'post',
      data: {
        ...data,
        id,
        [type]: updateValue,
        type: 0,
      },
    });
    this.mainSubStructureModel.onQuery();
  };
}
