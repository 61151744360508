import { CloseOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Space, TimePicker } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { datePeriodDict, liveSegmentDict, weeksDict, workPeriodDict } from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 工作时间
 */
@inject('store')
@observer
class WorkingHours extends React.Component<{ store?: AddModel }> {
  render() {
    const { setValue, liveSegment, jobType, type, formRef, partTimeWorkPeriodType } = this.props.store;
    return (
      <Card
        title="工作时间"
        className={styles.card}
      >
        {[3].includes(jobType) ? (
          <Form.Item
            className={'mb-0'}
            label={'上班时间范围'}
          >
            <Form.List
              initialValue={[{}]}
              name="livePeriod"
            >
              {(fields, { add, remove }) => (
                <>
                  <Space
                    size={[20, 26]}
                    direction={'vertical'}
                  >
                    {fields.map(({ key, name, ...restField }) => {
                      return (
                        <Card
                          className={`w-full ${styles.rowCard} relative`}
                          key={key}
                        >
                          <Button
                            onClick={() => {
                              remove(name);
                            }}
                            type={'text'}
                            className={'absolute right-[-24px] top-[-14px]'}
                          >
                            <CloseOutlined className={'text-20 '} />
                          </Button>
                          <Space className={'w-full mb-3'}>
                            <Form.Item
                              noStyle={true}
                              rules={[{ required: true, message: '请选择最小时间' }]}
                              name={[name, 'dateStart']}
                            >
                              <DatePicker />
                            </Form.Item>
                            <div>-</div>
                            <Form.Item
                              noStyle={true}
                              rules={[{ required: true, message: '请选择最大时间' }]}
                              name={[name, 'dateEnd']}
                            >
                              <DatePicker />
                            </Form.Item>
                          </Space>
                          <Form.List
                            initialValue={[{}]}
                            name={[name, 'liveTimeList']}
                          >
                            {(fields, { add, remove }) => (
                              <Space
                                className={'ml-[13px]'}
                                direction={'vertical'}
                              >
                                {fields.map(({ key, name, ...restField }, index) => (
                                  <Space
                                    key={key}
                                    style={{ display: 'flex', marginBottom: 8 }}
                                    align="baseline"
                                  >
                                    <Form.Item
                                      noStyle={true}
                                      name={[name, 'start']}
                                      rules={[{ required: true, message: '请选择最小时间' }]}
                                    >
                                      <TimePicker
                                        className={'flex-1'}
                                        format={'HH:mm'}
                                      />
                                    </Form.Item>
                                    <div>-</div>
                                    <Form.Item
                                      noStyle={true}
                                      name={[name, 'end']}
                                      rules={[{ required: true, message: '请选择最大时间' }]}
                                    >
                                      <TimePicker
                                        className={'flex-1'}
                                        format={'HH:mm'}
                                      />
                                    </Form.Item>
                                    {[1, 3].includes(type) && fields.length > 1 ? (
                                      <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(name)}
                                      />
                                    ) : null}
                                    {[1, 3].includes(type) && fields.length - 1 === index ? (
                                      <PlusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => add()}
                                      />
                                    ) : null}
                                  </Space>
                                ))}
                              </Space>
                            )}
                          </Form.List>
                        </Card>
                      );
                    })}
                  </Space>

                  <Form.Item className={'mt-2'}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block={true}
                      icon={<PlusOutlined />}
                    >
                      添加时间段
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        ) : undefined}
        {[1, 2].includes(jobType) ? (
          <Space
            size={[20, 16]}
            className={'w-full'}
            direction={'vertical'}
          >
            {[2].includes(jobType) ? (
              <Form.Item
                label="上班时段"
                name={'partTimeWorkPeriodType'}
                className={'mb-0'}
                rules={[{ required: true }]}
              >
                <Radio.Group
                  onChange={(e) => {
                    if (partTimeWorkPeriodType === 1) {
                      formRef.current.resetFields(['dayPeriod']);
                    }
                    if (partTimeWorkPeriodType === 2) {
                      formRef.current.resetFields(['weekPeriod']);
                    }
                    if (partTimeWorkPeriodType === 3) {
                      formRef.current.resetFields(['monthPeriod']);
                    }
                    setValue('partTimeWorkPeriodType', e.target.value);
                  }}
                  options={datePeriodDict}
                />
              </Form.Item>
            ) : undefined}
            {[2].includes(jobType) && partTimeWorkPeriodType === 1 ? (
              <Form.Item
                className={'mb-0'}
                label={'上班时间范围'}
              >
                <Form.List
                  initialValue={[{}]}
                  name={'dayPeriod'}
                >
                  {(fields, { add, remove }) => (
                    <Space
                      className={'ml-[13px]'}
                      direction={'vertical'}
                    >
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                          key={key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            noStyle={true}
                            name={[name, 'start']}
                            rules={[{ required: true, message: '请选择最小时间' }]}
                          >
                            <TimePicker
                              className={'flex-1'}
                              format={'HH:mm'}
                            />
                          </Form.Item>
                          <div>-</div>
                          <Form.Item
                            noStyle={true}
                            name={[name, 'end']}
                            rules={[{ required: true, message: '请选择最大时间' }]}
                          >
                            <TimePicker
                              className={'flex-1'}
                              format={'HH:mm'}
                            />
                          </Form.Item>
                          {[1, 3].includes(type) && fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(name)}
                            />
                          ) : null}
                          {[1, 3].includes(type) && fields.length - 1 === index ? (
                            <PlusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => add()}
                            />
                          ) : null}
                        </Space>
                      ))}
                    </Space>
                  )}
                </Form.List>
              </Form.Item>
            ) : undefined}
            {[2].includes(jobType) && partTimeWorkPeriodType === 2 ? (
              <Form.Item
                className={'mb-0'}
                label={'上班时间范围'}
              >
                <Form.List
                  initialValue={[{}]}
                  name="weekPeriod"
                >
                  {(fields, { add, remove }) => (
                    <>
                      <Space
                        size={[20, 26]}
                        direction={'vertical'}
                      >
                        {fields.map(({ key, name, ...restField }) => {
                          return (
                            <Card
                              className={`w-full ${styles.rowCard} relative`}
                              key={key}
                            >
                              <Button
                                onClick={() => {
                                  remove(name);
                                }}
                                type={'text'}
                                className={'absolute right-[-24px] top-[-14px]'}
                              >
                                <CloseOutlined className={'text-20 '} />
                              </Button>
                              <Space className={'w-full mb-3'}>
                                <Form.Item
                                  noStyle={true}
                                  rules={[{ required: true, message: '请选择上班范围' }]}
                                  name={[name, 'weekDay']}
                                >
                                  <Checkbox.Group options={weeksDict} />
                                </Form.Item>
                              </Space>
                              <Form.List
                                initialValue={[{}]}
                                name={[name, 'weekPeriod']}
                              >
                                {(fields, { add, remove }) => (
                                  <Space direction={'vertical'}>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                      <Space
                                        key={key}
                                        style={{ display: 'flex', marginBottom: 8 }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          noStyle={true}
                                          name={[name, 'start']}
                                          rules={[{ required: true, message: '请选择最小时间' }]}
                                        >
                                          <TimePicker
                                            className={'flex-1'}
                                            format={'HH:mm'}
                                          />
                                        </Form.Item>
                                        <div>-</div>
                                        <Form.Item
                                          noStyle={true}
                                          name={[name, 'end']}
                                          rules={[{ required: true, message: '请选择最大时间' }]}
                                        >
                                          <TimePicker
                                            className={'flex-1'}
                                            format={'HH:mm'}
                                          />
                                        </Form.Item>
                                        {[1, 3].includes(type) && fields.length > 1 ? (
                                          <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(name)}
                                          />
                                        ) : null}
                                        {[1, 3].includes(type) && fields.length - 1 === index ? (
                                          <PlusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => add()}
                                          />
                                        ) : null}
                                      </Space>
                                    ))}
                                  </Space>
                                )}
                              </Form.List>
                            </Card>
                          );
                        })}
                      </Space>

                      <Form.Item className={'mt-2'}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block={true}
                          icon={<PlusOutlined />}
                        >
                          添加时间段
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            ) : undefined}
            {[2].includes(jobType) && partTimeWorkPeriodType === 3 ? (
              <Form.Item
                className={'mb-0'}
                label={'上班时间范围'}
              >
                <Form.List
                  initialValue={[{}]}
                  name="monthPeriod"
                >
                  {(fields, { add, remove }) => (
                    <>
                      <Space
                        size={[20, 26]}
                        direction={'vertical'}
                      >
                        {fields.map(({ key, name, ...restField }) => {
                          return (
                            <Card
                              className={`w-full ${styles.rowCard} relative`}
                              key={key}
                            >
                              <Button
                                onClick={() => {
                                  remove(name);
                                }}
                                type={'text'}
                                className={'absolute right-[-24px] top-[-14px]'}
                              >
                                <CloseOutlined className={'text-20 '} />
                              </Button>
                              <Space className={'w-full mb-3'}>
                                <Form.Item
                                  noStyle={true}
                                  rules={[{ required: true, message: '请选择最小时间' }]}
                                  name={[name, 'dateStart']}
                                >
                                  <DatePicker />
                                </Form.Item>
                                <div>-</div>
                                <Form.Item
                                  noStyle={true}
                                  rules={[{ required: true, message: '请选择最大时间' }]}
                                  name={[name, 'dateEnd']}
                                >
                                  <DatePicker />
                                </Form.Item>
                              </Space>
                              <Form.List
                                initialValue={[{}]}
                                name={[name, 'liveTimeList']}
                              >
                                {(fields, { add, remove }) => (
                                  <Space
                                    className={'ml-[13px]'}
                                    direction={'vertical'}
                                  >
                                    {fields.map(({ key, name, ...restField }, index) => (
                                      <Space
                                        key={key}
                                        style={{ display: 'flex', marginBottom: 8 }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          noStyle={true}
                                          name={[name, 'start']}
                                          rules={[{ required: true, message: '请选择最小时间' }]}
                                        >
                                          <TimePicker
                                            className={'flex-1'}
                                            format={'HH:mm'}
                                          />
                                        </Form.Item>
                                        <div>-</div>
                                        <Form.Item
                                          noStyle={true}
                                          name={[name, 'end']}
                                          rules={[{ required: true, message: '请选择最大时间' }]}
                                        >
                                          <TimePicker
                                            className={'flex-1'}
                                            format={'HH:mm'}
                                          />
                                        </Form.Item>
                                        {[1, 3].includes(type) && fields.length > 1 ? (
                                          <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(name)}
                                          />
                                        ) : null}
                                        {[1, 3].includes(type) && fields.length - 1 === index ? (
                                          <PlusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => add()}
                                          />
                                        ) : null}
                                      </Space>
                                    ))}
                                  </Space>
                                )}
                              </Form.List>
                            </Card>
                          );
                        })}
                      </Space>

                      <Form.Item className={'mt-2'}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block={true}
                          icon={<PlusOutlined />}
                        >
                          添加时间段
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            ) : undefined}
            {[1].includes(jobType) ? (
              <Form.Item
                label="上班时段"
                name={'workPeriod'}
                className={'mb-0'}
                rules={[{ required: true }]}
              >
                <Form.List
                  initialValue={[{}]}
                  name="workPeriod"
                >
                  {(fields, { add, remove }) => (
                    <>
                      <Space
                        size={[20, 26]}
                        direction={'vertical'}
                      >
                        {fields.map(({ key, name, ...restField }) => {
                          return (
                            <Card
                              className={`w-full ${styles.rowCard} relative`}
                              key={key}
                            >
                              <Button
                                onClick={() => {
                                  remove(name);
                                }}
                                type={'text'}
                                className={'absolute right-[-24px] top-[-14px]'}
                              >
                                <CloseOutlined className={'text-20 '} />
                              </Button>
                              <Space className={'w-full mb-3'}>
                                <Form.Item
                                  noStyle={true}
                                  rules={[{ required: true, message: '请选择上班时段' }]}
                                  name={[name, 'workPeriod']}
                                >
                                  <Radio.Group options={workPeriodDict} />
                                </Form.Item>
                              </Space>
                              <Form.List
                                initialValue={[{}]}
                                name={[name, 'workTime']}
                              >
                                {(fields, { add, remove }) => (
                                  <Space direction={'vertical'}>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                      <Space
                                        key={key}
                                        style={{ display: 'flex', marginBottom: 8 }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          noStyle={true}
                                          name={[name, 'start']}
                                          rules={[{ required: true, message: '请选择最小时间' }]}
                                        >
                                          <TimePicker
                                            className={'flex-1'}
                                            format={'HH:mm'}
                                          />
                                        </Form.Item>
                                        <div>-</div>
                                        <Form.Item
                                          noStyle={true}
                                          name={[name, 'end']}
                                          rules={[{ required: true, message: '请选择最大时间' }]}
                                        >
                                          <TimePicker
                                            className={'flex-1'}
                                            format={'HH:mm'}
                                          />
                                        </Form.Item>
                                        {[1, 3].includes(type) && fields.length > 1 ? (
                                          <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(name)}
                                          />
                                        ) : null}
                                        {[1, 3].includes(type) && fields.length - 1 === index ? (
                                          <PlusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => add()}
                                          />
                                        ) : null}
                                      </Space>
                                    ))}
                                  </Space>
                                )}
                              </Form.List>
                            </Card>
                          );
                        })}
                      </Space>

                      <Form.Item className={'mt-2'}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block={true}
                          icon={<PlusOutlined />}
                        >
                          添加时间段
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            ) : undefined}
            {/* {[1].includes(jobType) ? (
              <Form.Item
                label="上班时间范围"
                className={'mb-0'}
                required={true}
              >
                <div className={'flex flex-row items-center'}>
                  <Form.Item
                    noStyle={true}
                    name={'workTimeStart'}
                    rules={[{ required: true, message: '请选择最小时间' }]}
                  >
                    <TimePicker
                      className={'flex-1'}
                      format={'HH:mm'}
                    />
                  </Form.Item>
                  <div className="mx-4">-</div>
                  <Form.Item
                    noStyle={true}
                    name={'workTimeEnd'}
                    rules={[{ required: true, message: '请选择最大时间' }]}
                  >
                    <TimePicker
                      className={'flex-1'}
                      format={'HH:mm'}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            ) : undefined} */}
            <Row>
              <Col span={3}>
                <div className={'font-500 text-14 text-[#333333] text-right'}>每日直播时长：</div>
              </Col>
              <Space
                direction={'vertical'}
                className={'w-full'}
              >
                <Form.Item
                  name={'liveSegment'}
                  label={'是否分段播'}
                  rules={[{ required: true }]}
                  className={'mb-0'}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setValue('liveSegment', e.target.value);
                    }}
                    options={liveSegmentDict}
                  />
                </Form.Item>
                {liveSegment === 1 ? (
                  <Form.Item
                    label={'每日场次'}
                    className={'mb-0'}
                    required={true}
                  >
                    <Space direction={'horizontal'}>
                      <Form.Item
                        noStyle={true}
                        rules={[{ required: true, message: '请输入场次' }]}
                        name={'liveTimes'}
                      >
                        <InputNumber
                          maxLength={5}
                          className={'w-full'}
                          precision={0}
                        />
                      </Form.Item>
                      <div>场</div>
                    </Space>
                  </Form.Item>
                ) : undefined}

                <Form.Item
                  label={liveSegment === 1 ? '每场时长' : '每日时长'}
                  className={'mb-0'}
                  required={true}
                >
                  <Space direction={'horizontal'}>
                    <Form.Item
                      noStyle={true}
                      rules={[{ required: true, message: '请输入时长' }]}
                      name={'liveHour'}
                    >
                      <InputNumber
                        maxLength={5}
                        className={'w-full'}
                        precision={0}
                      />
                    </Form.Item>
                    <div>小时</div>
                  </Space>
                </Form.Item>

                {liveSegment === 1 ? (
                  <Form.Item
                    label={'中间休息'}
                    required={true}
                    className={'mb-0'}
                  >
                    <Space direction={'horizontal'}>
                      <Form.Item
                        noStyle={true}
                        rules={[{ required: true, message: '请输入休息时长' }]}
                        name={'middleRest'}
                      >
                        <InputNumber
                          maxLength={5}
                          className={'w-full'}
                          precision={0}
                        />
                      </Form.Item>
                      <div>小时</div>
                    </Space>
                  </Form.Item>
                ) : undefined}
              </Space>
            </Row>
          </Space>
        ) : undefined}
      </Card>
    );
  }
}
export default WorkingHours;
