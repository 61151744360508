import { type BaseData, request } from '@/utils';
import { Button, Form, type FormInstance, Input, Modal, message } from 'antd';

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

export const EndService = observer(({ store }: { store: EndServiceModal }) => {
  const { visible, onClose, setRef, onSave } = store;
  return (
    <Modal
      title="结束服务"
      centered={true}
      closable={true}
      open={visible}
      onCancel={onClose}
      width={383}
      footer={
        <div>
          <Button onClick={onClose}>取消</Button>
          <Button
            type="primary"
            onClick={onSave}
          >
            确定
          </Button>
        </div>
      }
    >
      <Form ref={setRef}>
        <Form.Item
          label="服务反馈"
          name="evaluateContent"
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export class EndServiceModal {
  @observable public visible = false;
  @observable public parent = null;

  @observable public ref: FormInstance;
  @observable public id: number;

  constructor(parent) {
    this.parent = parent;
  }

  @action public setRef = (ref) => {
    this.ref = ref;
  };

  @action public onShow = (id) => {
    this.visible = true;
    this.id = id;
  };

  @action public onSave = () => {
    const values = this.ref.getFieldsValue();
    const postParams = { ...values };
    request<BaseData<any>>({
      url: '/pbb/platform/anchor/service/cancel',
      method: 'POST',
      data: {
        id: this.id,
        ...postParams,
      },
    });
    message.success('操作成功');
    this.ref.resetFields();
    this.onClose();
    this.parent.getAnchorDetail();
  };
  @action public onClose = () => {
    this.visible = false;
  };
}
