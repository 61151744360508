import { type BaseData, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';

export default class RevenueShareModel {
  @observable public ref: FormInstance = null;
  @observable public saveLoading = false;

  constructor() {
    this.onShow();
  }
  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action public handleChange = (value: number | null, name: string) => {
    if (value === null || value === undefined) {
      this.ref.setFieldsValue({ [name]: 0 });
    }
  };

  @action public onSave = async () => {
    const isValidated = await this.ref.validateFields();

    if (!isValidated) {
      return;
    }

    const values = this.ref.getFieldsValue();
    const postParams = Object.entries(values).map(([key, value]) => ({ key, value }));
    return request<BaseData<any>>({
      url: '/pbb/platform/system/configDict/update/batch',
      method: 'post',
      data: {
        list: postParams,
      },
    }).then((resp) => {
      message.success('保存成功');
      this.onShow();
    });
  };

  public onShow = () => {
    const keyList = [
      'invite_anchor_amount',
      'match_to_face_proportion',
      'sign_up_choose_broker_service_proportion',
      'sign_up_pay_service_proportion',
      'buy_social_group_service_proportion',
      'employer_pay_own_invite_proportion',
      'employer_pay_artificial_invite_proportion',
    ];
    return request<BaseData<any>>({
      url: '/pbb/platform/anon/system/configDict/getValue/batch',
      method: 'post',
      data: {
        keyList,
      },
    }).then((resp) => {
      setTimeout(() => {
        this.ref.setFieldsValue(resp.data);
      }, 100);
    });
  };
}
