export const orderStatus = [
  { label: '已支付', value: '1' },
  { label: '已退款', value: '2' },
];

export const payChannel = [
  {
    label: '主播小程序',
    value: 1,
  },
  {
    label: '雇主小程序',
    value: 2,
  },
  {
    label: '公众号',
    value: 3,
  },
  {
    label: '运营平台充值',
    value: 99,
  },
];
