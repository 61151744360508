export const API = {
  // 查询雇主列表
  queryEmployerPage: '/pbb/platform/sys/employer/info/list',
  // 获取雇主详情
  getDetailInfo: '/pbb/platform/sys/employer/detail',
  // 获取切片列表
  getSliceList: '/pbb/platform/sys/slice/info/list',
  // 启用禁用账号
  enabelCount: '/pbb/platform/sys/employer/account/ban/unban',
  // 热门城市列表
  cityList: '/pbb/platform/anon/system/hot/city/list',
  // 直播类目列表
  liveTypeList: '/pbb/platform/anon/system/liveType/list',
  // 直播标签列表
  tagList: '/pbb/platform/sys/tag/lib/list/all',
  // 更新雇主信息
  updateEmployer: '/pbb/platform/sys/update/employer/info',
  butlerList: '/pbb/platform/butler/list',
  modifyButler: '/pbb/platform/sys/employer/batch/modify/butler',
};
