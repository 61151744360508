import AnchorInfoModel from '@/compnents/anchorInfo/model';
import { Avatar, Button, Space, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import React from 'react';
import type { BaseData } from '../../../../utils';
import { MainSubStructureModel, request } from '../../../../utils';
import type Mode2 from '../../../employment/store';
import { API } from '../api';
import { userTypeDict } from '../dict';
import { MockSliceData } from '../mock';
import type Model from '../model';
import type { ISliceModalTitle, SliceInfoVO } from '../type';

type Models = Model | Mode2;

export default class AlternativeSliceModel {
  @observable public sliceData: SliceInfoVO[] = [];

  @observable public isEdit = false;

  @observable public isOpen = false;

  @observable public id: string | number = '';

  @observable public titleInfo: ISliceModalTitle = {
    name: '',
    slicePeopleCount: 0,
    sliceCount: 0,
  };

  public anchorInfoModel = new AnchorInfoModel();

  constructor(parentStore: any) {
    this.parentStore = parentStore;
    // this.mainSubStructureModel?.gridModel?.onQuery();
  }

  public parentStore: any;

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      rowHeight: 80,
      columns: [
        {
          key: 'opreations',
          name: '操作',
          frozen: true,
          width: 100,
          formatter: ({ row }) => (
            <div>
              <a
                onClick={() => {
                  window.top.RingPermission.openTab(`/pbb-pc-management/anchorDetail/${row.userId}`, '67', '主播详情');
                }}
              >
                查看主播
              </a>
            </div>
          ),
        },
        {
          key: 'time',
          name: '收藏时间',
          formatter: ({ row }) => <div>{row?.time ? dayjs(row?.time).format('YYYY-MM-DD HH:mm:ss') : ''}</div>,
        },
        {
          key: 'name',
          name: '主播姓名',
          formatter: ({ row }) => {
            const { name, avatar, userId } = row;
            return (
              <Space
                className="cursor-pointer"
                onClick={() => {
                  this.anchorInfoModel.onShow(userId);
                }}
              >
                <a>{name}</a>
                <Avatar
                  alt="暂无头像"
                  src={avatar}
                />
              </Space>
            );
          },
        },
        {
          key: 'tagList',
          name: '主播标签',
          formatter: ({ row }) => {
            const { tagList } = row;
            return (
              <Tooltip
                title={tagList?.map((item) => {
                  return (
                    <Tag
                      key={item.libId}
                      color="green"
                    >
                      {item.libName}
                    </Tag>
                  );
                })}
              >
                {tagList?.map((item) => {
                  return (
                    <Tag
                      key={item.libId}
                      color="green"
                    >
                      {item.libName}
                    </Tag>
                  );
                })}
              </Tooltip>
            );
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      showRefresh: true,
      primaryKeyField: 'opusId',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: true,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        return request<BaseData<any>>({
          url: API.getSliceList,
          method: 'POST',
          data: { ...postParams, id: this.id },
        });
        // return Promise.resolve({ ...MockSliceData } as any);
      },
    },
  });

  @action onOpenModal = (id: string | number) => {
    this.isOpen = true;
    this.id = id;
    this.mainSubStructureModel.gridModel.onQuery();
  };

  @action setSliceModalTitleInfo = (info: ISliceModalTitle) => {
    this.titleInfo = info;
  };

  @action public onCancel = () => {
    this.isOpen = false;
    this.id = '';
    this.mainSubStructureModel.gridModel.clearToOriginal();
    this.setSliceModalTitleInfo({
      name: '',
      slicePeopleCount: 0,
      sliceCount: 0,
    });
  };
}
