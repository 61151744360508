import AnchorInfoModal from '@/compnents/anchorInfo';
import { MainSubStructure } from '@/utils';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import EditUser from './modal/editUser';
import Model from './model';

const WhiteList = () => {
  const store = useMemo(() => new Model(), []);

  const { whiteListStore, anchorInfoModalStore, editUserStore } = store;

  return (
    <div style={{ height: '100vh' }}>
      <MainSubStructure store={whiteListStore} />
      <AnchorInfoModal store={anchorInfoModalStore} />

      {/* <AddUser store={addUserStore} /> */}
      <EditUser store={editUserStore} />
      <AnchorInfoModal store={store.anchorInfoModalStore} />
    </div>
  );
};

export default observer(WhiteList);
