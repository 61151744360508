import { Form, type FormInstance, Input, Modal } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

interface Options {
  callback?: (params: { butlerReview: string }, ...args: any[]) => Promise<any>;
  title?: string;
  butlerReview?: string;
}

export class RecommendWordModalStore {
  public formRef = React.createRef<FormInstance>();

  @observable public open = false;
  @observable.ref options: Options = {};
  @action public handleOpen = (options: Options) => {
    this.open = true;
    this.options = options;
  };
  @action public handleClose = () => {
    this.open = false;
    this.options = {};
  };

  @observable public isSubmit = false;
  public handleSubmit = async () => {
    if (this.isSubmit) {
      return;
    }

    const values = await this.formRef.current.validateFields();
    this.isSubmit = true;
    try {
      await this.options.callback(values);
      this.handleClose();
    } finally {
      this.isSubmit = false;
    }
  };
}

export const RecommendWordModal = observer(
  ({
    recommendWordModalStore: { open, handleClose, options, handleSubmit, isSubmit, formRef },
  }: { recommendWordModalStore: RecommendWordModalStore }) => {
    return (
      <Modal
        destroyOnClose={true}
        centered={true}
        open={open}
        width={400}
        onCancel={handleClose}
        maskClosable={false}
        title={options.title}
        closable={true}
        confirmLoading={isSubmit}
        onOk={handleSubmit}
      >
        <Form
          ref={formRef}
          layout={'vertical'}
          autoComplete="off"
          initialValues={{ butlerReview: options.butlerReview }}
        >
          <Form.Item
            label={'推荐理由'}
            name="butlerReview"
          >
            <Input.TextArea
              allowClear={true}
              autoSize={{ minRows: 4, maxRows: 4 }}
              showCount={true}
              maxLength={50}
              placeholder={'请输入推荐理由'}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
