import { type BaseData, request } from '@/utils';
import { Button, Form, Modal, Select, type SelectProps } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { API } from '../api';
import type Model from './model';
const InsertUser = ({ store }: { store: Model }) => {
  const { visible, onCancel, setRef, onSave, options } = store;

  return (
    <Modal
      title="新增机器人用户"
      footer={
        <div className={'flex justify-end gap-2'}>
          <Button onClick={onCancel}>取消</Button>
          <Button
            type={'primary'}
            onClick={onSave}
          >
            确定
          </Button>
        </div>
      }
      open={visible}
      onCancel={onCancel}
      width={383}
      centered={true}
      maskClosable={false}
    >
      <Form ref={setRef}>
        <Form.Item
          name="sysUser"
          label={'选择用户'}
          rules={[
            {
              required: true,
              message: '请选择用户',
            },
          ]}
        >
          <SearchInput
            placeholder={'输入用户昵称/手机号/ID搜索选择'}
            style={undefined}
            className={'!w-60'}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(InsertUser);

const SearchInput: React.FC<{
  placeholder: string;
  style: React.CSSProperties;
  className: string;
  callback?: (value: string) => void;
  [key: string]: any;
}> = React.forwardRef((props, ref) => {
  const [data, setData] = useState<SelectProps['options']>([]);

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };
  return (
    <Select
      // @ts-ignore
      ref={ref}
      labelInValue={true}
      className={props.className}
      showSearch={true}
      value={props.value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={(value, option: any) => {
        console.log(option, 'value');

        props.onChange(option?.sysUserId);
        props?.callback(option?.sysUserId);
      }}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        label: d.label,
        value: d.value,
        sysUserId: d.sysUserId,
      }))}
    />
  );
});

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
const fetch = (value: string, callback: (data: { label: any; value: any }[] | undefined) => void) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = async () => {
    const req = await request<BaseData<any[]>>({
      method: 'POST',
      url: API.querySysUser,
      data: {
        param: value,
      },
    });
    if (currentValue === value) {
      callback(
        (req?.data || [])?.map((item) => {
          console.log(item, 'item');

          return {
            label: item.sysUserName,
            value: item.sysUserId,
            sysUserId: item.sysUserId,
          };
        }),
      );
    }
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};
