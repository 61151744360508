import { type BaseData, EgGrid, EgGridModel, request } from '@/utils';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import type { ModalProps } from './interface';

const History = observer(({ visible, item, onCancel }: ModalProps) => {
  const gridModel = new EgGridModel({
    columns: [
      {
        key: 'operateTime',
        name: '操作时间',
        width: 200,
        resizable: true,
      },
      {
        key: 'description',
        name: '说明',
        width: 350,
        resizable: true,
        sortable: true,
      },
      {
        key: 'operator',
        name: '操作人',
        // width: 180,
        resizable: true,
      },
    ],
    rows: [],
    primaryKeyField: 'operateTime',
    sortByLocal: false,
    showCheckBox: false,
    showSelectedTotal: false,
    showReset: false,
    showPager: false,
  });
  const getList = async () => {
    try {
      gridModel.loading = true;
      const res = await request<BaseData<any[]>>({
        url: '/pbb/platform/sys/recruit/getRecruitRecordById',
        method: 'POST',
        data: { id: item.recruitId },
      });
      gridModel.rows = res.data;
    } finally {
      gridModel.loading = false;
    }
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (visible) {
      getList();
    }
  }, [visible]);
  return (
    <Modal
      open={visible}
      title="历史进度"
      width={800}
      footer={null}
      centered={true}
      onCancel={onCancel}
    >
      <div className="text-14 font-500">
        JD名称：<span className="font-400">{item?.positionName}</span>
      </div>
      <div className="text-14 font-500 mt-3">
        主播姓名：<span className="font-400">{item?.anchorInfo.userName}</span>
      </div>
      <div className="h-[400px] mt-4">
        <EgGrid store={gridModel} />
      </div>
    </Modal>
  );
});

export default History;
