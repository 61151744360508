export const API = {
  WechatUserList: '/pbb/platform/sys/corpWechat/queryCorpWechatUserPage',

  updatePaymentNotice: '/pbb/platform/sys/corpWechat/updatePaymentNotice',

  deleteCorpWechatUser: '/pbb/platform/sys/corpWechat/deleteCorpWechatUser',
  queryGroupChatPage: '/pbb/platform/sys/corpWechat/queryGroupChatPage',
  GetGroupsAndMembers: '/pbb/platform/sys/corpWechat/testGetGroupsAndMembers',
  querySysUser: '/pbb/platform/sys/corpWechat/querySysUser',
  addCorpWechatUser: '/pbb/platform/sys/corpWechat/addCorpWechatUser',
};
