import type { BrokerVO, UserVO } from '@/pages/anchor/anchorManagement/type.ts';
import { api } from '@/utils/api.ts';
import { Button, Modal, Space, Tag, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable, toJS } from 'mobx';
import {
  type BaseData,
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  type PaginationData,
  RenderByPermission,
  SearchListModal,
  copyToClipboard,
  extractValuesByKey,
  getWxUrl,
  request,
  requireSource,
  transformKeys,
} from '../../../utils';

import { ASSETS } from '@/assets/index.ts';
import AnchorInfoModel from '@/compnents/anchorInfo/model.ts';
import type { AnchorDetail } from '@/pages/anchorDetail/types.ts';
import { MyModelCardModel } from '@/pages/section/sectionAdmin/components/modelCard.tsx';
import FileSaver from 'file-saver';
import { API } from './api.ts';
import { AnchorTagsModalStore } from './components/anchorTags/model.ts';
import { AuditModalStore } from './components/audit/model.ts';
import { CanOwnInviteModel } from './components/canOwnInvite/model.tsx';
import { status, vitaAuditStatus } from './options.ts';

interface Option {
  label: any;
  value: string;
  id?: string | number | null;
  name?: string;
  children?: Option[];
  isLeaf?: boolean;
}
export default class AnchorAuditLogModel {
  @observable auditModalStore: AuditModalStore = new AuditModalStore(this); //审核弹框store

  @observable anchorTagsModalStore: AnchorTagsModalStore = new AnchorTagsModalStore(this); //主播标签弹框store
  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框
  @observable canOwnInviteModalStore: CanOwnInviteModel = new CanOwnInviteModel(this);

  public cities = [];

  myModelCardStore = new MyModelCardModel(); // 模卡
  //初始化查询条件数据
  init = async () => {
    //主播标签
    const categoriesList = await request<BaseData<Option[]>>({
      url: api.tag.getAllLibList,
      method: 'get',
      params: { categoryType: 5 },
    });
    this.mainStore.programme.filterItems.updateFilterItem([
      {
        field: 'categoryIdList',
        treeData: transformKeys(
          categoriesList.data.filter((e) => e.children.length > 0),
          {
            name: 'label',
            id: 'value',
          },
        ),
      },
    ]);
  };

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'name',
        label: '主播信息',
        placeholder: '请输入主播昵称/姓名/手机号/ID',
      },
      {
        type: 'select',
        field: 'auditStatus',
        label: '审核处理',
        placeholder: '请选择',
        data: [
          { label: '待处理', value: '0' },
          { label: '已处理', value: '1' },
          { label: '无需处理', value: '99' },
        ],
      },
      {
        type: 'treeSelect',
        field: 'categoryIdList',
        label: '主播标签',
        placeholder: '请选择',
        multiple: true,
        isParamList: true,
        treeCheckable: true,
        showCheckedStrategy: 'SHOW_CHILD',
        allowClear: true,
      },
      {
        type: 'input',
        field: 'vitaAuditAdminName',
        label: '最新审核人',
        placeholder: '请输入',
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '注册时间',
      },
      {
        type: 'select',
        field: 'canOwnInvite',
        label: '雇主可自主邀约',
        data: [
          {
            label: '是',
            value: '1',
          },
          {
            label: '否',
            value: '0',
          },
        ],
      },
      {
        type: 'dateRange',
        field: 'latestAuditTime',
        label: '最新审核时间',
      },
      {
        type: 'select',
        field: 'isVirtual',
        label: '激活状态',
        value: '0',
        data: [
          { label: '已激活', value: '0' },
          { label: '未激活', value: '1' },
        ],
      },
    ],
  };

  constructor() {
    this.init();
    this.mainStore.grid.onQuery();
  }

  public grid: IMainSubStructureModel<UserVO> = {
    buttons: [
      {
        text: '批量主播标签',
        permissionId: '48',
        handleClick: () => {
          const selectedRows = this.mainStore.grid.gridModel.selectRows;
          if (selectedRows.length === 0) {
            message.warning('请选择需要设置标签的主播');
            return;
          }
          this.anchorTagsModalStore.onShow(selectedRows);
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 360,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            const { accountStatus, auditStatus } = row; //0正常 1封禁
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <Button
                    onClick={() => {
                      //查看主播详情
                      window.top.RingPermission.openTab(
                        `/pbb-pc-management/anchorDetail/${row.id}?type=audit`,
                        '67',
                        '主播详情',
                      );
                    }}
                    type="link"
                  >
                    详情
                  </Button>
                </RenderByPermission>
                {auditStatus !== 1 && auditStatus !== 2 && (
                  <RenderByPermission permissionId={`${pageId}_14`}>
                    <Button
                      onClick={() => {
                        this.auditModalStore.onShow(row.id);
                      }}
                      type="link"
                    >
                      审核
                    </Button>
                  </RenderByPermission>
                )}
                <RenderByPermission permissionId={`${pageId}_15`}>
                  <Button
                    onClick={() => {
                      window.top.RingPermission.openTab(`/pbb-pc-management/auditLog?id=${row.id}`, '15', '审核记录');
                    }}
                    type="link"
                  >
                    审核记录
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_116`}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.shareAnchor(row.id);
                    }}
                    type="link"
                  >
                    分享主播
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_45`}>
                  <Button
                    onClick={() => {
                      this.anchorTagsModalStore.onShow([row]);
                    }}
                    type="link"
                  >
                    主播标签
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '主播信息',
          width: 300,
          formatter: ({ row }) => {
            const { name, nickName, avatar, serviceObjectList, id, mobile } = row;
            const isPay = row?.userAccountVO?.cpay === 1;
            return (
              <div
                className="flex items-center cursor-pointer h-full gap-2"
                onClick={() => {
                  //弹出主播信息资料
                  this.anchorInfoModalStore.onShow(id, 'audit');
                }}
              >
                <div className="relative">
                  <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                    <img
                      src={avatar}
                      className="w-full h-full"
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-12 leading-[18px]">
                  <div className="flex items-center">
                    {isPay ? (
                      <>
                        <img
                          src={ASSETS.COMMON.PAY_STATUS}
                          className="w-13"
                          alt=""
                        />
                        <div className="text-[red]"> ({row?.userOrderCount})</div>
                      </>
                    ) : null}
                  </div>
                  <div className="flex items-center">
                    {name ? `姓名：${name}` : `昵称：${nickName}`}

                    <img
                      src={requireSource('/common/idcard.png')}
                      alt={''}
                      className={'w-4 h-4 ml-1'}
                    />
                  </div>
                  <div>ID：{id}</div>
                  <div>手机号：{mobile}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'tagList',
          name: '主播标签',
          formatter: ({ row }) => {
            const { tagList } = row;
            if (!tagList) {
              return '';
            }
            return (
              <Tooltip title={tagList.map((item) => item.libName).join(', ')}>
                {tagList?.map((item) => {
                  return (
                    <Tag
                      className="text-primary text-12 px-2 bg-[#1D53D91A] border-[#3A6EF866] border-[1px]"
                      key={item.libId}
                    >
                      {item.libName}
                    </Tag>
                  );
                })}
              </Tooltip>
            );
          },
        },
        {
          key: 'auditStatus',
          name: '审核处理',
          formatter: ({ row }) => {
            const { auditStatus } = row;
            if (auditStatus === 0) {
              return <div className={'text-primary'}>待处理</div>;
            } else if (auditStatus === 99) {
              return <div className={'text-secondary'}>无需处理</div>;
            }
            return '已处理';
          },
        },
        {
          key: 'userJobHuntVO',
          name: '简历更新时间',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.vitaUpdateTime
              ? dayjs(userJobHuntVO?.vitaUpdateTime).format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
        {
          key: 'vitaAuditStatus',
          name: '文本状态',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return vitaAuditStatus.find((item) => item.value == userJobHuntVO?.vitaAuditStatus)?.label || '';
          },
        },
        {
          key: 'sliceVitaStatus',
          name: '切片状态',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return status.find((item) => item.value == userJobHuntVO?.sliceVitaStatus)?.label || '';
          },
        },
        {
          key: 'imageVitaStatus',
          name: '形象照状态',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return status.find((item) => item.value == userJobHuntVO?.imageVitaStatus)?.label || '';
          },
        },
        {
          key: 'muckUpVitaStatus',
          name: '模卡状态',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;

            return status.find((item) => item.value == userJobHuntVO?.muckUpVitaStatus)?.label || '';
          },
        },
        {
          key: 'latestAuditTime',
          name: '最新审核时间',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.vitaAuditTime
              ? dayjs(userJobHuntVO?.vitaAuditTime).format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
        {
          key: 'latestAuditName',
          name: '最新审核人',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.vitaAuditAdminName || '';
          },
        },
        {
          key: 'isVirtual',
          name: '激活状态',
          formatter: ({ row }) => {
            const { isVirtual } = row;
            return isVirtual === 1 ? '未激活' : '已激活';
          },
        },
        {
          key: 'createTime',
          name: '注册时间',
          formatter: ({ row }) => {
            const { createTime } = row;
            return createTime ? dayjs(createTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      rowHeight: 120,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
      pageSize: 20,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        if (postParams.createTime) {
          postParams.startTime = postParams.createTime.split(',')[0];
          postParams.endTime = postParams.createTime.split(',')[1];
          postParams.createTime = undefined;
        }
        if (postParams.latestAuditTime) {
          postParams.vitaAuditStartTime = postParams.latestAuditTime.split(',')[0];
          postParams.vitaAuditEndTime = postParams.latestAuditTime.split(',')[1];
          postParams.latestAuditTime = undefined;
        }

        return request<PaginationData<UserVO>>({
          url: API.queryAnchorList,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  @action public shareAnchor = (id: number) => {
    getWxUrl({
      channel: 6,
      anchorId: id,
    }).then((url) => {
      copyToClipboard(url);
      message.success('复制分享主播链接成功');
    });
  };
}
