import { type BaseData, request } from '@/utils';
import { Card, Col, Form, Input, Radio, Row, Select, type SelectProps, Space, Tag } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { jobTypeDict, publicDict } from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';

/**
 * 新增职位
 */
@inject('store')
@observer
class NewJob extends React.Component<{ store?: AddModel }> {
  render() {
    const { getCompanyInfo, setValue, auditReason, type, id, status, dataOrigin, parentStore, employerDetailModel } =
      this.props.store;
    return (
      <Card
        title={
          <div className={'flex flex-row items-center justify-between'}>
            {type === 1 ? (
              '新增职位'
            ) : (
              <Space>
                {`职位详情（ID：${id}）`}
                {status === 2 ? <Tag className={'px-4 py-2'}>待上线</Tag> : undefined}
                {status === 4 ? (
                  <Tag
                    className={'px-4 py-2'}
                    color="#f50"
                  >
                    审核拒绝
                  </Tag>
                ) : undefined}
                {status === 1 ? (
                  <Tag
                    className={'px-4 py-2'}
                    color="#87d068"
                  >
                    已上线
                  </Tag>
                ) : undefined}
                {status === 3 ? (
                  <Tag
                    className={'px-4 py-2'}
                    color="#2db7f5"
                  >
                    待审核
                  </Tag>
                ) : undefined}
              </Space>
            )}
            {auditReason && status === 4 ? <div className={'text-[red]'}>失败原因：{auditReason}</div> : undefined}
          </div>
        }
        className={styles.card}
      >
        {type === 1 ? (
          <Space
            className={'w-full'}
            size={[20, 16]}
            direction={'vertical'}
          >
            <Form.Item
              label={'职位类型'}
              name="jobType"
              className={'mb-0'}
            >
              <Radio.Group
                onChange={(e) => {
                  setValue('jobType', e.target.value);
                }}
                options={jobTypeDict}
              />
            </Form.Item>
            <Form.Item
              label={'关联雇主'}
              name="hrUserId"
              className={'mb-0'}
              rules={[{ required: true }]}
            >
              <SearchInput
                placeholder={'请输入'}
                style={undefined}
                callback={(value) => {
                  getCompanyInfo(value);
                }}
                className={'!w-90'}
              />
            </Form.Item>
          </Space>
        ) : undefined}
        {[2, 3].includes(type) ? (
          <Space
            className={'w-full'}
            direction={'vertical'}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  className={'mb-0'}
                  label={'创建时间'}
                >
                  {dataOrigin?.createTime || '-'}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className={'mb-0'}
                  label={'创建人'}
                >
                  {`${dataOrigin?.createBy || ''}${dataOrigin?.createByType ? `（${dataOrigin.createByType}）` : ''}` ||
                    '-'}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  className={'mb-0'}
                  label={'报名人数'}
                >
                  {dataOrigin?.signUpCount ? (
                    <a
                      onClick={() => {
                        parentStore.informationPopupModel.onOpen(
                          2,
                          dataOrigin.name,
                          dataOrigin.signUpCount,
                          dataOrigin.id,
                          dataOrigin.companyName,
                        );
                      }}
                    >
                      {dataOrigin?.signUpCount}
                    </a>
                  ) : (
                    0
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className={'mb-0'}
                  label={'邀约人数'}
                >
                  {dataOrigin?.inviteCount ? (
                    <a
                      onClick={() => {
                        parentStore.informationPopupModel.onOpen(
                          3,
                          dataOrigin.name,
                          dataOrigin.signUpCount,
                          dataOrigin.id,
                          dataOrigin.companyName,
                        );
                      }}
                    >
                      {dataOrigin?.inviteCount}
                    </a>
                  ) : (
                    0
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={'职位类型'}
                  name="jobType"
                  className={'mb-0'}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setValue('jobType', e.target.value);
                    }}
                    options={jobTypeDict}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className={'mb-0'}
                  label={'雇主昵称'}
                >
                  {dataOrigin?.hrName ? (
                    <a
                      onClick={() => {
                        employerDetailModel?.onOpenDetailModal(dataOrigin.hrUserId, dataOrigin.id);
                      }}
                    >
                      {dataOrigin?.hrName}
                    </a>
                  ) : null}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  className={'mb-0'}
                  label={'职位来源'}
                >
                  {dataOrigin?.dataSourceName}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className={'mb-0'}
                  label={'职位链接'}
                >
                  {dataOrigin?.positionUrl}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={'是否展示给兼职经纪人'}
                  name="showToPartBroker"
                >
                  <Radio.Group
                    onChange={(e) => {
                      setValue('showToPartBroker', e.target.value);
                    }}
                    options={publicDict}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Space>
        ) : undefined}
      </Card>
    );
  }
}
export default NewJob;
const SearchInput: React.FC<{
  placeholder: string;
  style: React.CSSProperties;
  className: string;
  callback: (value: string) => void;
  [key: string]: any;
}> = React.forwardRef((props, ref) => {
  const [data, setData] = useState<SelectProps['options']>([]);

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };
  return (
    <Select
      // @ts-ignore
      ref={ref}
      className={props.className}
      showSearch={true}
      value={props.value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={(value, option: any) => {
        props.onChange(value);
        props?.callback(option?.companyId);
      }}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.label,
        companyId: d?.companyId,
      }))}
    />
  );
});

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
const fetch = (value: string, callback: (data: { label: any; value: any }[] | undefined) => void) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = async () => {
    const req = await request<BaseData<any[]>>({
      method: 'POST',
      url: '/pbb/platform/sys/position/getEmployerInfo',
      data: {
        name: value,
      },
    });
    if (currentValue === value) {
      callback(
        (req?.data || [])?.map((item) => {
          return {
            label: item.employerName,
            value: item.hrUserId,
            companyId: item.companyId,
          };
        }),
      );
    }
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};
