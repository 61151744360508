import { type BaseData, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';

type UpdateData = {
  adminId?: string;
  adminMobile?: string;
  adminNickName?: string;
  userId?: string;
  userMobile?: string;
  userNickName?: string;
  userAvatar?: string;
};

export default class ChangeBrokerModel {
  @observable public parent = null;
  @observable public visible = false;
  @observable public id: number;
  @observable public ref: FormInstance;
  public saveLoading = false; // 保存状态

  constructor(parent) {
    this.parent = parent;
  }

  @action public setRef = (ref: FormInstance) => {
    this.ref = ref;
  };

  @action public onShow = () => {
    this.visible = true;
  };

  @action public onClose = () => {
    this.visible = false;
    this.saveLoading = false;
    this.ref.resetFields();
  };

  @action public onSave = async () => {
    this.saveLoading = true;
    const values = await this.ref.getFieldsValue();
    if (!(values.adminId || values.userId)) {
      return message.error('请选择一个经纪人用户');
    }

    const postParams = { ...values };
    const { adminId, userId } = postParams;

    const data: UpdateData = {};

    const updateParentAndData = (field: 'admin' | 'user', value: any) => {
      if (value) {
        const mobile = value.mobile;
        const id = value.value;
        const nickName = value.label;
        const avatar = value.keyWord;

        this.parent[`${field}Mobile`] = mobile;
        this.parent[`${field}Id`] = id;
        this.parent[`${field}NickName`] = nickName;

        data[`${field}Id`] = id;
        data[`${field}Mobile`] = mobile;
        data[`${field}NickName`] = nickName;

        if (field === 'user') {
          this.parent.userAvatar = avatar;
          data.userAvatar = avatar;
        }
      }
    };

    updateParentAndData('admin', adminId);
    updateParentAndData('user', userId);

    setTimeout(() => {
      this.parent.ref?.setFieldsValue({
        ...data,
      });
      this.onClose();
    }, 0);

    return '';
  };
}
